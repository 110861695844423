import axios from 'axios'

import { SUBMIT_JOB_PROFILE } from './job-profile'

//////////////////////////////
// Action Types
//////////////////////////////
export const FETCH_PENDING_JOBS_REQUESTED = 'job/FETCH_PENDING_JOBS_REQUESTED'
export const FETCH_PENDING_JOBS_ERROR = 'job/FETCH_PENDING_JOBS_ERROR'
export const FETCH_PENDING_JOBS = 'job/FETCH_PENDING_JOBS'
export const FETCH_MATCHED_JOBS_REQUESTED = 'job/FETCH_MATCHED_JOBS_REQUESTED'
export const FETCH_MATCHED_JOBS_ERROR = 'job/FETCH_MATCHED_JOBS_ERROR'
export const FETCH_MATCHED_JOBS = 'job/FETCH_MATCHED_JOBS'
export const FETCH_COMPLETED_JOBS_REQUESTED =
  'job/FETCH_COMPLETED_JOBS_REQUESTED'
export const FETCH_COMPLETED_JOBS_ERROR = 'job/FETCH_COMPLETED_JOBS_ERROR'
export const FETCH_COMPLETED_JOBS = 'job/FETCH_COMPLETED_JOBS'
export const FETCH_JOB_REQUESTED = 'job/FETCH_JOB_REQUESTED'
export const FETCH_JOB_ERROR = 'job/FETCH_JOB_ERROR'
export const FETCH_JOB = 'job/FETCH_JOB'
export const SUBMIT_JOB_REQUESTED = 'job/SUBMIT_JOB_REQUESTED'
export const SUBMIT_JOB_ERROR = 'job/SUBMIT_JOB_ERROR'
export const SUBMIT_JOB = 'job/SUBMIT_JOB'
export const SUBMIT_MULTIPLE_JOBS_REQUESTED =
  'job/SUBMIT_MULTIPLE_JOBS_REQUESTED'
export const SUBMIT_MULTIPLE_JOBS = 'job/SUBMIT_MULTIPLE_JOBS'
export const SUBMIT_MULTIPLE_JOBS_ERROR = 'job/SUBMIT_MULTIPLE_JOBS_ERROR'
export const START_JOB_REQUESTED = 'job/START_JOB_REQUESTED'
export const START_JOB_ERROR = 'job/START_JOB_ERROR'
export const START_JOB = 'job/START_JOB'
export const STOP_JOB_REQUESTED = 'job/STOP_JOB_REQUESTED'
export const STOP_JOB_ERROR = 'job/STOP_JOB_ERROR'
export const STOP_JOB = 'job/STOP_JOB'
export const COMPLETE_JOB_REQUESTED = 'job/COMPLETE_JOB_REQUESTED'
export const COMPLETE_JOB_ERROR = 'job/COMPLETE_JOB_ERROR'
export const COMPLETE_JOB = 'job/COMPLETE_JOB'
export const MATCH_JOB_REQUESTED = 'job/MATCH_JOB_REQUESTED'
export const MATCH_JOB_ERROR = 'job/MATCH_JOB_ERROR'
export const MATCH_JOB = 'job/MATCH_JOB'
export const SEND_INVOICE_REQUESTED = 'job/SEND_INVOICE_REQUESTED'
export const SEND_INVOICE_ERROR = 'job/SEND_INVOICE_ERROR'
export const SEND_INVOICE = 'job/SEND_INVOICE'
export const JOB_MATCHED = 'job/JOB_MATCHED'
export const SELECT_JOB = 'job/SELECT_JOB'

//////////////////////////////
// Reducer
//////////////////////////////
const initialState = {
  isFetching: false,
  pending: [],
  matched: [],
  completed: [],
  completedTotal: 0,
  // submittedTotal: 0,
  // submittedCompletedTotal: 0,
  job: {},
  error: null,
}
export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PENDING_JOBS_REQUESTED:
    case FETCH_MATCHED_JOBS_REQUESTED:
    case FETCH_COMPLETED_JOBS_REQUESTED:
    case FETCH_JOB_REQUESTED:
    case SUBMIT_JOB_REQUESTED:
    case SUBMIT_MULTIPLE_JOBS_REQUESTED:
    case START_JOB_REQUESTED:
    case STOP_JOB_REQUESTED:
    case COMPLETE_JOB_REQUESTED:
    case MATCH_JOB_REQUESTED:
      return {
        ...state,
        isFetching: true,
        error: null,
      }
    // case SUBMIT_MULTIPLE_JOBS_REQUESTED:
    //   return {
    //     ...state,
    //     submittedTotal: action.total,
    //     submittedCompletedTotal: 0,
    //   }
    case FETCH_PENDING_JOBS_ERROR:
    case FETCH_MATCHED_JOBS_ERROR:
    case FETCH_COMPLETED_JOBS_ERROR:
    case FETCH_JOB_ERROR:
    case SUBMIT_JOB_ERROR:
    case SUBMIT_MULTIPLE_JOBS_ERROR:
    case START_JOB_ERROR:
    case STOP_JOB_ERROR:
    case COMPLETE_JOB_ERROR:
    case MATCH_JOB_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      }
    case FETCH_PENDING_JOBS:
      return {
        ...state,
        isFetching: false,
        error: null,
        pending: action.pending,
      }
    case FETCH_MATCHED_JOBS:
      return {
        ...state,
        isFetching: false,
        error: null,
        matched: action.matched,
      }
    case FETCH_COMPLETED_JOBS:
      return {
        ...state,
        isFetching: false,
        error: null,
        completed: action.concat
          ? [...state.completed, ...action.completed]
          : action.completed,
        completedTotal: action.total,
      }
    case SUBMIT_JOB:
      return {
        ...state,
        isFetching: false,
        error: null,
        job: action.job,
        pending: [...state.pending, action.job],
        // submittedCompletedTotal: state.submittedCompletedTotal + 1,
      }
    case SUBMIT_MULTIPLE_JOBS:
      return {
        ...state,
        isFetching: false,
        error: null,
        pending: [...state.pending, ...action.jobs],
        // submittedCompletedTotal: state.submittedCompletedTotal + 1,
      }
    case START_JOB:
    case STOP_JOB:
      return {
        ...state,
        isFetching: false,
        error: null,
        job: action.job,
        matched: state.matched.map(job =>
          job.id === action.job.id ? action.job : job
        ),
      }
    case COMPLETE_JOB:
      return {
        ...state,
        isFetching: false,
        error: null,
        job: action.job,
        completed: [...state.completed, action.job],
        matched: state.matched.filter(job => job.id !== action.job.id),
      }
    case JOB_MATCHED:
    case MATCH_JOB:
      return {
        ...state,
        job: action.job,
        matched: [...state.matched, action.job],
        pending: state.pending.filter(job => job.id !== action.job.id),
      }
    case FETCH_JOB:
      return {
        ...state,
        isFetching: false,
        job: action.job,
      }
    case SELECT_JOB:
      return {
        ...state,
        job: action.job,
      }
    default:
      return state
  }
}

//////////////////////////////
// Action Creators
//////////////////////////////

export const fetchPendingJobs = params => async dispatch => {
  dispatch({
    type: FETCH_PENDING_JOBS_REQUESTED,
  })
  try {
    const result = await axios.get('/api/jobs/pending', {
      params,
    })
    dispatch({
      type: FETCH_PENDING_JOBS,
      pending: result.data,
    })
  } catch (error) {
    dispatch({
      type: FETCH_PENDING_JOBS_ERROR,
      error: error.response.data.message,
    })
  }
}

export const fetchMatchedJobs = params => async dispatch => {
  dispatch({
    type: FETCH_MATCHED_JOBS_REQUESTED,
  })
  try {
    const result = await axios.get('/api/jobs/matched', {
      params,
    })
    dispatch({
      type: FETCH_MATCHED_JOBS,
      matched: result.data,
    })
  } catch (error) {
    dispatch({
      type: FETCH_MATCHED_JOBS_ERROR,
      error: error.response.data.message,
    })
  }
}

export const fetchCompletedJobs = (
  params,
  loadMore = false
) => async dispatch => {
  dispatch({
    type: FETCH_COMPLETED_JOBS_REQUESTED,
  })
  try {
    const result = await axios.get('/api/jobs/completed', {
      params,
    })
    dispatch({
      type: FETCH_COMPLETED_JOBS,
      concat: loadMore,
      completed: result.data,
      total: parseInt(result.headers['x-total-count']),
    })
    return result
  } catch (error) {
    dispatch({
      type: FETCH_COMPLETED_JOBS_ERROR,
      error: error.response.data.message,
    })
  }
}

export const fetchJob = id => async dispatch => {
  dispatch({
    type: FETCH_JOB_REQUESTED,
  })
  try {
    const result = await axios.get(`/api/jobs/${id}`)
    dispatch({
      type: FETCH_JOB,
      job: result.data,
    })
  } catch (error) {
    dispatch({
      type: FETCH_JOB_ERROR,
      error: error.response.data.message,
    })
  }
}

export const createJob = job => async dispatch => {
  dispatch({
    type: SUBMIT_JOB_REQUESTED,
  })
  try {
    const result = await axios.post('/api/jobs', job)
    dispatch({
      type: SUBMIT_JOB,
      job: result.data,
    })
    if (!job.jobProfileId) {
      dispatch({
        type: SUBMIT_JOB_PROFILE,
        jobProfile: result.data.jobProfile,
      })
    }
  } catch (error) {
    dispatch({
      type: SUBMIT_JOB_ERROR,
      error: error.response.data.message,
    })
  }
}

export const createMultipleJobs = jobs => async dispatch => {
  // dispatch({
  //   type: SUBMIT_MULTIPLE_JOBS_REQUESTED,
  //   total: jobs.length,
  // })
  // for (const job of jobs) {
  //   dispatch(createJob(job))
  // }
  dispatch({
    type: SUBMIT_MULTIPLE_JOBS_REQUESTED,
  })
  try {
    const result = await axios.post('/api/jobs/bulk', jobs)
    dispatch({
      type: SUBMIT_MULTIPLE_JOBS,
      jobs: result.data,
    })
  } catch (error) {
    dispatch({
      type: SUBMIT_JOB_ERROR,
      error: error.response.data.message,
    })
  }
}

export const startJob = (id, time) => async dispatch => {
  dispatch({
    type: START_JOB_REQUESTED,
  })
  try {
    const result = await axios.post(`/api/jobs/${id}/start`, { time })
    dispatch({
      type: START_JOB,
      job: result.data,
    })
  } catch (error) {
    dispatch({
      type: START_JOB_ERROR,
      error: error.response.data.message,
    })
  }
}

export const stopJob = (id, time) => async dispatch => {
  dispatch({
    type: STOP_JOB_REQUESTED,
  })
  try {
    const result = await axios.post(`/api/jobs/${id}/stop`, { time })
    dispatch({
      type: STOP_JOB,
      job: result.data,
    })
  } catch (error) {
    dispatch({
      type: STOP_JOB_ERROR,
      error: error.response.data.message,
    })
  }
}

export const completeJob = (id, review) => async dispatch => {
  dispatch({
    type: COMPLETE_JOB_REQUESTED,
  })
  try {
    const result = await axios.post(`/api/jobs/${id}/complete`, { review })
    dispatch({
      type: COMPLETE_JOB,
      job: result.data,
    })
  } catch (error) {
    dispatch({
      type: COMPLETE_JOB_ERROR,
      error: error.response.data.message,
    })
  }
}

export const matchJob = (id, employeeId) => async dispatch => {
  dispatch({
    type: MATCH_JOB_REQUESTED,
  })
  try {
    const result = await axios.put(`/api/jobs/${id}`, { employeeId })
    dispatch({
      type: MATCH_JOB,
      job: result.data,
    })
  } catch (error) {
    dispatch({
      type: MATCH_JOB_ERROR,
      error: error.response.data.message,
    })
  }
}

export const sendInvoice = (id, all = false) => async dispatch => {
  dispatch({
    type: SEND_INVOICE_REQUESTED,
  })
  try {
    await axios.post(
      `/api/jobs/${id}/send-invoice`,
      {},
      {
        params: {
          all,
        },
      }
    )
    dispatch({
      type: SEND_INVOICE,
    })
  } catch (error) {
    dispatch({
      type: SEND_INVOICE_ERROR,
      error: error.response.data.message,
    })
  }
}

export const selectJob = job => dispatch => {
  dispatch({
    type: SELECT_JOB,
    job: job,
  })
}
