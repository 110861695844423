import axios from 'axios'

//////////////////////////////
// Action Types
//////////////////////////////
export const FETCH_SOFT_SKILLS_REQUESTED = 'softSkill/FETCH_REQUESTED'
export const FETCH_SOFT_SKILLS_ERROR = 'softSkill/FETCH_ERROR'
export const FETCH_SOFT_SKILLS = 'softSkill/FETCH'

//////////////////////////////
// Reducer
//////////////////////////////
const initialState = {
  isFetching: false,
  softSkills: [],
  error: null,
}
export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SOFT_SKILLS_REQUESTED:
      return {
        ...state,
        isFetching: true,
      }
    case FETCH_SOFT_SKILLS_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      }
    case FETCH_SOFT_SKILLS:
      return {
        ...state,
        isFetching: false,
        error: null,
        softSkills: action.softSkills,
      }
    default:
      return state
  }
}

//////////////////////////////
// Action Creators
//////////////////////////////

export const fetchSoftSkills = () => async dispatch => {
  dispatch({
    type: FETCH_SOFT_SKILLS_REQUESTED,
  })
  try {
    const result = await axios.get('/api/soft-skills')
    dispatch({
      type: FETCH_SOFT_SKILLS,
      softSkills: result.data,
    })
    return result
  } catch (error) {
    dispatch({
      type: FETCH_SOFT_SKILLS_ERROR,
      error: error.response.data.message,
    })
  }
}
