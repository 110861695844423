import React, { Component, createRef } from 'react'
import {
  Avatar,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  Hidden,
  Menu,
  MenuItem,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  TextField,
  Toolbar,
  Typography,
  withStyles,
  ListSubheader,
} from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import NotInterestedIcon from '@material-ui/icons/NotInterested'
import SortIcon from '@material-ui/icons/Sort'
import WarningIcon from '@material-ui/icons/Warning'
import DoneIcon from '@material-ui/icons/Done'
import GradeIcon from '@material-ui/icons/Grade'
import WorkIcon from '@material-ui/icons/Work'
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter'
import PersonIcon from '@material-ui/icons/Person'
import FlagIcon from '@material-ui/icons/Flag'
import MoodIcon from '@material-ui/icons/Mood'
import ChatIcon from '@material-ui/icons/Chat'
import FaceIcon from '@material-ui/icons/Face'
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun'

import InfiniteScroll from 'react-infinite-scroller'
import moment from 'moment'

import ContractorSelectDetails from './ContractorSelectDetails'
import ContractorStats from './ContractorStats'
import SoftSkillStats from './SoftSkillStats'

const styles = theme => ({
  root: {
    // These are needed for InfiniteScroll to work
    height: '100%',
    overflow: 'auto',
  },
  toolbar: {
    position: 'fixed',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: theme.breakpoints.values['md'],
    },
    zIndex: 1000,
  },
  toolbarHeight: theme.mixins.toolbar,
  search: {
    minWidth: 100,
    paddingBottom: 8,
  },
  sortSubheader: {
    '&:focus': { outline: 'none' },
  },
  jobDetails: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  loading: {
    padding: 48,
  },
  list: {},
  statHeader: {
    minWidth: 70,
  },
  secondaryStar: {
    verticalAlign: 'top',
    fontSize: 18,
    marginLeft: -2,
  },
  errorIcon: {
    verticalAlign: 'top',
    marginRight: 4,
  },
})

const EMPLOYEE_PAGE_SIZE = 20

const TORONTO_SOFT_SKILLS = [
  {
    key: 'canadianExperience',
    name: 'Canadian Experience',
    icon: <FlagIcon fontSize="inherit" />,
  },
  {
    key: 'physicalCondition',
    name: 'Physical Condition',
    icon: <FitnessCenterIcon fontSize="inherit" />,
  },
  {
    key: 'grooming',
    name: 'Grooming',
    icon: <FaceIcon fontSize="inherit" />,
  },
  {
    key: 'smile',
    name: 'Smile / Eye Contact',
    icon: <MoodIcon fontSize="inherit" />,
  },
  {
    key: 'communication',
    name: 'Communication',
    icon: <ChatIcon fontSize="inherit" />,
  },
  {
    key: 'motivation',
    name: 'Motivation',
    icon: <DirectionsRunIcon fontSize="inherit" />,
  },
]
const AUSTIN_SOFT_SKILLS = [
  {
    key: 'physicalCondition',
    name: 'Physical Condition',
    icon: <FitnessCenterIcon fontSize="inherit" />,
  },
]

const getSoftSkills = region =>
  region.name === 'Toronto'
    ? TORONTO_SOFT_SKILLS
    : region.name === 'Austin'
    ? AUSTIN_SOFT_SKILLS
    : []

class ContractorSelect extends Component {
  state = {
    limit: EMPLOYEE_PAGE_SIZE,
    offset: 0,
    sort: [
      'businessJobCount.desc',
      'jobTypeSkill.desc',
      'businessRating.desc',
      'rating.desc',
    ],
    search: '',
    sortMenuEl: null,
  }

  timeout = null
  ref = createRef()

  componentDidMount = () => {
    this.fetchEmployees()
  }

  fetchEmployees = clearOffset => {
    let { limit, offset, sort, search } = this.state
    if (clearOffset) {
      offset = 0
      this.setState({ offset })
      this.scrollToTop()
    }
    this.props.fetchEmployees(limit, offset, sort, search)
  }

  handleLoadMoreEmployees = () => {
    if (!this.props.isFetching && this.hasMoreEmployees()) {
      let { offset } = this.state
      offset = offset + EMPLOYEE_PAGE_SIZE
      this.setState({ offset }, () => this.fetchEmployees())
    }
  }

  hasMoreEmployees = () =>
    this.props.employees.length < this.props.employeeTotal

  handleSearchChange = e => {
    const search = e.target.value
    this.setState({ search })

    // Only send the search text to fetch if typing stops
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    this.timeout = setTimeout(() => {
      this.fetchEmployees(true)
    }, 500)
  }

  handleSort = selected => {
    let { sort } = this.state
    sort = [selected, ...sort.filter(item => item !== selected)]
    this.setState({ sort, sortMenuEl: null }, () => this.fetchEmployees(true))
  }

  handleCloseSortMenu = () => this.setState({ sortMenuEl: null })

  scrollToTop = () => {
    this.ref.current.scrollIntoView({ behavior: 'smooth' })
  }

  render = () => {
    const {
      classes,
      job,
      region,
      employees,
      selectedEmployee,
      isFetching,
      onSelect,
      onSubmit,
    } = this.props
    const { search, sort, sortMenuEl } = this.state

    const softSkills = getSoftSkills(region)

    const SortItem = ({ text, value, icon }) => {
      const order = sort.indexOf(value)
      return (
        <MenuItem onClick={() => this.handleSort(value)}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={text} />
          {order !== -1 && (
            <Typography variant="caption">{order + 1}</Typography>
          )}
        </MenuItem>
      )
    }

    return (
      <div className={classes.root} ref={this.ref}>
        <Paper square className={classes.toolbar}>
          <Toolbar>
            <Grid container alignItems="center">
              <Grid item xs={12} sm>
                <Typography
                  className={`${classes.toolbarHeight} ${classes.jobDetails}`}
                  variant="body2"
                >
                  {`${job.jobProfile.jobType.name} @ ${
                    job.business.name
                  } \u00b7 ${moment(job.bookedStartTime).format(
                    'ddd, MMM D, h:mm a'
                  )} - ${moment(job.bookedEndTime).format('h:mm a')}`}
                </Typography>
              </Grid>
              <Grid item xs sm={3}>
                <TextField
                  className={classes.search}
                  id="search"
                  label="Search"
                  type="search"
                  value={search}
                  onChange={this.handleSearchChange}
                />
              </Grid>
              <Grid item>
                <IconButton
                  onClick={e => this.setState({ sortMenuEl: e.currentTarget })}
                >
                  <SortIcon />
                </IconButton>
                <Menu
                  anchorEl={sortMenuEl}
                  open={Boolean(sortMenuEl)}
                  onClose={this.handleCloseSortMenu}
                >
                  <ListSubheader
                    disableSticky
                    className={classes.sortSubheader}
                  >
                    At this business
                  </ListSubheader>
                  <SortItem
                    text="Completed Jobs"
                    value="businessJobCount.desc"
                    icon={<DoneIcon />}
                  />
                  <SortItem
                    text="Rating"
                    value="businessRating.desc"
                    icon={<GradeIcon />}
                  />
                  <ListSubheader
                    disableSticky
                    className={classes.sortSubheader}
                  >
                    Overall
                  </ListSubheader>
                  <SortItem
                    text="Rating"
                    value="rating.desc"
                    icon={<GradeIcon />}
                  />
                  <SortItem
                    text="Job Skill Level"
                    value="jobTypeSkill.desc"
                    icon={<WorkIcon />}
                  />
                  <SortItem
                    text="Full Name"
                    value="user.name.asc"
                    icon={<PersonIcon />}
                  />
                  <SortItem
                    text="Nickname"
                    value="user.nickname.asc"
                    icon={<PersonIcon />}
                  />
                  <ListSubheader
                    disableSticky
                    className={classes.sortSubheader}
                  >
                    Soft Skills
                  </ListSubheader>
                  {softSkills.map(({ key, name, icon }) => (
                    <SortItem
                      key={key}
                      text={name}
                      value={`softSkills.${key}.desc`}
                      icon={icon}
                    />
                  ))}
                </Menu>
              </Grid>
            </Grid>
          </Toolbar>
        </Paper>
        {/* Extra toolbar for spacing needed for fixed toolbar to make up for the dynamic height */}
        <Toolbar />
        <Hidden smUp>
          <Toolbar />
        </Hidden>

        {employees.length === 0 && (isFetching || !this.ref.current) ? (
          <Grid container justify="center" className={classes.loading}>
            <CircularProgress />
          </Grid>
        ) : (
          <List className={classes.list}>
            <InfiniteScroll
              pageStart={0}
              loadMore={this.handleLoadMoreEmployees}
              hasMore={this.hasMoreEmployees()}
              loader={
                <Grid container justify="center" key="infinite-scroll-loader">
                  <CircularProgress size={24} />
                </Grid>
              }
              initialLoad={false}
              useWindow={false}
              getScrollParent={() => this.ref.current}
            >
              {employees.map(employee => (
                <div key={employee.id}>
                  {selectedEmployee && employee.id === selectedEmployee.id && (
                    <Divider />
                  )}
                  <ListItem
                    key={employee.id}
                    button
                    onClick={() => onSelect(employee)}
                  >
                    <ListItemAvatar>
                      <Avatar
                        alt={employee.user.nickname}
                        src={employee.photo}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Typography variant="subtitle1" noWrap>
                          {employee.businessNoJobType ? (
                            <NotInterestedIcon
                              className={classes.errorIcon}
                              color="error"
                            />
                          ) : (
                            employee.businessNo && (
                              <WarningIcon className={classes.errorIcon} />
                            )
                          )}
                          {`${employee.user.name} (${employee.user.nickname})`}
                        </Typography>
                      }
                      secondaryTypographyProps={{
                        component: 'div',
                      }}
                      secondary={
                        <div>
                          <Grid container>
                            <Grid item>
                              <div className={classes.statHeader}>
                                Business:
                              </div>
                            </Grid>
                            <Grid item>
                              <ContractorStats
                                hideFavouriteCount
                                contractor={{
                                  completedCount: employee.businessJobCount,
                                  rating: employee.businessRating,
                                  skill: employee.jobTypeSkill,
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid item>
                              <div className={classes.statHeader}>Overall:</div>
                            </Grid>
                            <Grid item>
                              <ContractorStats
                                showSkill
                                contractor={employee}
                              />
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid item>
                              <SoftSkillStats
                                contractor={employee}
                                softSkills={softSkills}
                              />
                            </Grid>
                          </Grid>
                        </div>
                      }
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        onClick={() => onSubmit(employee.id)}
                        color="primary"
                        aria-label="Select Contractor"
                      >
                        <CheckIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Collapse
                    in={selectedEmployee && employee.id === selectedEmployee.id}
                    unmountOnExit
                  >
                    {selectedEmployee &&
                      employee.id === selectedEmployee.id && (
                        <ContractorSelectDetails
                          employee={selectedEmployee}
                          classes={classes}
                        />
                      )}
                    <Divider />
                  </Collapse>
                </div>
              ))}
            </InfiniteScroll>
          </List>
        )}
      </div>
    )
  }
}
export default withStyles(styles)(ContractorSelect)
