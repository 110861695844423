import React, { Component } from 'react'

import { connect } from 'react-redux'
import { changeBilling, fetchBilling } from '../reducers/billing'

import Billing from './Billing'

class BillingPage extends Component {
  componentDidMount = () => {
    this.props.fetchBilling()
  }

  handleToken = token => {
    if (token.type === 'card') {
      this.props.changeBilling(token)
    }
  }

  render = () => {
    const { billing, email } = this.props

    return (
      <Billing billing={billing} email={email} onToken={this.handleToken} />
    )
  }
}

const mapStateToProps = ({
  authentication: {
    user: { email },
  },
  billing: { billing },
}) => ({
  billing,
  email,
})

const mapDispatchToProps = {
  changeBilling,
  fetchBilling,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BillingPage)
