import React, { Fragment } from 'react'
import { ValidatorComponent } from 'react-form-validator-core'
import { withStyles, FormHelperText, FormControl } from '@material-ui/core'

const styles = {
  error: {
    margin: 0,
  },
}

class RadioValidator extends ValidatorComponent {
  render() {
    /* eslint-disable no-unused-vars */
    const {
      classes,
      label,
      errorMessages,
      validators,
      requiredError,
      children,
      ...rest
    } = this.props
    const { isValid } = this.state
    return (
      <Fragment>
        <FormControl component="fieldset" {...rest}>
          {!isValid && (
            <FormHelperText error className={classes.error}>
              {this.getErrorMessage()}
            </FormHelperText>
          )}
          {children}
        </FormControl>
      </Fragment>
    )
  }
}

export default withStyles(styles)(RadioValidator)
