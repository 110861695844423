import React, { Component } from 'react'
import { withStyles, TextField, MenuItem } from '@material-ui/core'

const styles = {
  root: {
    margin: 32,
  },
  textField: {
    marginTop: 8,
    marginBottom: 8,
    maxWidth: 400,
  },
  menu: {
    width: 200,
  },
}

class JobsFilter extends Component {
  handleChange = e => {
    const { name, value } = e.target
    if (name === 'selectedBusiness') {
      const businessId = value === -1 ? null : value
      this.props.filter(businessId)
    }
  }

  render = () => {
    let { classes, businesses, selectedBusiness } = this.props

    if (!selectedBusiness) {
      selectedBusiness = -1
    }

    businesses = [
      { id: -1, name: 'All' },
      ...businesses.sort((a, b) => a.name.localeCompare(b.name)),
    ]

    return (
      <div className={classes.root}>
        <TextField
          id="selectedBusiness"
          name="selectedBusiness"
          select
          label="Filter businesses"
          className={classes.textField}
          value={selectedBusiness}
          onChange={this.handleChange}
          SelectProps={{
            MenuProps: {
              className: classes.menu,
            },
          }}
          variant="outlined"
          fullWidth
        >
          {businesses.map(business => (
            <MenuItem key={business.id} value={business.id}>
              {business.name}
            </MenuItem>
          ))}
        </TextField>
      </div>
    )
  }
}

export default withStyles(styles)(JobsFilter)
