import React from 'react'
import {
  withStyles,
  withMobileDialog,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Grid,
} from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'
import { Link } from 'react-router-dom'

import ScheduleJobSummary from './ScheduleJobSummary'

const styles = {
  root: {},
  description: {
    marginBottom: 32,
  },
  feedbackArea: {
    margin: 16,
  },
  warningIcon: {
    margin: 8,
  },
}

const ScheduleJobConfirmDialog = ({
  classes,
  fullScreen,
  open,
  jobProfile,
  times,
  managerName,
  specialInstructions,
  working,
  error,
  onClose,
  onSubmit,
}) => (
  <Dialog
    className={classes.root}
    open={open}
    onClose={onClose}
    fullScreen={fullScreen}
    fullWidth={!fullScreen}
    aria-labelledby="confirm-job-dialog-title"
  >
    <DialogTitle id="confirm-job-dialog-title" onClose={onClose}>
      {times > 1 ? 'Confirm Shifts' : 'Confirm Shift'}
    </DialogTitle>
    <DialogContent>
      <Typography className={classes.description}>
        Please review the details below and confirm to schedule the shifts.
      </Typography>
      <ScheduleJobSummary
        jobProfile={jobProfile}
        times={times}
        managerName={managerName}
        specialInstructions={specialInstructions}
      />
    </DialogContent>
    <DialogActions>
      <Grid container justify="flex-end" className={classes.feedbackArea}>
        <Grid item xs={12}>
          {working && (
            <Grid container justify="center">
              <CircularProgress />
            </Grid>
          )}
          {error && (
            <Grid container alignItems="center" wrap="nowrap">
              <Grid item className={classes.warningIcon}>
                <WarningIcon color="error" />
              </Grid>
              <Typography color="error" variant="body1">
                {error}
              </Typography>
              <Button
                variant="text"
                color="primary"
                style={{ paddingLeft: 16 }}
                component={Link}
                to="/billing"
              >
                Billing
              </Button>
            </Grid>
          )}
        </Grid>
        <Button onClick={onClose} color="primary">
          Go back
        </Button>
        <Button onClick={onSubmit} color="primary">
          Confirm
        </Button>
      </Grid>
    </DialogActions>
  </Dialog>
)

export default withMobileDialog()(withStyles(styles)(ScheduleJobConfirmDialog))
