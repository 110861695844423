import React, { Component } from 'react'
import { connect } from 'react-redux'

import ResetPassword from './ResetPassword'

import { validateResetToken, resetPassword } from '../reducers/authentication'

class ResetPasswordPage extends Component {
  componentDidMount = () => {
    this.props.validateResetToken(this.props.match.params.token)
  }

  render = () => {
    const {
      match: {
        params: { token },
      },
      validToken,
      resetComplete,
      error,
      isFetching,
      resetPassword,
    } = this.props

    return (
      <ResetPassword
        token={token}
        isValidToken={validToken}
        onSubmit={resetPassword}
        error={error}
        processing={isFetching}
        complete={resetComplete}
      />
    )
  }
}

const mapStateToProps = ({
  authentication: { validToken, resetComplete, error, isFetching },
}) => ({
  validToken,
  resetComplete,
  error,
  isFetching,
})

const mapDispatchToProps = {
  resetPassword,
  validateResetToken,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordPage)
