import React, { Component } from 'react'

import { connect } from 'react-redux'
import {
  fetchEmployees,
  fetchEmployeeHistory,
  selectEmployee,
} from '../reducers/employee'
import { matchJob } from '../reducers/job'

import MatchDialog from './MatchDialog'

class MatchContainer extends Component {
  handleFetchEmployees = (limit, offset, sort, search) => {
    const {
      businessId,
      jobProfile: { jobType },
    } = this.props.job
    this.props.fetchEmployees({
      limit,
      offset,
      sort,
      search,
      businessId,
      jobTypeId: jobType.id,
      includeStats: true,
    })
  }

  handleSelectEmployee = employee => {
    // Select / unselect employee (for expand/collapse)
    if (employee) {
      if (
        this.props.selectedEmployee &&
        this.props.selectedEmployee.id === employee.id
      ) {
        this.props.selectEmployee(null)
      } else {
        this.props.selectEmployee(employee)
        this.props.fetchEmployeeHistory(employee.id, this.props.job.business.id)
      }
    }
  }

  handleMatch = employeeId => {
    this.props.matchJob(this.props.job.id, employeeId)
    this.handleSelectEmployee(null)
  }

  render = () => {
    const {
      job,
      region,
      employees,
      employeeTotal,
      selectedEmployee,
      isFetchingEmployees,
    } = this.props

    return (
      <MatchDialog
        job={job}
        region={region}
        employees={employees}
        employeeTotal={employeeTotal}
        selectedEmployee={selectedEmployee}
        fetchEmployees={this.handleFetchEmployees}
        isFetching={isFetchingEmployees}
        onSelect={this.handleSelectEmployee}
        onSubmit={this.handleMatch}
      />
    )
  }
}

const mapStateToProps = ({
  employee: {
    employees,
    total,
    employee,
    isFetching,
    pendingOffers,
    upcomingJobs,
    completedJobs,
  },
  authentication: {
    user: { region },
  },
}) => ({
  employees,
  isFetchingEmployee: isFetching,
  selectedEmployee: employee
    ? {
        ...employee,
        pendingOffers,
        upcomingJobs,
        completedJobs,
      }
    : null,
  employeeTotal: total,
  region,
})

const mapDispatchToProps = {
  matchJob,
  fetchEmployees,
  fetchEmployeeHistory,
  selectEmployee,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MatchContainer)
