import axios from 'axios'
import { AUTH_STORAGE_KEY } from '../config/axios'

//////////////////////////////
// Action Types
//////////////////////////////
export const LOGIN_REQUESTED = 'auth/LOGIN_REQUESTED'
export const LOGIN_ERROR = 'auth/LOGIN_ERROR'
export const LOGIN = 'auth/LOGIN'
export const GET_USER_SESSION_REQUESTED = 'auth/GET_USER_SESSION_REQUESTED'
export const GET_USER_SESSION_ERROR = 'auth/GET_USER_SESSION_ERROR'
export const GET_USER_SESSION = 'auth/GET_USER_SESSION'
export const FORGOT_PASSWORD_REQUESTED = 'auth/FORGOT_PASSWORD_REQUESTED'
export const FORGOT_PASSWORD_ERROR = 'auth/FORGOT_PASSWORD_ERROR'
export const FORGOT_PASSWORD = 'auth/FORGOT_PASSWORD'
export const VALIDATE_RESET_TOKEN_REQUESTED =
  'auth/VALIDATE_RESET_TOKEN_REQUESTED'
export const VALIDATE_RESET_TOKEN_ERROR = 'auth/VALIDATE_RESET_TOKEN_ERROR'
export const VALIDATE_RESET_TOKEN = 'auth/VALIDATE_RESET_TOKEN'
export const RESET_PASSWORD_REQUESTED = 'auth/RESET_PASSWORD_REQUESTED'
export const RESET_PASSWORD_ERROR = 'auth/RESET_PASSWORD_ERROR'
export const RESET_PASSWORD = 'auth/RESET_PASSWORD'
export const SET_USER = 'auth/SET_USER'
export const LOGOUT = 'auth/LOGOUT'

//////////////////////////////
// Reducer
//////////////////////////////
const initialState = {
  isFetching: false,
  isAuthenticated: false,
  isAdmin: false,
  isContractor: false,
  user: {},
  error: null,
  message: '',
  validToken: true, // Set as true to start so the error message doesn't show unless we know it's invalid
  resetComplete: false,
}
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_SESSION_REQUESTED:
    case LOGIN_REQUESTED:
      return {
        ...state,
        isFetching: true,
        isAuthenticated: false,
        isAdmin: false,
        isContractor: false,
      }
    case FORGOT_PASSWORD_REQUESTED:
      return {
        ...state,
        isFetching: true,
        message: '',
      }
    case VALIDATE_RESET_TOKEN_REQUESTED:
      return {
        ...state,
        isFetching: true,
        validToken: true,
      }
    case RESET_PASSWORD_REQUESTED:
      return {
        ...state,
        isFetching: true,
        resetComplete: false,
      }
    case GET_USER_SESSION_ERROR:
    case LOGIN_ERROR:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: false,
        isAdmin: false,
        isContractor: false,
        error: action.error,
      }
    case FORGOT_PASSWORD_ERROR:
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      }
    case VALIDATE_RESET_TOKEN_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
        validToken: false,
      }
    case LOGIN:
    case GET_USER_SESSION:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: true,
        isAdmin: action.user.roles.some(role => role.name === 'admin'),
        isContractor: action.user.roles.some(role => role.name === 'employee'),
        error: null,
        user: action.user,
      }
    case SET_USER:
      return {
        ...state,
        user: action.user,
      }
    case LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        user: {},
      }
    case FORGOT_PASSWORD:
      return {
        ...state,
        isFetching: false,
        error: null,
        message: action.message,
      }
    case VALIDATE_RESET_TOKEN:
      return {
        ...state,
        isFetching: false,
        error: null,
        validToken: true,
      }
    case RESET_PASSWORD:
      return {
        ...state,
        isFetching: false,
        error: null,
        resetComplete: true,
      }
    default:
      return state
  }
}

//////////////////////////////
// Action Creators
//////////////////////////////

export const getUserSession = () => async (dispatch, getState) => {
  dispatch({
    type: GET_USER_SESSION_REQUESTED,
  })
  try {
    const result = await axios.get('/api/auth/account')
    dispatch({
      type: GET_USER_SESSION,
      user: result.data,
    })

    subscribe(
      getState().socketio.socket,
      localStorage.getItem(AUTH_STORAGE_KEY)
    )
  } catch (error) {
    dispatch({
      type: GET_USER_SESSION_ERROR,
    })
  }
}

export const login = (email, password) => async (dispatch, getState) => {
  dispatch({
    type: LOGIN_REQUESTED,
  })
  try {
    const result = await axios.post('/api/auth/login', {
      email,
      password,
    })
    if (result.data.auth) {
      localStorage.setItem(AUTH_STORAGE_KEY, result.data.token)
      dispatch({
        type: LOGIN,
        user: result.data.user,
      })

      subscribe(getState().socketio.socket, result.data.token)
    } else {
      dispatch({
        type: LOGIN_ERROR,
        error: result.data.message,
      })
    }
  } catch (error) {
    dispatch({
      type: LOGIN_ERROR,
      error: error.response.data.message,
    })
  }
}

export const logout = () => dispatch => {
  if (localStorage.getItem(AUTH_STORAGE_KEY)) {
    localStorage.removeItem(AUTH_STORAGE_KEY)
  }
  dispatch({
    type: LOGOUT,
  })
}

export const forgotPassword = email => async dispatch => {
  dispatch({
    type: FORGOT_PASSWORD_REQUESTED,
  })
  try {
    const result = await axios.post('/api/auth/forgot-password', {
      email,
    })
    dispatch({
      type: FORGOT_PASSWORD,
      message: result.data.message,
    })
  } catch (error) {
    dispatch({
      type: FORGOT_PASSWORD_ERROR,
      error: error && error.response.data ? error.response.data.message : error,
    })
  }
}

export const validateResetToken = token => async dispatch => {
  dispatch({
    type: VALIDATE_RESET_TOKEN_REQUESTED,
  })
  try {
    await axios.get(`/api/auth/reset-token/${token}`)
    dispatch({
      type: VALIDATE_RESET_TOKEN,
    })
  } catch (error) {
    dispatch({
      type: VALIDATE_RESET_TOKEN_ERROR,
      error: error && error.response.data ? error.response.data.message : error,
    })
  }
}

export const resetPassword = (token, password) => async dispatch => {
  dispatch({
    type: RESET_PASSWORD_REQUESTED,
  })
  try {
    await axios.post(`/api/auth/reset-password/${token}`, {
      password,
    })
    dispatch({
      type: RESET_PASSWORD,
    })
  } catch (error) {
    dispatch({
      type: RESET_PASSWORD_ERROR,
      error: error && error.response.data ? error.response.data.message : error,
    })
  }
}

const subscribe = (socket, token) => socket.emit('subscribe', token)
