import React from 'react'
import { withStyles, Paper, Typography, Grid, Button } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

import JobProfilesList from './JobProfilesList'

const styles = {
  root: {
    flexGrow: 1,
    margin: 16,
    maxWidth: 800,
  },
  paper: {},
  title: {
    marginBottom: 16,
  },
  description: {
    marginBottom: 16,
  },
  button: {
    marginBottom: 16,
  },
}

const JobProfiles = ({
  classes,
  jobProfiles,
  onNewClick,
  onEditClick,
  onDeleteClick,
}) => (
  <div className={classes.root}>
    <Typography className={classes.title} variant="h5">
      Positions
    </Typography>
    <Grid container alignItems="flex-end">
      <Grid item xs={12} sm={8}>
        <Typography className={classes.description} variant="body2">
          Define the job positions available at your business. Specify
          responsibilities, dress codes, addresses, and other details you wish
          to make known to the contractor that will fill the job.
        </Typography>
        <Typography className={classes.description} variant="body2">
          You may wish to have several positions for the same job type if they
          have different responsibilities, addresses, or other details.
        </Typography>
        <Typography className={classes.description} variant="body2">
          Every booking you make will use a position listed here.
        </Typography>
      </Grid>
      <Grid item xs={12} sm container justify="flex-end">
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={onNewClick}
          >
            <AddIcon />
            Add
          </Button>
        </Grid>
      </Grid>
    </Grid>
    {jobProfiles.length > 0 && (
      <Paper className={classes.paper}>
        <JobProfilesList
          jobProfiles={jobProfiles}
          onItemClick={onEditClick}
          showDelete
          onDelete={onDeleteClick}
        />
      </Paper>
    )}
  </div>
)

export default withStyles(styles)(JobProfiles)
