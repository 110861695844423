import React from 'react'
import {
  CircularProgress,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  withStyles,
} from '@material-ui/core'
import MapIcon from '@material-ui/icons/Map'
import NotInterestedIcon from '@material-ui/icons/NotInterested'
import StarRateIcon from '@material-ui/icons/StarRate'
import WarningIcon from '@material-ui/icons/Warning'
import moment from 'moment'

const styles = {
  root: {
    paddingLeft: 32,
    paddingRight: 4,
  },
  detailsError: {
    marginTop: 4,
    marginBottom: 16,
  },
  starIcon: {
    verticalAlign: 'top',
    fontSize: 18,
    marginLeft: -2,
  },
  errorIcon: {
    verticalAlign: 'top',
    marginRight: 4,
  },
  address: {
    marginTop: 4,
    marginBottom: 16,
  },
  addressTitle: {
    marginRight: 8,
  },
}

const JobHistoryItem = ({ classes, job }) => {
  const primary = `${job.business.name} - ${job.jobProfile.jobType.name}`
  const startTime = job.completed ? job.actualStartTime : job.bookedStartTime
  const endTime = job.completed ? job.actualEndTime : job.bookedEndTime
  const secondary = `${moment(startTime).format(
    'ddd, MMM D @ h:mm a'
  )} - ${moment(endTime).format('h:mm a')}`
  return (
    <ListItem>
      <ListItemText primary={primary} secondary={secondary} />
      {job.completed && (
        <ListItemSecondaryAction>
          <Typography color="textSecondary">
            {job.employeeReview.rating}
            <StarRateIcon className={classes.starIcon} />
          </Typography>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  )
}
const JobHistoryItemStyled = withStyles(styles)(JobHistoryItem)

const ContractorSelectDetails = ({
  classes,
  employee: {
    address,
    pendingOffers,
    upcomingJobs,
    completedJobs,
    businessNo,
    businessNoJobType,
  },
}) => (
  <Grid container className={classes.root}>
    {businessNo && (
      <Grid item xs={12} className={classes.detailsError}>
        {businessNoJobType ? (
          <Typography color="error">
            <NotInterestedIcon className={classes.errorIcon} color="error" />
            Business does not want this employee for this job type!
          </Typography>
        ) : (
          <Typography>
            <WarningIcon className={classes.errorIcon} />
            Business has said they don't want this employee, but for a different
            job type.
          </Typography>
        )}
      </Grid>
    )}
    <Grid item xs={12} className={classes.address}>
      <Grid container alignItems="center">
        <Grid item>
          <Typography className={classes.addressTitle} variant="subtitle2">
            Address:
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography>{address}</Typography>
        </Grid>
        <Grid item sm={6} md={8}>
          <IconButton
            color="secondary"
            target="_blank"
            href={`https://maps.google.com/?q=${address}`}
          >
            <MapIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
    <Grid item sm xs={12}>
      <Typography variant="subtitle2">Pending Offers:</Typography>
      {!pendingOffers ? (
        <CircularProgress />
      ) : (
        <List dense>
          {pendingOffers.length === 0 ? (
            <ListItem>
              <ListItemText primary="None" />
            </ListItem>
          ) : (
            pendingOffers.map(offer => (
              <JobHistoryItemStyled key={offer.id} job={offer.job} />
            ))
          )}
        </List>
      )}
    </Grid>
    <Grid item sm xs={12}>
      <Typography variant="subtitle2">Upcoming jobs:</Typography>
      {!upcomingJobs ? (
        <CircularProgress />
      ) : (
        <List dense>
          {upcomingJobs.length === 0 ? (
            <ListItem>
              <ListItemText primary="None" />
            </ListItem>
          ) : (
            upcomingJobs.map(job => (
              <JobHistoryItemStyled key={job.id} job={job} />
            ))
          )}
        </List>
      )}
    </Grid>
    <Grid item sm xs={12}>
      <Typography variant="subtitle2">Past 5 jobs at business:</Typography>
      {!completedJobs ? (
        <CircularProgress />
      ) : (
        <List dense>
          {completedJobs.length === 0 ? (
            <ListItem>
              <ListItemText primary="None" />
            </ListItem>
          ) : (
            completedJobs.map(job => (
              <JobHistoryItemStyled key={job.id} job={job} />
            ))
          )}
        </List>
      )}
    </Grid>
  </Grid>
)

export default withStyles(styles)(ContractorSelectDetails)
