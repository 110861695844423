import React, { Component, Fragment } from 'react'
import {
  withStyles,
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Typography,
} from '@material-ui/core'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import InputMask from 'react-input-mask'
import PlacesAutocomplete from 'react-places-autocomplete'
import poweredByGoogle from '../images/powered_by_google_on_white.png'
import ErrorIcon from '@material-ui/icons/Error'
import RestaurantIcon from '@material-ui/icons/Restaurant'
import StoreIcon from '@material-ui/icons/Store'

import { Link } from 'react-router-dom'

import CheckboxValidator from './CheckboxValidator'

const styles = {
  root: {
    flexGrow: 1,
    margin: 16,
  },
  paper: {
    maxWidth: 1024,
    padding: 32,
  },
  title: {
    marginBottom: 8,
  },
  subtitle: {
    marginTop: 24,
    marginBottom: 8,
  },
  searchBusiness: {
    maxWidth: 600,
    marginTop: 32,
  },
  searchLabel: {
    padding: 4,
  },
  searchHelp: {
    display: 'flex',
    justifyContent: 'end',
  },
  businessDetails: {
    margin: 32,
  },
  textField: {
    minHeight: '5rem',
  },
  addressMenuItem: {
    marginTop: 8,
    marginBottom: 8,
  },
  accept: {
    marginTop: 8,
  },
  buttonArea: {
    marginTop: 8,
  },
  button: {
    marginTop: 8,
  },
}

class Register extends Component {
  // User info: name, email, password, password confirm, phone, title
  // Business info: name, phone, address
  state = {
    name: '',
    email: '',
    password: '',
    passwordConfirm: '',
    phone: '',
    title: '',
    businessName: '',
    businessPhone: '',
    businessAddress: '',
    businessLatLong: '',
    businessPlaceId: '',
    businessSearch: '',
    selectedSearch: '',
    accept: false,
  }

  handleChange = e => {
    const { name, value, type, checked } = e.target

    this.setState({
      [name]: type === 'checkbox' ? checked : value,
    })
  }

  handleSearchChange = businessSearch => {
    this.setState({
      businessSearch,
      selectedSearch: '',
      businessPlaceId: '',
      businessName: '',
      businessAddress: '',
      businessPhone: '',
      businessLatLong: '',
    })
  }

  handleSearchSelect = async (address, placeId) => {
    this.setState({
      businessSearch: address,
      selectedSearch: address,
      businessPlaceId: placeId,
    })
    new window.google.maps.places.PlacesService(
      document.createElement('div')
    ).getDetails(
      {
        placeId,
        fields: [
          'name',
          'formatted_address',
          'formatted_phone_number',
          'geometry',
        ],
      },
      ({ name, formatted_address, formatted_phone_number, geometry }) =>
        this.setState({
          businessName: name,
          businessAddress: formatted_address,
          businessPhone: formatted_phone_number,
          businessLatLong: geometry.location.toUrlValue(),
        })
    )
  }

  handleSubmit = e => {
    const {
      name,
      email,
      password,
      passwordConfirm,
      phone,
      title,
      businessName,
      businessPhone,
      businessAddress,
      businessLatLong,
      businessPlaceId,
    } = this.state
    const business = {
      name: businessName,
      phone: businessPhone,
      address: businessAddress,
      addressGPlaceId: businessPlaceId,
      latLong: businessLatLong,
    }
    const employer = {
      name,
      email,
      password,
      passwordConfirm,
      phone,
      title,
      business,
    }
    this.props.submit(employer)
    // this.setState({})
    e.preventDefault()
  }

  handleEnter = e => {
    if (e.key === 'Enter') {
      this.handleSubmit(e)
      e.preventDefault()
    }
  }

  componentDidMount = () => {
    ValidatorForm.addValidationRule(
      'isPasswordMatch',
      value => value === this.state.password
    )
    ValidatorForm.addValidationRule('isTruthy', value => value)
  }

  render = () => {
    const { classes, location, error } = this.props
    const {
      name,
      email,
      password,
      passwordConfirm,
      phone,
      title,
      businessName,
      businessPhone,
      businessAddress,
      businessSearch,
      selectedSearch,
      accept,
    } = this.state

    // Get default location if exists, otherwise default to somewhere that makes sense
    // TODO: Fallback is hard-coded to Toronto for now (Yonge & Eglinton)
    const lat = location && location.lat ? location.lat : 43.7064477
    const lng = location && location.lng ? location.lng : -79.3998241
    const defaultLocation = new window.google.maps.LatLng(lat, lng)

    return (
      <div className={classes.root}>
        <Typography className={classes.contractorLink} variant="caption">
          Are you a worker looking to get jobs with Zing?
          <Button
            color="primary"
            style={{ fontSize: '0.7rem' }}
            component={Link}
            to="/get-app"
          >
            Get the app
          </Button>
          to get started as a worker
        </Typography>
        <Paper className={classes.paper}>
          <Typography className={classes.title} variant="h5">
            Register Business
          </Typography>
          <Typography variant="body1" className={classes.description}>
            We just need a little information about your business to get started
          </Typography>
          <ValidatorForm onSubmit={this.handleSubmit} instantValidate>
            <div className={classes.searchBusiness}>
              <Typography variant="subtitle1" className={classes.searchLabel}>
                Search for your business by name
              </Typography>
              <PlacesAutocomplete
                value={businessSearch}
                onChange={this.handleSearchChange}
                onSelect={this.handleSearchSelect}
                searchOptions={{
                  location: defaultLocation,
                  types: ['establishment'],
                  radius: 50000,
                }}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <Fragment>
                    <TextValidator
                      id="businessSearch"
                      name="businessSearch"
                      label="Business Name"
                      value={businessSearch}
                      className={classes.textField}
                      variant="outlined"
                      fullWidth
                      validators={['required']}
                      errorMessages={['Required']}
                      helperText={
                        businessSearch &&
                        !selectedSearch &&
                        'You must select your business from the list'
                      }
                      error={Boolean(businessSearch && !selectedSearch)}
                      InputProps={{
                        ...getInputProps(),
                        inputProps: {
                          autoComplete: 'disabled',
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <img
                              className={classes.poweredByGoogle}
                              src={poweredByGoogle}
                              alt="Powered by Google"
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Paper>
                      {loading && <CircularProgress />}
                      {suggestions.map(suggestion => (
                        <MenuItem
                          key={suggestion.index}
                          className={classes.addressMenuItem}
                          {...getSuggestionItemProps(suggestion)}
                        >
                          <ListItemIcon>
                            {suggestion.types.includes('restaurant') ? (
                              <RestaurantIcon />
                            ) : (
                              <StoreIcon />
                            )}
                          </ListItemIcon>
                          <ListItemText
                            primary={suggestion.formattedSuggestion.mainText}
                            secondary={
                              suggestion.formattedSuggestion.secondaryText
                            }
                          />
                        </MenuItem>
                      ))}
                    </Paper>
                  </Fragment>
                )}
              </PlacesAutocomplete>
              <div className={classes.searchHelp}>
                <Typography variant="caption">
                  Can't find your business? Not listed on Google? Email
                  hello@zingwithus.com
                </Typography>
              </div>
              {businessName && (
                <div className={classes.businessDetails}>
                  <Typography variant="h6">{businessName}</Typography>
                  <Typography variant="body2">{businessAddress}</Typography>
                  <Typography variant="body2">{businessPhone}</Typography>
                </div>
              )}
            </div>
            {selectedSearch && (
              <Fragment>
                <Typography className={classes.subtitle} variant="subtitle1">
                  Information about you
                </Typography>
                <Grid container spacing={8}>
                  <Grid item xs={12} sm={6} lg={4}>
                    <TextValidator
                      id="name"
                      name="name"
                      label="Full name"
                      type="text"
                      value={name}
                      onChange={this.handleChange}
                      onKeyPress={this.handleEnter}
                      validators={['required']}
                      errorMessages={['Required']}
                      className={classes.textField}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={8}>
                  <Grid item xs={12} sm={6} lg={4}>
                    <TextValidator
                      id="email"
                      name="email"
                      label="Email"
                      type="email"
                      value={email}
                      onChange={this.handleChange}
                      onKeyPress={this.handleEnter}
                      validators={['required', 'isEmail']}
                      errorMessages={['Required', 'Invalid email format']}
                      className={classes.textField}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <InputMask
                      mask="(999) 999-9999"
                      maskChar=" "
                      value={phone}
                      onChange={this.handleChange}
                      onKeyPress={this.handleEnter}
                      className={classes.textField}
                    >
                      {() => (
                        <TextValidator
                          id="phone"
                          name="phone"
                          label="Personal phone"
                          className={classes.textField}
                          variant="outlined"
                          fullWidth
                          validators={[
                            'required',
                            'matchRegexp:^\\([0-9]{3}\\) [0-9]{3}-[0-9]{4}$',
                          ]}
                          errorMessages={['Required', 'Invalid phone number']}
                        />
                      )}
                    </InputMask>
                  </Grid>
                </Grid>
                <Grid container spacing={8}>
                  <Grid item xs={12} sm={6} lg={4}>
                    <TextValidator
                      id="password"
                      name="password"
                      label="Password"
                      type="password"
                      value={password}
                      onChange={this.handleChange}
                      onKeyPress={this.handleEnter}
                      validators={[
                        'required',
                        'matchRegexp:^(?=.*?[a-zA-Z])(?=.*?[0-9]).*$',
                        'minStringLength:8',
                      ]}
                      errorMessages={[
                        'Required',
                        'Password must contain at least one letter and one number',
                        'Must be at least 8 characters',
                      ]}
                      className={classes.textField}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <TextValidator
                      id="password-confirm"
                      name="passwordConfirm"
                      label="Confirm password"
                      type="password"
                      value={passwordConfirm}
                      onChange={this.handleChange}
                      onKeyPress={this.handleEnter}
                      validators={['required', 'isPasswordMatch']}
                      errorMessages={['Required', 'Passwords do not match']}
                      className={classes.textField}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={8}>
                  <Grid item xs={12} sm={6} lg={4}>
                    <TextValidator
                      id="title"
                      name="title"
                      label="Position/title"
                      type="text"
                      value={title}
                      onChange={this.handleChange}
                      onKeyPress={this.handleEnter}
                      validators={['required']}
                      errorMessages={['Required']}
                      className={classes.textField}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                </Grid>

                <CheckboxValidator
                  id="accept"
                  name="accept"
                  label={
                    <Typography variant="body1" style={{ paddingTop: 8 }}>
                      I have read and agree to the&nbsp;
                      <a
                        href="https://www.zingwithus.com/terms"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms and Conditions
                      </a>
                      &nbsp;and the&nbsp;
                      <a
                        href="https://www.zingwithus.com/privacy"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Privacy Policy
                      </a>
                    </Typography>
                  }
                  checked={accept}
                  value={accept}
                  onChange={this.handleChange}
                  onKeyPress={this.handleEnter}
                  validators={['isTruthy']}
                  errorMessages={['Required']}
                  className={classes.accept}
                />

                <div className={classes.buttonArea}>
                  {error && (
                    <Typography
                      className={classes.errorMessage}
                      variant="body1"
                      color="error"
                    >
                      <ErrorIcon className={classes.errorIcon} />
                      {error}
                    </Typography>
                  )}

                  <Button
                    type="submit"
                    className={classes.button}
                    variant="contained"
                    color="primary"
                  >
                    Register
                  </Button>
                </div>
              </Fragment>
            )}
          </ValidatorForm>
        </Paper>
      </div>
    )
  }
}

export default withStyles(styles)(Register)
