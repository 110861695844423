import React, { Component } from 'react'
import {
  withStyles,
  Grid,
  Typography,
  CircularProgress,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  Button,
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import moment from 'moment'
import WarningIcon from '@material-ui/icons/Warning'

import RadioValidator from './RadioValidator'
import RatingValidator from './RatingValidator'

const styles = {
  textField: {
    marginTop: 8,
    marginBottom: 8,
  },
  progress: {
    margin: 48,
  },
  hoursSection: {
    marginBottom: 24,
  },
  hours: {
    border: '2px solid',
    borderColor: 'rgba(0, 0, 0, 0.87)',
    borderRadius: 4,
    display: 'inline-block',
    padding: 4,
  },
  rating: {
    marginTop: 16,
  },
  feedbackArea: {
    marginTop: 16,
  },
}

const initialState = () => ({
  rating: null,
  again: null,
  comments: '',
})

class CompleteForm extends Component {
  state = initialState()

  handleStarRatingClick = rating => {
    this.setState({ rating })
  }

  handleChange = e => {
    const { name, value } = e.target

    this.setState({
      [name]: value,
    })
  }

  handleSubmit = e => {
    const { rating, again, comments } = this.state
    this.props.onSubmit(this.props.job, {
      rating,
      again: again === 'yes',
      comments,
    })
    e.preventDefault()
  }

  componentDidMount = () => {
    ValidatorForm.addValidationRule(
      'ratingRequired',
      value => this.state.rating > 0
    )
    ValidatorForm.addValidationRule(
      'againRequired',
      value => this.state.again !== null
    )
    ValidatorForm.addValidationRule(
      'commentRequired',
      value =>
        value.length > 1 ||
        this.state.rating === null ||
        this.state.again === null ||
        (this.state.rating >= 4 && this.state.again === 'yes')
    )
  }

  render = () => {
    const { classes, job, working, error, isAdmin } = this.props
    const { rating, again, comments } = this.state

    return (
      <ValidatorForm
        id="complete-job-form"
        onSubmit={this.handleSubmit}
        instantValidate
      >
        <Typography className={classes.hoursSection} variant="subtitle1">
          {`Booked for: 
                  ${moment(job.bookedStartTime).format('dddd, MMMM D @ h:mm a')}
                  - ${moment(job.bookedEndTime).format('h:mm a')}`}
        </Typography>
        <div className={classes.hoursSection}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item xs={6}>
              {job.actualStartTime && (
                <Typography variant="subtitle2">
                  {`Actual start time: ${moment(job.actualStartTime).format(
                    'LT'
                  )}`}
                </Typography>
              )}
              {job.actualEndTime && (
                <Typography variant="subtitle2">
                  {`Actual end time: ${moment(job.actualEndTime).format('LT')}`}
                </Typography>
              )}
            </Grid>
            <Grid item xs={6}>
              {job.actualEndTime && (
                <div className={classes.hours}>
                  <Typography variant="h6">
                    {`Hours: ${job.jobPrice.hours}`}
                  </Typography>
                </div>
              )}
            </Grid>
          </Grid>
        </div>

        <div className={classes.rating}>
          {job.employee && (
            <Typography variant="body1">
              Please rate {job.employee.user.nickname}, indicate whether you
              would use {job.employee.user.nickname} again, and leave any
              comments you have
            </Typography>
          )}
          <RatingValidator
            rating={rating}
            name="rating"
            onStarClick={this.handleStarRatingClick}
            validators={['ratingRequired']}
            errorMessages={['Required']}
          />
          <RadioValidator
            name="again"
            value={again}
            className={classes.again}
            validators={['againRequired']}
            errorMessages={['Required']}
          >
            <FormLabel component="legend">
              Would you use this worker again?
            </FormLabel>
            <RadioGroup
              aria-label="Use again?"
              name="again"
              value={again}
              onChange={this.handleChange}
            >
              <FormControlLabel
                value="yes"
                control={<Radio />}
                label="Yes, I would use this worker again"
              />
              <FormControlLabel
                value="no"
                control={<Radio />}
                label="No, please do not send me this worker again"
              />
            </RadioGroup>
          </RadioValidator>
          <TextValidator
            id="comments"
            name="comments"
            label="Comments"
            type="text"
            multiline
            rows={2}
            value={comments}
            onChange={this.handleChange}
            className={classes.textField}
            variant="outlined"
            fullWidth
            validators={['commentRequired']}
            errorMessages={[
              'Comment required for less than 4 stars or "do not send me this worker again"',
            ]}
          />
          <Grid container className={classes.feedbackArea}>
            {working && <CircularProgress />}
            {error && (
              <Grid container alignItems="center" wrap="nowrap">
                <Grid item className={classes.warningIcon}>
                  <WarningIcon color="error" />
                </Grid>
                <Typography color="error" variant="body1">
                  {error}
                </Typography>
                {!isAdmin && (
                  <Button
                    variant="text"
                    color="primary"
                    style={{ paddingLeft: 16 }}
                    component={Link}
                    to="/billing"
                  >
                    Billing
                  </Button>
                )}
              </Grid>
            )}
          </Grid>
        </div>
      </ValidatorForm>
    )
  }
}

export default withStyles(styles)(CompleteForm)
