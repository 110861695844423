import React, { Fragment } from 'react'
import { withStyles, Button, Grid, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import StripeCheckout from 'react-stripe-checkout'
import logo from '../images/zing-logo-symbol-blue.png'

const styles = {
  root: {
    margin: 16,
    maxWidth: 400,
  },
}

const StripeCheckoutWrapper = ({ children, email, onToken }) => (
  <StripeCheckout
    token={onToken}
    stripeKey={
      process.env.REACT_APP_STRIPE_KEY || 'pk_test_yl1kjQ3oeK2lfzSPE2gsShzc'
    }
    email={email}
    panelLabel="Save"
    name="Zing Inc."
    // description="Used to pay for shifts upon completion"
    image={logo}
    allowRememberMe={false}
  >
    {children}
  </StripeCheckout>
)

const BillingDetail = ({ label, value }) => (
  <Grid container justify="space-between" alignItems="center" wrap="nowrap">
    <Grid item>
      <Typography variant="subtitle1">{label}:</Typography>
    </Grid>
    <Grid item>
      <Typography variant="body2">{value}</Typography>
    </Grid>
  </Grid>
)

const Billing = ({ classes, billing, email, onToken }) => (
  <div className={classes.root}>
    {billing ? (
      <Fragment>
        <Typography variant="h6" gutterBottom>
          Credit Card Details
        </Typography>
        <BillingDetail label="Card type" value={billing.brand} />
        <BillingDetail
          label="Card number"
          value={`**** **** **** ${billing.last4}`}
        />
        <BillingDetail
          label="Card expiry"
          value={`${billing.expMonth}/${billing.expYear}`}
        />
        <BillingDetail label="Email" value={billing.email} />
        <StripeCheckoutWrapper onToken={onToken} email={email}>
          <Button variant="text" color="primary" onClick={onToken}>
            Change credit card details
          </Button>
        </StripeCheckoutWrapper>
      </Fragment>
    ) : (
      <StripeCheckoutWrapper onToken={onToken} email={email}>
        <Button variant="contained" color="secondary" onClick={onToken}>
          <AddIcon />
          Add credit card
        </Button>
      </StripeCheckoutWrapper>
    )}
  </div>
)

export default withStyles(styles)(Billing)
