import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import ReviewingApplication from './ReviewingApplication'

const PrivateRoute = ({
  component: Component,
  isAuthenticated,
  isActivated,
  isContractor,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated ? (
        isContractor ? (
          <Redirect
            to={{
              pathname: '/get-app',
              state: {
                from: props.location,
              },
            }}
          />
        ) : isActivated ? (
          <Component {...props} />
        ) : (
          <ReviewingApplication />
        )
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: {
              from: props.location,
            },
          }}
        />
      )
    }
  />
)

const mapStateToProps = ({
  authentication: {
    isAuthenticated,
    isContractor,
    user: { activated },
  },
}) => ({
  isAuthenticated,
  isContractor,
  isActivated: activated,
})

export default connect(
  mapStateToProps,
  null,
  null,
  { pure: false }
)(PrivateRoute)
