import React from 'react'
import { withStyles, Grid, ButtonBase } from '@material-ui/core'

import googlePlay from '../images/google-play-badge.png'
import appStore from '../images/apple-app-store-badge.png'

const styles = {
  googlePlay: {
    width: '100%',
  },
  appStore: {
    width: '100%',
  },
}

const AppStoreButtons = ({ classes }) => (
  <Grid container spacing={8}>
    <Grid item xs={6} sm={4} md={3} lg={2}>
      <ButtonBase
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.zingwithus.zingapp"
      >
        <img
          className={classes.googlePlay}
          src={googlePlay}
          alt="Get it on Google Play"
        />
      </ButtonBase>
    </Grid>
    <Grid item xs={6} sm={4} md={3} lg={2}>
      <ButtonBase
        target="_blank"
        href="https://apps.apple.com/us/app/zing-work/id1472397725"
      >
        <img
          className={classes.appStore}
          src={appStore}
          alt="Download on the App Store"
        />
      </ButtonBase>
    </Grid>
  </Grid>
)

export default withStyles(styles)(AppStoreButtons)
