import React, { Fragment, Component } from 'react'
import {
  withStyles,
  Grid,
  Typography,
  Divider,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from '@material-ui/core'
import moment from 'moment'

const styles = {
  title: {
    marginBottom: 16,
  },
  note: {
    marginBottom: 16,
  },
  invoiceDivider: {
    marginTop: 8,
    marginBottom: 8,
  },
}

const _Invoice = ({ classes, jobPrice, length }) => {
  const LineItem = ({ description, amount, total = false }) => (
    <Grid container justify="space-between" alignItems="center" wrap="nowrap">
      <Grid item>
        <Typography style={total ? { fontWeight: 500 } : {}} variant="body1">
          {description}
        </Typography>
      </Grid>
      <Grid item>
        <Typography style={total ? { fontWeight: 500 } : {}} variant="body1">
          ${amount}
        </Typography>
      </Grid>
    </Grid>
  )
  return (
    <Fragment>
      <LineItem
        description={`Base contractor wage (${length} hrs @ $${
          jobPrice.wage
        }/hr)`}
        amount={jobPrice.hourlyTotal}
      />
      <LineItem
        description="Zing platform charges"
        amount={jobPrice.employerZingSubtotal}
      />
      {jobPrice.employerTotalTaxes > 0 && (
        <LineItem description="Taxes" amount={jobPrice.employerTotalTaxes} />
      )}
      <Divider className={classes.invoiceDivider} variant="middle" />
      <LineItem total description="Total" amount={jobPrice.employerTotal} />
    </Fragment>
  )
}
const Invoice = withStyles(styles)(_Invoice)

class InvoiceEstimate extends Component {
  state = {
    invoiceDialogOpen: false,
  }

  handleOpenDialog = () => this.setState({ invoiceDialogOpen: true })

  handleCloseDialog = () => this.setState({ invoiceDialogOpen: false })

  handleSendInvoice = (all = false) => {
    this.props.onSendInvoice(this.props.job.id, all)
    this.handleCloseDialog()
  }

  render = () => {
    const { classes, job } = this.props
    const { invoiceDialogOpen } = this.state

    if (job.jobPrice) {
      return (
        <Fragment>
          <Grid container justify="space-between" alignItems="flex-start">
            <Typography variant="h6" className={classes.title}>
              Payment Details
            </Typography>
            {job.completed && (
              <Fragment>
                <Button color="primary" onClick={this.handleOpenDialog}>
                  Re-send invoice
                </Button>
                <Dialog
                  open={invoiceDialogOpen}
                  onClose={this.handleCloseDialog}
                  aria-labelledby="invoice-dialog-title"
                  aria-describedby="invoice-dialog-description"
                >
                  <DialogTitle id="invoice-dialog-title">
                    Re-send Invoice
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="invoice-dialog-description">
                      The invoice can be emailed only to you or re-sent to all
                      of your business' users that normally recieve invoices
                      automatically.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => this.handleSendInvoice(true)}
                      color="primary"
                    >
                      All Users
                    </Button>
                    <Button
                      onClick={() => this.handleSendInvoice(false)}
                      color="primary"
                    >
                      Only Me
                    </Button>
                  </DialogActions>
                </Dialog>
              </Fragment>
            )}
          </Grid>
          <Invoice
            jobPrice={job.jobPrice}
            length={moment(job.actualEndTime)
              .diff(job.actualStartTime, 'h', true)
              .toFixed(2)}
          />
        </Fragment>
      )
    } else if (job.jobPriceEstimate) {
      return (
        <Fragment>
          <Typography variant="h6" className={classes.title}>
            Estimate
          </Typography>
          <Typography variant="caption" className={classes.note}>
            NOTE: This is just an estimate, final charges will be based on
            actual hours.
          </Typography>
          <Invoice
            jobPrice={job.jobPriceEstimate}
            length={moment(job.bookedEndTime)
              .diff(job.bookedStartTime, 'h', true)
              .toFixed(2)}
          />
        </Fragment>
      )
    } else {
      return <Fragment />
    }
  }
}

export default withStyles(styles)(InvoiceEstimate)
