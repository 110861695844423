import React, { Fragment } from 'react'
import {
  withStyles,
  Grid,
  Paper,
  Avatar,
  Typography,
  Button,
  IconButton,
} from '@material-ui/core'
import TimerIcon from '@material-ui/icons/Timer'
import StarIcon from '@material-ui/icons/Star'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import StarRateIcon from '@material-ui/icons/StarRate'
import EditIcon from '@material-ui/icons/Edit'
import moment from 'moment'

import InvoiceEstimate from './InvoiceEstimate'
import MatchContainer from './MatchContainer'
import OfferContainer from './OfferContainer'
import CompleteJob from './CompleteJob'

const styles = theme => ({
  root: {
    margin: 16,
  },
  actions: {
    marginBottom: 8,
  },
  actionItem: {
    margin: 4,
  },
  paper: {
    // margin: 16,
    padding: 16,
  },
  title: {
    marginBottom: 16,
  },
  avatar: {
    height: 80,
    width: 80,
  },
  description: {
    marginTop: 16,
  },
  detailsItem: {
    marginTop: 4,
    marginBottom: 16,
    whiteSpace: 'pre-wrap',
  },
  detailsSection: {
    paddingBottom: 8,
  },
  countAvatar: {
    backgroundColor: theme.palette.secondary.main,
    color: 'black',
    height: 32,
    width: 32,
    fontSize: '1rem',
    marginRight: 8,
  },
  inline: {
    display: 'inline',
  },
})

const JobDetails = ({
  classes,
  job,
  isAdmin,
  isFetching,
  error,
  onStart,
  onStop,
  onComplete,
  onSendInvoice,
}) => {
  const Employee = ({
    employee: {
      photo,
      rating,
      about,
      user: { name, nickname },
    },
  }) => (
    <Fragment>
      <Grid container spacing={16} alignItems="center">
        <Grid item>
          <Avatar className={classes.avatar} alt={name} src={photo} />
        </Grid>
        <Grid item xs container direction="column">
          <Grid item>
            <Typography variant="subtitle1" className={classes.inline}>
              {nickname + ' '}
            </Typography>
            <Typography
              variant="subtitle1"
              color="textSecondary"
              className={classes.inline}
            >
              ({name})
            </Typography>
          </Grid>
          <Grid item>
            {rating && (
              <Grid container alignItems="center">
                <Grid item>
                  <Typography variant="subtitle1">{rating}</Typography>
                </Grid>
                <Grid item>
                  <StarRateIcon color="primary" />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      {typeof job.employeeBusinessCompletedCount !== 'undefined' && (
        <div className={classes.description}>
          <Grid container alignItems="center">
            <Avatar className={classes.countAvatar}>
              {job.employeeBusinessCompletedCount}
            </Avatar>
            <Typography variant="subtitle2">
              {nickname} has completed {job.employeeBusinessCompletedCount} jobs
              at {job.business.name}.
            </Typography>
          </Grid>
        </div>
      )}
      <div className={classes.description}>
        <Typography variant="subtitle2">
          Interesting things about {name}:
        </Typography>
        <Typography style={{ whiteSpace: 'pre-wrap' }}>{about}</Typography>
      </div>
    </Fragment>
  )

  const ShiftDetails = ({ job }) => (
    <Fragment>
      <Typography className={classes.detailsSection} variant="subtitle1">
        {`Booked for: 
                  ${moment(job.bookedStartTime).format('dddd, MMMM D @ h:mm a')}
                  - ${moment(job.bookedEndTime).format('h:mm a')}`}
      </Typography>
      <div className={classes.detailsSection}>
        {job.actualStartTime && (
          <Typography variant="subtitle2">
            {`Actual start time: ${moment(job.actualStartTime).format('LT')}`}
          </Typography>
        )}
        {job.actualEndTime && (
          <Typography variant="subtitle2">
            {`Actual end time: ${moment(job.actualEndTime).format('LT')}`}
          </Typography>
        )}
      </div>
      {job.jobProfile && (
        <Fragment>
          <Typography className={classes.detailsItem} variant="subtitle2">
            {job.jobProfile.jobType.name}
          </Typography>
          <Typography className={classes.detailsItem}>
            {job.jobProfile.jobType.description}
          </Typography>
          <Typography className={classes.detailsItem}>
            Dress code: {job.jobProfile.dressCode}
          </Typography>
          {job.jobProfile.description && (
            <Typography className={classes.detailsItem}>
              Position details: {job.jobProfile.description}
            </Typography>
          )}
          {job.specialInstructions && (
            <Typography className={classes.detailsItem}>
              Specific instructions for shift: {job.specialInstructions}
            </Typography>
          )}
        </Fragment>
      )}
    </Fragment>
  )

  const Rating = ({ rating }) => {
    const RatingStarIcon = ({ value }) =>
      rating >= value ? (
        <StarIcon fontSize="large" color="secondary" />
      ) : (
        <StarBorderIcon fontSize="large" color="secondary" />
      )

    let stars = []

    for (let i = 1; i <= 5; i++) {
      stars.push(
        <IconButton key={i} onClick={() => this.handleStarRatingClick(i)}>
          <RatingStarIcon value={i} />
        </IconButton>
      )
    }

    return <div className={classes.starRating}>{stars}</div>
  }

  return (
    <div className={classes.root}>
      <div className={classes.actions}>
        <Grid container alignItems="center">
          {/* Admin actions */}
          {isAdmin && (
            <Fragment>
              {!job.completed && <MatchContainer job={job} />}
              {job.employeeId && !job.completed && (
                <Fragment>
                  {job.actualStartTime ? (
                    <Fragment>
                      <div className={classes.actionItem}>
                        <Grid item container alignItems="center">
                          <Typography variant="body1">
                            {`Start Time: ${moment(job.actualStartTime).format(
                              'MMM D @ h:mm a'
                            )}`}
                          </Typography>
                          <IconButton color="primary" onClick={onStart}>
                            <EditIcon />
                          </IconButton>
                        </Grid>
                      </div>
                      {job.actualEndTime ? (
                        <div className={classes.actionItem}>
                          <Grid item container alignItems="center">
                            <Typography variant="body1">
                              {`End Time: ${moment(job.actualEndTime).format(
                                'MMM D @ h:mm a'
                              )}`}
                            </Typography>
                            <IconButton color="primary" onClick={onStop}>
                              <EditIcon />
                            </IconButton>
                          </Grid>
                        </div>
                      ) : (
                        <Button
                          onClick={onStop}
                          className={classes.actionItem}
                          variant="text"
                          color="primary"
                        >
                          <TimerIcon />
                          Stop Job
                        </Button>
                      )}
                    </Fragment>
                  ) : (
                    <Button
                      onClick={onStart}
                      className={classes.actionItem}
                      variant="text"
                      color="primary"
                      disabled={Boolean(job.actualStartTime)}
                    >
                      <TimerIcon />
                      Start Job
                    </Button>
                  )}
                </Fragment>
              )}
            </Fragment>
          )}
          {job.employeeId && !job.completed && job.actualEndTime && (
            <Grid container spacing={16}>
              <Grid item xs={12} sm={8}>
                <Paper className={classes.paper}>
                  <Typography variant="h6" className={classes.title}>
                    Complete / Review Job
                  </Typography>
                  <CompleteJob
                    job={job}
                    working={isFetching}
                    isAdmin={isAdmin}
                    error={error}
                    onSubmit={onComplete}
                  />
                </Paper>
              </Grid>
            </Grid>
          )}
        </Grid>
        {isAdmin && <Typography variant="h6">Job ID: {job.id}</Typography>}
      </div>

      {isAdmin && job.id && (
        <Grid container spacing={16}>
          <Grid item xs={12} sm={8}>
            <OfferContainer job={job} />
          </Grid>
        </Grid>
      )}

      <Grid container spacing={16}>
        {job.employee && (
          <Grid item xs={12} sm={6}>
            <Paper className={classes.paper}>
              <Typography variant="h6" className={classes.title}>
                Contractor Info
              </Typography>
              <Employee employee={job.employee} />
            </Paper>
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper}>
            <InvoiceEstimate job={job} onSendInvoice={onSendInvoice} />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper}>
            <Typography variant="h6" className={classes.title}>
              Shift Details
            </Typography>
            <ShiftDetails job={job} />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          {job.employeeReview && (
            <Paper className={classes.paper}>
              <Typography variant="h6" className={classes.title}>
                Employee Review
              </Typography>
              <Rating rating={job.employeeReview.rating} />
              <Grid container alignItems="center">
                <Typography variant="subtitle1" style={{ paddingRight: 16 }}>
                  Would I use this employee again?
                </Typography>
                <Typography color="primary">
                  {job.employeeReview.again ? 'Yes' : 'No'}
                </Typography>
              </Grid>
              {job.employeeReview.comments && (
                <Fragment>
                  <Typography variant="subtitle1">Comments</Typography>
                  <Typography>{job.employeeReview.comments}</Typography>
                </Fragment>
              )}
            </Paper>
          )}
        </Grid>
      </Grid>
    </div>
  )
}

export default withStyles(styles)(JobDetails)
