import axios from 'axios'

//////////////////////////////
// Action Types
//////////////////////////////
export const FETCH_OFFERS_REQUESTED = 'offer/FETCH_REQUESTED'
export const FETCH_OFFERS_ERROR = 'offer/FETCH_ERROR'
export const FETCH_OFFERS = 'offer/FETCH'
export const SUBMIT_OFFER_REQUESTED = 'offer/SUBMIT_REQUESTED'
export const SUBMIT_OFFER_ERROR = 'offer/SUBMIT_ERROR'
export const SUBMIT_OFFER = 'offer/SUBMIT'
export const CANCEL_OFFER_REQUESTED = 'offer/CANCEL_REQUESTED'
export const CANCEL_OFFER_ERROR = 'offer/CANCEL_ERROR'
export const CANCEL_OFFER = 'offer/CANCEL'

//////////////////////////////
// Reducer
//////////////////////////////
const initialState = {
  isFetching: false,
  offers: [],
  offer: {},
  error: null,
}
export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_OFFERS_REQUESTED:
    case SUBMIT_OFFER_REQUESTED:
    case CANCEL_OFFER_REQUESTED:
      return {
        ...state,
        isFetching: true,
      }
    case FETCH_OFFERS_ERROR:
    case SUBMIT_OFFER_ERROR:
    case CANCEL_OFFER_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      }
    case FETCH_OFFERS:
      return {
        ...state,
        isFetching: false,
        error: null,
        offers: action.offers,
      }
    case SUBMIT_OFFER:
      return {
        ...state,
        isFetching: false,
        error: null,
        offer: action.offer,
        offers: [...state.offers, action.offer],
      }
    case CANCEL_OFFER:
      return {
        ...state,
        isFetching: false,
        error: null,
        offer: action.offer,
        offers: state.offers.map(offer =>
          offer.id === action.offer.id ? action.offer : offer
        ),
      }
    default:
      return state
  }
}

//////////////////////////////
// Action Creators
//////////////////////////////

export const fetchOffers = jobId => async dispatch => {
  dispatch({
    type: FETCH_OFFERS_REQUESTED,
  })
  try {
    const result = await axios.get(`/api/jobs/${jobId}/offers`)
    dispatch({
      type: FETCH_OFFERS,
      offers: result.data,
    })
  } catch (error) {
    dispatch({
      type: FETCH_OFFERS_ERROR,
      error: error.response.data.message,
    })
  }
}

export const createOffer = offer => async dispatch => {
  dispatch({
    type: SUBMIT_OFFER_REQUESTED,
  })
  try {
    const result = await axios.post('/api/offers', offer)
    dispatch({
      type: SUBMIT_OFFER,
      offer: result.data,
    })
  } catch (error) {
    dispatch({
      type: SUBMIT_OFFER_ERROR,
      error: error.response.data.message,
    })
  }
}

export const cancelOffer = id => async dispatch => {
  dispatch({
    type: CANCEL_OFFER_REQUESTED,
  })
  try {
    const result = await axios.post(`/api/offers/${id}/cancel`)
    dispatch({
      type: CANCEL_OFFER,
      offer: result.data,
    })
  } catch (error) {
    dispatch({
      type: CANCEL_OFFER_ERROR,
      error: error.response.data.message,
    })
  }
}
