import React, { Fragment, Component } from 'react'
import { Button } from '@material-ui/core'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import JobsPending from './JobsPending'
import JobsMatched from './JobsMatched'
import JobsCompleted from './JobsCompleted'
import JobsFilter from './JobsFilter'
import JobStartDialog from './JobStartDialog'
import JobStopDialog from './JobStopDialog'
import JobCompleteDialog from './JobCompleteDialog'

import {
  fetchPendingJobs,
  fetchMatchedJobs,
  fetchCompletedJobs,
  createJob,
  startJob,
  stopJob,
  completeJob,
  selectJob,
} from '../reducers/job'
import { fetchBilling } from '../reducers/billing'
import { fetchBusinesses, selectBusiness } from '../reducers/business'

import { PAGE_SIZE as COMPLETED_PAGE_SIZE } from './JobsCompleted'

class JobsPage extends Component {
  state = {
    startJobDialogOpen: false,
    stopJobDialogOpen: false,
    completeJobDialogOpen: false,
    // For completed job pagination
    completedJobsOffset: 0,
  }

  componentDidMount = () => {
    this.fetchJobs(this.props.selectedBusiness)

    if (this.props.isAdmin) {
      this.props.fetchBusinesses()
    } else {
      this.props.fetchBilling()
    }
  }

  handleFilterJobs = businessId => {
    this.props.selectBusiness(businessId)
    this.setState({ completedJobsOffset: 0 })
    this.fetchJobs(businessId)
  }

  fetchJobs = businessId => {
    this.props.fetchPendingJobs({ businessId, sort: 'bookedStartTime.asc' })
    this.props.fetchMatchedJobs({ businessId, sort: 'bookedStartTime.desc' })
    this.props.fetchCompletedJobs(
      {
        businessId,
        limit: COMPLETED_PAGE_SIZE,
        offset: 0,
        sort: 'bookedStartTime.desc',
      },
      false
    )
  }

  fetchMoreJobs = async () => {
    if (
      !this.props.isFetching &&
      this.props.completed.length < this.props.completedTotal
    ) {
      const offset = this.state.completedJobsOffset + COMPLETED_PAGE_SIZE
      this.setState({ completedJobsOffset: offset })
      this.props.fetchCompletedJobs(
        {
          businessId: this.props.selectedBusiness,
          limit: COMPLETED_PAGE_SIZE,
          offset,
          sort: 'bookedStartTime.desc',
        },
        true
      )
    }
  }

  handleAddClick = () => {
    this.props.history.push('/schedule-shift')
  }

  handleStartJobClick = (e, job) => {
    this.props.selectJob(job)
    this.setState({ startJobDialogOpen: true })
  }

  handleStopJobClick = (e, job) => {
    this.props.selectJob(job)
    this.setState({ stopJobDialogOpen: true })
  }

  handleCompleteJobClick = (e, job) => {
    this.props.selectJob(job)
    this.setState({ completeJobDialogOpen: true })
  }

  handleSelectJobClick = (e, job) => {
    this.props.history.push('/shift-details/' + job.id)
  }

  handleSubmitJob = async job => {
    await this.props.createJob(job)
  }

  handleSubmitStartJob = (job, time) => {
    this.props.startJob(job.id, time)
  }

  handleSubmitStopJob = (job, time) => {
    this.props.stopJob(job.id, time)
  }

  handleSubmitCompleteJob = async (job, review) => {
    await this.props.completeJob(job.id, review)
    if (!this.props.error) {
      this.handleDialogClose()
    }
  }

  handleDialogClose = () => {
    this.setState({
      startJobDialogOpen: false,
      stopJobDialogOpen: false,
      completeJobDialogOpen: false,
    })
  }

  render = () => {
    const {
      isAdmin,
      isFetching,
      job,
      pending,
      matched,
      completed,
      completedTotal,
      error,
      billing,
      externalPayment,
      businesses,
      selectedBusiness,
    } = this.props
    const {
      startJobDialogOpen,
      stopJobDialogOpen,
      completeJobDialogOpen,
    } = this.state

    return (
      <Fragment>
        {isAdmin ? (
          <JobsFilter
            businesses={businesses}
            selectedBusiness={selectedBusiness}
            filter={this.handleFilterJobs}
          />
        ) : (
          <Button
            variant="contained"
            color="secondary"
            size="large"
            style={{ margin: 32, marginBottom: 16 }}
            onClick={this.handleAddClick}
          >
            Schedule shift
          </Button>
        )}
        <JobsPending
          jobs={pending}
          showBusiness={isAdmin}
          showBillingWarning={!isAdmin && !externalPayment && !billing}
          showOfferCounts={isAdmin}
          onSelect={this.handleSelectJobClick}
        />
        <JobsMatched
          jobs={matched}
          showBusiness={isAdmin}
          showStartStop={isAdmin}
          onSelect={this.handleSelectJobClick}
          onStart={this.handleStartJobClick}
          onStop={this.handleStopJobClick}
          onComplete={this.handleCompleteJobClick}
        />
        <JobsCompleted
          jobs={completed}
          showBusiness={isAdmin}
          onSelect={this.handleSelectJobClick}
          onNextPage={this.fetchMoreJobs}
          hasNextPage={completed.length < completedTotal}
        />
        {isAdmin && (
          <Fragment>
            <JobStartDialog
              open={startJobDialogOpen}
              job={job}
              onClose={this.handleDialogClose}
              onSubmit={this.handleSubmitStartJob}
            />
            <JobStopDialog
              open={stopJobDialogOpen}
              job={job}
              onClose={this.handleDialogClose}
              onSubmit={this.handleSubmitStopJob}
            />
          </Fragment>
        )}
        <JobCompleteDialog
          isAdmin={isAdmin}
          open={completeJobDialogOpen}
          job={job}
          working={isFetching}
          error={error}
          onClose={this.handleDialogClose}
          onSubmit={this.handleSubmitCompleteJob}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = ({
  authentication: { isAdmin, user },
  job: { isFetching, pending, matched, completed, completedTotal, job, error },
  billing: { billing },
  business: { businesses, selectedBusiness },
}) => ({
  isAdmin,
  isFetching,
  pending,
  matched,
  completed,
  completedTotal,
  job,
  error,
  billing,
  externalPayment:
    user.employer &&
    user.employer.business &&
    user.employer.business.externalPayment,
  businesses,
  selectedBusiness,
})

const mapDispatchToProps = {
  fetchPendingJobs,
  fetchMatchedJobs,
  fetchCompletedJobs,
  fetchBilling,
  fetchBusinesses,
  selectBusiness,
  createJob,
  startJob,
  stopJob,
  completeJob,
  selectJob,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(JobsPage))
