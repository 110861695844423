import React from 'react'
import { withStyles, Button, Grid, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'

import JobProfilesList from './JobProfilesList'

const styles = {
  root: {},
}

const ScheduleJobPosition = ({
  classes,
  jobProfiles,
  selectedProfile,
  onSelectProfile,
  onAddProfile,
}) => (
  <div className={classes.root}>
    <Typography>
      Select from your list of positions or create a new one. Each saved
      position has a job type, dress code, address, instructions, etc.
    </Typography>
    <Grid container direction="row-reverse">
      <Button color="primary" onClick={onAddProfile}>
        {/* <AddIcon /> */}
        New Position
      </Button>
      <Button component={Link} to="/positions">
        Manage my positions
      </Button>
    </Grid>
    <JobProfilesList
      jobProfiles={jobProfiles}
      radio
      selectedProfile={selectedProfile}
      onItemClick={onSelectProfile}
    />
    {/* <Grid container style={{ marginBottom: 16 }}>
      <Button className={classes.button} color="primary">
        <AddIcon />
        New Position
      </Button>
      <Button>Manage my positions</Button>
    </Grid> */}
  </div>
)

export default withStyles(styles)(ScheduleJobPosition)
