import React, { Component, createRef } from 'react'
import {
  Avatar,
  Checkbox,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Switch,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core'
import Pagination from 'material-ui-flat-pagination'

import ContractorStats from './ContractorStats'

const styles = {
  paper: {
    margin: 16,
    maxWidth: 800,
  },
  header: {
    padding: 16,
  },
  toolbar: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  search: {},
}

const PAGE_SIZE = 10
const SEARCH_DELAY = 500 // Don't make calls if user is typing. Timeout in ms

class Contractors extends Component {
  //TODO: This functionality for handling pagination of Contractors is common with ContractorSelect
  // and should be refactored to a common HOC component or something
  state = {
    limit: PAGE_SIZE,
    offset: 0,
    sort: null,
    search: '',
    showDeactivated: false,
  }

  timeout = null // Search timeout
  ref = createRef()

  componentDidMount = () => {
    this.fetchContractors()
  }

  fetchContractors = clearOffset => {
    let {
      limit,
      offset,
      sort,
      search,
      showDeactivated: includeInactive,
    } = this.state
    if (clearOffset) {
      offset = 0
      this.setState({ offset })
    }
    this.props.fetchContractors({
      limit,
      offset,
      sort,
      search,
      includeStats: true,
      includeInactive,
      concat: false,
    })
  }

  handleSearchChange = e => {
    const search = e.target.value
    this.setState({ search })

    // Only send the search text to fetch if typing stops
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    this.timeout = setTimeout(() => {
      this.fetchContractors(true)
    }, SEARCH_DELAY)
  }

  handleToggleShowDeactivated = () => {
    const { showDeactivated } = this.state
    this.setState({ showDeactivated: !showDeactivated }, () =>
      this.fetchContractors(true)
    )
  }

  handlePageClick = offset => {
    this.setState({ offset }, () => this.fetchContractors())
  }

  render = () => {
    const {
      classes,
      contractors,
      contractorTotal,
      onSelect,
      onToggleActivated,
    } = this.props
    const { offset, search, showDeactivated } = this.state
    return (
      <Paper className={classes.paper}>
        <Typography className={classes.header} variant="h5">
          Contractors
        </Typography>
        <div className={classes.toolbar}>
          <Grid container justify="space-between">
            <TextField
              className={classes.search}
              id="search"
              label="Search"
              type="search"
              value={search}
              onChange={this.handleSearchChange}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={showDeactivated}
                  onChange={this.handleToggleShowDeactivated}
                  value={String(showDeactivated)}
                />
              }
              label="Show de-activated"
            />
          </Grid>
          <Pagination
            limit={PAGE_SIZE}
            offset={offset}
            total={contractorTotal}
            onClick={(e, offset) => this.handlePageClick(offset)}
          />
        </div>
        <List className={classes.list}>
          {contractors.map(contractor => (
            <div key={contractor.id}>
              <ListItem
                key={contractor.id}
                button
                onClick={() => onSelect(contractor)}
              >
                <ListItemAvatar>
                  <Avatar
                    alt={contractor.user.nickname}
                    src={contractor.photo}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={`${contractor.user.name} (${
                    contractor.user.nickname
                  })`}
                  secondaryTypographyProps={{
                    component: 'div',
                  }}
                  secondary={<ContractorStats contractor={contractor} />}
                />
                <ListItemSecondaryAction>
                  <Switch
                    checked={contractor.user.activated}
                    onChange={() => onToggleActivated(contractor)}
                    value={contractor.user.activated}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            </div>
          ))}
        </List>
      </Paper>
    )
  }
}

export default withStyles(styles)(Contractors)
