import React, { Component } from 'react'
import { withStyles, Button } from '@material-ui/core'

import ContractorSelectDialog from './ContractorSelectDialog'

const styles = {
  root: {
    margin: 8,
  },
}

class MatchDialog extends Component {
  state = {
    open: false,
  }

  handleOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  handleMatch = employeeId => {
    this.props.onSubmit(employeeId)
    this.handleClose()
  }

  render = () => {
    const {
      classes,
      job,
      region,
      employees,
      employeeTotal,
      selectedEmployee,
      fetchEmployees,
      isFetchingEmployees,
      onSelect,
    } = this.props
    const { open } = this.state

    return (
      <div className={classes.root}>
        <Button variant="contained" color="secondary" onClick={this.handleOpen}>
          Match
        </Button>
        <ContractorSelectDialog
          open={open}
          job={job}
          region={region}
          employees={employees}
          employeeTotal={employeeTotal}
          selectedEmployee={selectedEmployee}
          fetchEmployees={fetchEmployees}
          isFetching={isFetchingEmployees}
          onClose={this.handleClose}
          onSelect={onSelect}
          onSubmit={this.handleMatch}
        />
      </div>
    )
  }
}

export default withStyles(styles)(MatchDialog)
