import React from 'react'
import { withStyles, Typography, Grid } from '@material-ui/core'
import DoneIcon from '@material-ui/icons/Done'
import FavoriteIcon from '@material-ui/icons/Favorite'
import GradeIcon from '@material-ui/icons/Grade'
import WorkIcon from '@material-ui/icons/Work'

const styles = {
  root: {},
  item: {
    minWidth: 45,
  },
  icon: {
    verticalAlign: 'top',
    fontSize: 18,
  },
}

const ContractorStats = ({
  classes,
  contractor,
  hideFavouriteCount = false,
  showSkill = false,
}) => (
  <Grid className={classes.root} container>
    <div className={classes.item}>
      <Typography color="textSecondary" noWrap>
        {`${contractor.completedCount}`}
        <DoneIcon className={classes.icon} />
      </Typography>
    </div>
    <div className={classes.item}>
      <Typography color="textSecondary" noWrap>
        {`${contractor.rating ? contractor.rating : '--'}`}
        <GradeIcon className={classes.icon} />
      </Typography>
    </div>
    {showSkill && (
      <div className={classes.item}>
        <Typography color="textSecondary" noWrap>
          {`${contractor.jobTypeSkill ? contractor.jobTypeSkill : '--'}`}
          <WorkIcon className={classes.icon} />
        </Typography>
      </div>
    )}
    {!hideFavouriteCount && (
      <div className={classes.item}>
        <Typography color="textSecondary" noWrap>
          {`${contractor.favouritedCount ? contractor.favouritedCount : 0}`}
          <FavoriteIcon className={classes.icon} />
        </Typography>
      </div>
    )}
  </Grid>
)

export default withStyles(styles)(ContractorStats)
