import React from 'react'
import { connect } from 'react-redux'

import ForgotPassword from './ForgotPassword'

import { forgotPassword } from '../reducers/authentication'

const ForgotPasswordPage = ({ message, error, isFetching, forgotPassword }) => (
  <ForgotPassword
    onSubmit={forgotPassword}
    error={error}
    message={message}
    processing={isFetching}
  />
)

const mapStateToProps = ({
  authentication: { isFetching, error, message },
}) => ({
  error,
  message,
  isFetching,
})

const mapDispatchToProps = {
  forgotPassword,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordPage)
