import React from 'react'
import {
  withStyles,
  Drawer,
  Hidden,
  Divider,
  List,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from '@material-ui/core'
// import HomeIcon from '@material-ui/icons/Home'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import AssignmentIcon from '@material-ui/icons/Assignment'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import GetAppIcon from '@material-ui/icons/GetApp'
import HelpIcon from '@material-ui/icons/Help'
import PersonIcon from '@material-ui/icons/Person'
import WorkIcon from '@material-ui/icons/Work'

import { Link } from 'react-router-dom'

import { drawerWidth } from '../config/theme'
import logo from '../images/zing-logo.png'

const styles = theme => ({
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  header: {
    height: 56,
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      height: 48,
    },
    [theme.breakpoints.up('sm')]: {
      height: 64,
    },
  },
  logo: {
    height: '100%',
    padding: 4,
    paddingLeft: 14,
  },
})

const mainItems = [
  // {
  //   name: 'Dashboard',
  //   route: '/',
  //   icon: <HomeIcon />,
  // },
  {
    name: 'Bookings',
    route: '/bookings',
    icon: <WorkIcon />,
    selected: page =>
      page === 'Bookings' ||
      page === 'Schedule Shift' ||
      page === 'Shift Details',
  },
  {
    name: 'Positions',
    route: '/positions',
    icon: <AssignmentIcon />,
    selected: page => page === 'Positions',
  },
  {
    name: 'Billing',
    route: '/billing',
    icon: <CreditCardIcon />,
    selected: page => page === 'Billing',
  },
]
const adminItems = [
  {
    name: 'Bookings',
    route: '/bookings',
    icon: <WorkIcon />,
    selected: page =>
      page === 'Bookings' ||
      page === 'Schedule Shift' ||
      page === 'Shift Details',
  },
  {
    name: 'Contractors',
    route: '/contractors',
    icon: <PersonIcon />,
    selected: page => page === 'Contractors' || page === 'Contractor Details',
  },
]
const contractorItems = [
  {
    name: 'Get App',
    route: '/get-app',
    icon: <GetAppIcon />,
    selected: page => page === 'Sign Up Success',
  },
]
const loggedOutItems = [
  {
    name: 'Log in',
    route: '/login',
    icon: <AccountCircleIcon />,
    selected: page => page === 'Login',
  },
]
const bottomItems = [
  {
    name: 'Help Center',
    route: '/help',
    icon: <HelpIcon />,
    selected: page => false,
  },
]

const AppDrawer = ({
  classes,
  theme,
  open,
  isAuthenticated,
  isAdmin,
  isContractor,
  currentPage,
  onClose,
}) => {
  const items = !isAuthenticated
    ? loggedOutItems
    : isAdmin
    ? adminItems
    : isContractor
    ? contractorItems
    : mainItems

  const contents = (
    <div>
      {/* <div className={classes.toolbar} /> */}
      <div className={classes.header}>
        <img className={classes.logo} src={logo} alt="Logo" />
      </div>
      <Divider />
      <List>
        {items.map(item => (
          <MenuItem
            button
            key={item.name}
            component={Link}
            to={item.route}
            onClick={onClose}
            selected={item.selected(currentPage)}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.name} />
          </MenuItem>
        ))}
      </List>
      <Divider />
      <List>
        {bottomItems.map(item => (
          <MenuItem button key={item.name}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.name} />
          </MenuItem>
        ))}
      </List>
    </div>
  )

  return (
    <nav className={classes.drawer}>
      <Hidden mdUp>
        <Drawer
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={open}
          onClose={onClose}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          {contents}
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {contents}
        </Drawer>
      </Hidden>
    </nav>
  )
}

export default withStyles(styles, { withTheme: true })(AppDrawer)
