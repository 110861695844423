import React, { Fragment } from 'react'
import { ValidatorComponent } from 'react-form-validator-core'
import {
  withStyles,
  FormControlLabel,
  FormHelperText,
  Checkbox,
} from '@material-ui/core'

const styles = {
  error: {
    margin: 0,
  },
}

class CheckboxValidator extends ValidatorComponent {
  render() {
    /* eslint-disable no-unused-vars */
    const {
      classes,
      label,
      errorMessages,
      validators,
      requiredError,
      validatorListener,
      ...rest
    } = this.props
    const { isValid } = this.state
    return (
      <Fragment>
        <FormControlLabel label={label} control={<Checkbox {...rest} />} />
        {!isValid && (
          <FormHelperText error className={classes.error}>
            {this.getErrorMessage()}
          </FormHelperText>
        )}
      </Fragment>
    )
  }
}

export default withStyles(styles)(CheckboxValidator)
