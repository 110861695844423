import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
  fetchEmployee,
  updateEmployee,
  updateIdentificationPhoto,
  updateResume,
} from '../reducers/employee'
import { fetchJobTypes } from '../reducers/job-type'
import { fetchSoftSkills } from '../reducers/soft-skill'
import { geolocate } from '../reducers/maps'

import ContractorDetails from './ContractorDetails'

class ContractorsDetailsPage extends Component {
  componentDidMount = () => {
    this.props.fetchEmployee(this.props.match.params.id)
    this.props.fetchJobTypes(this.props.user.regionId)
    this.props.fetchSoftSkills()
    this.props.geolocate()
  }

  render = () => {
    const {
      employee,
      jobTypes,
      softSkills,
      error,
      location,
      isFetchingEmployees,
      isFetchingJobTypes,
      isFetchingSoftSkills,
      updateEmployee,
      updateIdentificationPhoto,
      updateResume,
    } = this.props
    // Note: "key" is important here because state of component is tied to props
    // See https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#recommendation-fully-uncontrolled-component-with-a-key
    return (
      <ContractorDetails
        key={employee && jobTypes && jobTypes.length > 0 && employee.id}
        contractor={employee}
        jobTypes={jobTypes}
        softSkills={softSkills}
        location={location}
        error={error}
        loadingContractor={isFetchingEmployees || !employee}
        loadingJobTypes={isFetchingJobTypes}
        loadingSoftSkills={isFetchingSoftSkills}
        onUploadIdentificationPhoto={updateIdentificationPhoto}
        onUploadResume={updateResume}
        onSave={updateEmployee}
      />
    )
  }
}

const mapStateToProps = ({
  authentication: { user },
  employee: { employee, isFetching: isFetchingEmployees, error },
  jobType: { jobTypes, isFetching: isFetchingJobTypes },
  softSkill: { softSkills, isFetching: isFetchingSoftSkills },
}) => ({
  user,
  employee,
  jobTypes,
  softSkills,
  error,
  isFetchingEmployees,
  isFetchingJobTypes,
  isFetchingSoftSkills,
})

const mapDispatchToProps = {
  updateEmployee,
  updateIdentificationPhoto,
  updateResume,
  fetchEmployee,
  fetchJobTypes,
  fetchSoftSkills,
  geolocate,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContractorsDetailsPage)
