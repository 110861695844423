import React, { Fragment } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import PrivateRoute from './PrivateRoute'
import LoginPage from './LoginPage'
import ForgotPasswordPage from './ForgotPasswordPage'
import ResetPasswordPage from './ResetPasswordPage'
import RegisterPage from './RegisterPage'
import JobsPage from './JobsPage'
import JobProfilesPage from './JobProfilesPage'
import JobDetailsPage from './JobDetailsPage'
import ScheduleJobPage from './ScheduleJobPage'
import BillingPage from './BillingPage'
import DownloadApp from './DownloadApp'
import ContractorsPage from './ContractorsPage'
import ContractorDetailsPage from './ContractorDetailsPage'

export const getRouteName = path => {
  // Look at first part of path only
  const firstPart =
    path.indexOf('/', 1) > 1 ? path.substring(0, path.indexOf('/', 1)) : path
  switch (firstPart) {
    case '/login':
      return 'Login'
    case '/register':
      return 'Register Business'
    case '/get-app':
      return 'Get the Zing app'
    case '/forgot-password':
      return 'Forgot Password'
    case '/reset-password':
      return 'Reset Password'
    case '/positions':
      return 'Positions'
    case '/bookings':
    case '/':
      return 'Bookings'
    case '/schedule-shift':
      return 'Schedule Shift'
    case '/shift-details':
      return 'Shift Details'
    case '/billing':
      return 'Billing'
    case '/contractors':
      return 'Contractors'
    case '/contractor-details':
      return 'Contractor Details'
    default:
      return 'Zing'
  }
}

const Routes = () => (
  <Fragment>
    <Switch>
      <Route path="/login" component={LoginPage} name="Login" />
      <Route path="/forgot-password" component={ForgotPasswordPage} />
      <Route path="/reset-password/:token" component={ResetPasswordPage} />
      <Route path="/register" component={RegisterPage} />
      <Redirect from="/contractor-register" to="get-app" />
      <Route path="/get-app" component={DownloadApp} />
      <PrivateRoute path="/positions" component={JobProfilesPage} />
      <PrivateRoute path="/bookings" component={JobsPage} />
      <PrivateRoute path="/schedule-shift" component={ScheduleJobPage} />
      <PrivateRoute path="/billing" component={BillingPage} />
      <PrivateRoute path="/shift-details/:id" component={JobDetailsPage} />
      <PrivateRoute path="/contractors" component={ContractorsPage} />
      <PrivateRoute
        path="/contractor-details/:id"
        component={ContractorDetailsPage}
      />
      <PrivateRoute path="/" component={JobsPage} />
    </Switch>
  </Fragment>
)

export default Routes
