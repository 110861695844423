import React from 'react'
import { withStyles, Typography } from '@material-ui/core'

const styles = {
  root: {
    margin: 16,
  },
}

const ReviewingApplication = ({ classes }) => (
  <div className={classes.root}>
    <Typography variant="h6">Thanks for registering with Zing.</Typography>
    <Typography style={{ marginTop: 8 }}>
      We are reviewing your application, and will contact you soon. Once you are
      activated you will have access to all functionality of Zing.
    </Typography>
  </div>
)

export default withStyles(styles)(ReviewingApplication)
