import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  palette: {
    primary: {
      // main: '#4da1e4',
      // For some reason, the color actually being used doesn't match whaqt is put here.
      // Setting main so that it will match what is meant to be
      main: '#48A0E7',
      light: '#86d2ff',
      dark: '#0073b2',
      contrastText: '#fff',
    },
    secondary: {
      main: '#ffca28',
      light: '#fffd61',
      dark: '#c79a00',
      contrastText: '#000',
    },
  },
  typography: {
    useNextVariants: true,
  },
})

export const drawerWidth = 240

export default theme
