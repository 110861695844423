import React from 'react'
import { withStyles, Button } from '@material-ui/core'

import CompleteForm from './CompleteForm'

const styles = {
  root: {},
  submitButton: {
    marginTop: 16,
  },
}

const CompleteJob = ({ classes, job, working, isAdmin, error, onSubmit }) => (
  <div className={classes.root}>
    <CompleteForm
      job={job}
      working={working}
      isAdmin={isAdmin}
      error={error}
      onSubmit={onSubmit}
    />
    <Button
      className={classes.submitButton}
      type="submit"
      disabled={working}
      form="complete-job-form"
      color="primary"
      variant="contained"
    >
      Complete
    </Button>
  </div>
)

export default withStyles(styles)(CompleteJob)
