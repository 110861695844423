import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'

import JobDetails from './JobDetails'
import JobStartDialog from './JobStartDialog'
import JobStopDialog from './JobStopDialog'

import {
  fetchJob,
  startJob,
  stopJob,
  completeJob,
  matchJob,
  sendInvoice,
} from '../reducers/job'
import { fetchOffers } from '../reducers/offer'

class JobDetailsPage extends Component {
  state = {
    startJobDialogOpen: false,
    stopJobDialogOpen: false,
  }

  componentDidMount = () => {
    this.props.fetchJob(this.props.match.params.id)
    if (this.props.isAdmin) {
      this.props.fetchOffers(this.props.match.params.id)
    }
  }

  handleStartJobClick = () => {
    this.setState({ startJobDialogOpen: true })
  }

  handleStopJobClick = () => {
    this.setState({ stopJobDialogOpen: true })
  }

  handleSubmitStartJob = (job, time) => {
    this.props.startJob(job.id, time)
  }

  handleSubmitStopJob = (job, time) => {
    this.props.stopJob(job.id, time)
  }

  handleSubmitCompleteJob = (job, time) => {
    this.props.completeJob(job.id, time)
  }

  handleDialogClose = () => {
    this.setState({
      startJobDialogOpen: false,
      stopJobDialogOpen: false,
    })
  }

  render = () => {
    const { job, isFetching, isAdmin, error, sendInvoice } = this.props
    const { startJobDialogOpen, stopJobDialogOpen } = this.state

    return (
      <Fragment>
        <JobDetails
          job={job}
          isAdmin={isAdmin}
          isFetching={isFetching}
          error={error}
          onStart={this.handleStartJobClick}
          onStop={this.handleStopJobClick}
          onComplete={this.handleSubmitCompleteJob}
          onSendInvoice={sendInvoice}
        />
        {isAdmin && (
          <Fragment>
            <JobStartDialog
              open={startJobDialogOpen}
              job={job}
              onClose={this.handleDialogClose}
              onSubmit={this.handleSubmitStartJob}
            />
            <JobStopDialog
              open={stopJobDialogOpen}
              job={job}
              onClose={this.handleDialogClose}
              onSubmit={this.handleSubmitStopJob}
            />
          </Fragment>
        )}
      </Fragment>
    )
  }
}

const mapStateToProps = ({
  job: { job, isFetching, error },
  authentication: { isAdmin },
}) => ({
  job,
  isFetching,
  isAdmin,
  error,
})

const mapDispatchToProps = {
  fetchJob,
  fetchOffers,
  startJob,
  stopJob,
  completeJob,
  matchJob,
  sendInvoice,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JobDetailsPage)
