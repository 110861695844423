import axios from 'axios'

import store from '../store'
import { logout } from '../reducers/authentication'

export const AUTH_STORAGE_KEY = 'zing-auth-token'
const TIMEOUT = 10000

export const configInterceptors = () => {
  // Add Bearer <token> to all requests
  axios.interceptors.request.use(config => {
    const token = localStorage.getItem(AUTH_STORAGE_KEY)
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    config.timeout = TIMEOUT
    return config
  })

  // Check for unauthorized, and log user out. JWT token has expired.
  axios.interceptors.response.use(
    response => {
      return response
    },
    error => {
      if (error.response.status === 401) {
        store.dispatch(logout())
      }
      console.log(error)
      return Promise.reject(error)
    }
  )
}
