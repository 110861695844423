import axios from 'axios'

//////////////////////////////
// Action Types
//////////////////////////////
export const FETCH_JOB_PROFILES_REQUESTED = 'jobProfile/FETCH_REQUESTED'
export const FETCH_JOB_PROFILES_ERROR = 'jobProfile/FETCH_ERROR'
export const FETCH_JOB_PROFILES = 'jobProfile/FETCH'
export const SUBMIT_JOB_PROFILE_REQUESTED = 'jobProfile/SUBMIT_REQUESTED'
export const SUBMIT_JOB_PROFILE_ERROR = 'jobProfile/SUBMIT_ERROR'
export const SUBMIT_JOB_PROFILE = 'jobProfile/SUBMIT'
export const UPDATE_JOB_PROFILE_REQUESTED = 'jobProfile/UPDATE_REQUESTED'
export const UPDATE_JOB_PROFILE_ERROR = 'jobProfile/UPDATE_ERROR'
export const UPDATE_JOB_PROFILE = 'jobProfile/UPDATE'
export const DELETE_JOB_PROFILE_REQUESTED = 'jobProfile/DELETE_REQUESTED'
export const DELETE_JOB_PROFILE_ERROR = 'jobProfile/DELETE_ERROR'
export const DELETE_JOB_PROFILE = 'jobProfile/DELETE'

//////////////////////////////
// Reducer
//////////////////////////////
const initialState = {
  isFetching: false,
  jobProfiles: [],
  jobProfile: {},
  error: null,
}
export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_JOB_PROFILES_REQUESTED:
      return {
        ...state,
        isFetching: true,
      }
    case FETCH_JOB_PROFILES_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      }
    case FETCH_JOB_PROFILES:
      return {
        ...state,
        isFetching: false,
        error: null,
        jobProfiles: action.jobProfiles,
      }
    case SUBMIT_JOB_PROFILE_REQUESTED:
      return {
        ...state,
        isFetching: true,
      }
    case SUBMIT_JOB_PROFILE_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      }
    case SUBMIT_JOB_PROFILE:
      return {
        ...state,
        isFetching: false,
        error: null,
        jobProfile: action.jobProfile,
        jobProfiles: [...state.jobProfiles, action.jobProfile],
      }
    case UPDATE_JOB_PROFILE_REQUESTED:
      return {
        ...state,
        isFetching: true,
      }
    case UPDATE_JOB_PROFILE_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      }
    case UPDATE_JOB_PROFILE:
      return {
        ...state,
        isFetching: false,
        error: null,
        jobProfile: action.jobProfile,
        jobProfiles: state.jobProfiles.map(jobProfile =>
          action.jobProfile.id === jobProfile.id
            ? action.jobProfile
            : jobProfile
        ),
      }
    case DELETE_JOB_PROFILE_REQUESTED:
      return {
        ...state,
        isFetching: true,
      }
    case DELETE_JOB_PROFILE_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      }
    case DELETE_JOB_PROFILE:
      return {
        ...state,
        isFetching: false,
        error: null,
        jobProfiles: state.jobProfiles.filter(
          jobProfile => action.id !== jobProfile.id
        ),
      }
    default:
      return state
  }
}

//////////////////////////////
// Action Creators
//////////////////////////////

export const fetchJobProfiles = () => async dispatch => {
  dispatch({
    type: FETCH_JOB_PROFILES_REQUESTED,
  })
  try {
    const result = await axios.get('/api/job-profiles')
    dispatch({
      type: FETCH_JOB_PROFILES,
      jobProfiles: result.data,
    })
  } catch (error) {
    dispatch({
      type: FETCH_JOB_PROFILES_ERROR,
      error: error.response.data.message,
    })
  }
}

export const createJobProfile = jobProfile => async dispatch => {
  dispatch({
    type: SUBMIT_JOB_PROFILE_REQUESTED,
  })
  try {
    const result = await axios.post('/api/job-profiles', jobProfile)
    dispatch({
      type: SUBMIT_JOB_PROFILE,
      jobProfile: result.data,
    })
    return result.data
  } catch (error) {
    dispatch({
      type: SUBMIT_JOB_PROFILE_ERROR,
      error: error.response.data.message,
    })
  }
}

export const updateJobProfile = jobProfile => async dispatch => {
  dispatch({
    type: UPDATE_JOB_PROFILE_REQUESTED,
  })
  try {
    const result = await axios.put(
      `/api/job-profiles/${jobProfile.id}`,
      jobProfile
    )
    dispatch({
      type: UPDATE_JOB_PROFILE,
      jobProfile: result.data,
    })
  } catch (error) {
    dispatch({
      type: UPDATE_JOB_PROFILE_ERROR,
      error: error.response.data.message,
    })
  }
}

export const deleteJobProfile = jobProfile => async dispatch => {
  dispatch({
    type: DELETE_JOB_PROFILE_REQUESTED,
  })
  try {
    await axios.put(`/api/job-profiles/${jobProfile.id}`, {
      ...jobProfile,
      deleted: true,
    })
    dispatch({
      type: DELETE_JOB_PROFILE,
      id: jobProfile.id,
    })
  } catch (error) {
    dispatch({
      type: DELETE_JOB_PROFILE_ERROR,
      error: error.response.data.message,
    })
  }
}
