import io from 'socket.io-client'
import { NOTIFICATION_ADD } from './notification'
import { SET_USER } from './authentication'
import { JOB_MATCHED, STOP_JOB } from './job'

//////////////////////////////
// Action Types
//////////////////////////////
export const SOCKETIO_CONNECT = 'socketio/CONNECT'

//////////////////////////////
// Reducer
//////////////////////////////
const initialState = {
  isConnected: false,
  socket: {},
}
export default (state = initialState, action) => {
  switch (action.type) {
    case SOCKETIO_CONNECT:
      return {
        ...state,
        isConnected: true,
        socket: action.socket,
      }
    default:
      return state
  }
}

//////////////////////////////
// Action Creators
//////////////////////////////

export const connectSocket = () => dispatch => {
  const socket = io()

  dispatch({
    type: SOCKETIO_CONNECT,
    socket,
  })

  socket.on('Account activated', payload => {
    const user = JSON.parse(payload)

    dispatch({
      type: SET_USER,
      user,
    })

    dispatch({
      type: NOTIFICATION_ADD,
      title: 'Account activated',
      data: user,
    })
  })

  socket.on('Shift matched', payload => {
    const job = JSON.parse(payload)

    dispatch({
      type: JOB_MATCHED,
      job,
    })

    dispatch({
      type: NOTIFICATION_ADD,
      title: 'Shift matched',
      data: job,
    })
  })

  socket.on('Shift completed', payload => {
    const job = JSON.parse(payload)

    dispatch({
      type: STOP_JOB,
      job,
    })

    dispatch({
      type: NOTIFICATION_ADD,
      title: 'Shift finished',
      data: job,
    })
  })
}
