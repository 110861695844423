import axios from 'axios'

//////////////////////////////
// Action Types
//////////////////////////////
export const BILLING_CHANGE_REQUESTED = 'billing/CHANGE_REQUESTED'
export const BILLING_CHANGE_ERROR = 'billing/CHANGE_ERROR'
export const BILLING_CHANGE = 'billing/CHANGE'
export const BILLING_FETCH_REQUESTED = 'billing/FETCH_REQUESTED'
export const BILLING_FETCH_ERROR = 'billing/FETCH_ERROR'
export const BILLING_FETCH = 'billing/FETCH'

//////////////////////////////
// Reducer
//////////////////////////////
const initialState = {
  isFetching: false,
  billing: {},
  error: null,
}
export default (state = initialState, action) => {
  switch (action.type) {
    case BILLING_CHANGE_REQUESTED:
    case BILLING_FETCH_REQUESTED:
      return {
        ...state,
        isFetching: true,
      }
    case BILLING_CHANGE_ERROR:
    case BILLING_FETCH_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      }
    case BILLING_CHANGE:
    case BILLING_FETCH:
      return {
        ...state,
        isFetching: false,
        error: false,
        billing: action.billing,
      }
    default:
      return state
  }
}

//////////////////////////////
// Action Creators
//////////////////////////////

export const changeBilling = token => async dispatch => {
  dispatch({
    type: BILLING_CHANGE_REQUESTED,
  })
  try {
    const result = await axios.post('/api/billings', token)
    dispatch({
      type: BILLING_CHANGE,
      billing: result.data,
    })
  } catch (error) {
    dispatch({
      type: BILLING_CHANGE_ERROR,
      error: error.response.data.message,
    })
  }
}

export const fetchBilling = token => async dispatch => {
  dispatch({
    type: BILLING_FETCH_REQUESTED,
  })
  try {
    const result = await axios.get('/api/billings')
    dispatch({
      type: BILLING_FETCH,
      billing: result.data,
    })
  } catch (error) {
    dispatch({
      type: BILLING_FETCH_ERROR,
      error: error.response.data.message,
    })
  }
}
