import React, { Component, Fragment } from 'react'
import {
  withStyles,
  Button,
  Paper,
  Typography,
  Grid,
  CircularProgress,
} from '@material-ui/core'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import ErrorIcon from '@material-ui/icons/Error'

const styles = {
  root: {
    margin: 16,
  },
  paper: {
    maxWidth: 1024,
    padding: 32,
  },
  title: {
    marginBottom: 16,
  },
  description: {
    marginBottom: 32,
  },
  textField: {
    minHeight: '5rem',
  },
  errorIcon: {
    verticalAlign: 'middle',
    margin: 4,
  },
}

class ForgotPassword extends Component {
  state = {
    email: '',
  }

  handleChange = e => {
    const value = e.target.value
    const name = e.target.name

    this.setState({
      [name]: value,
    })
  }

  handleSubmit = e => {
    this.props.onSubmit(this.state.email)
    e.preventDefault()
  }

  handleEnter = e => {
    if (e.key === 'Enter') {
      this.handleSubmit(e)
      e.preventDefault()
    }
  }

  render = () => {
    const { classes, message, error, isFetching } = this.props
    const { email } = this.state

    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Typography className={classes.title} variant="h5">
            Forgot your password?
          </Typography>
          <Grid container>
            <Grid item xs={12} sm={8}>
              <Typography className={classes.description} variant="body1">
                No worries, it happens. Just give us the email associated with
                your account and we'll send you an email to reset your password.
              </Typography>
            </Grid>
          </Grid>
          <ValidatorForm
            id="forgot-password-form"
            onSubmit={this.handleSubmit}
            instantValidate
          >
            <Grid container>
              <Grid item xs={12} sm={8} lg={6}>
                <TextValidator
                  id="email"
                  name="email"
                  label="Email"
                  type="email"
                  value={email}
                  onChange={this.handleChange}
                  validators={['required', 'isEmail']}
                  errorMessages={['Required', 'Invalid email format']}
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </Grid>

            {error && (
              <Typography
                className={classes.errorMessage}
                variant="body1"
                color="error"
              >
                <ErrorIcon className={classes.errorIcon} />
                {error}
              </Typography>
            )}

            {message ? (
              <Fragment>
                <Typography color="primary" variant="h6">
                  {message}
                </Typography>
                <Typography color="primary" variant="body2">
                  Check your email inbox, we have sent you an email with a link
                  to reset your password.
                </Typography>
              </Fragment>
            ) : isFetching ? (
              <CircularProgress />
            ) : (
              <Button
                type="submit"
                className={classes.button}
                variant="contained"
                color="primary"
              >
                Submit
              </Button>
            )}
          </ValidatorForm>
        </Paper>
      </div>
    )
  }
}

export default withStyles(styles)(ForgotPassword)
