import axios from 'axios'
import { login } from './authentication'
import { push } from 'react-router-redux'

//////////////////////////////
// Action Types
//////////////////////////////
export const EMPLOYER_REGISTER_REQUESTED = 'employer/REGISTER_REQUESTED'
export const EMPLOYER_REGISTER_ERROR = 'employer/REGISTER_ERROR'
export const EMPLOYER_REGISTER = 'employer/REGISTER'

//////////////////////////////
// Reducer
//////////////////////////////
const initialState = {
  isFetching: false,
  employer: {},
  error: null,
}
export default (state = initialState, action) => {
  switch (action.type) {
    case EMPLOYER_REGISTER_REQUESTED:
      return {
        ...state,
        isFetching: true,
      }
    case EMPLOYER_REGISTER_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      }
    case EMPLOYER_REGISTER:
      return {
        ...state,
        isFetching: false,
        error: null,
        employer: action.employer,
      }
    default:
      return state
  }
}

//////////////////////////////
// Action Creators
//////////////////////////////

export const registerEmployer = employer => async dispatch => {
  dispatch({
    type: EMPLOYER_REGISTER_REQUESTED,
  })
  try {
    const result = await axios.post('/api/employers/register', employer)
    dispatch({
      type: EMPLOYER_REGISTER,
      employer: result.data,
    })
    dispatch(login(employer.email, employer.password))
    dispatch(push('/bookings'))
  } catch (error) {
    dispatch({
      type: EMPLOYER_REGISTER_ERROR,
      error: error.response.data.message,
    })
  }
}
