import React, { Component } from 'react'
import {
  withStyles,
  withMobileDialog,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
} from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { InlineDatePicker, TimePicker } from 'material-ui-pickers'
import moment from 'moment'

const styles = {
  root: {
    margin: 32,
  },
  description: {
    marginBottom: 16,
  },
  textField: {
    marginTop: 8,
    marginBottom: 8,
  },
  warningIcon: {
    margin: 8,
  },
}

const initialState = () => ({
  timeMoment: null,
  validTime: true,
  overMaxLength: false,
})

class JobStartDialog extends Component {
  state = initialState()

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.job && nextProps.job !== prevState.prevJob) {
      const { actualStartTime, bookedStartTime } = nextProps.job
      const timeMoment = actualStartTime
        ? moment(actualStartTime)
        : moment(bookedStartTime)
      return {
        timeMoment,
        overMaxLength:
          nextProps.job.actualEndTime &&
          timeMoment.isBefore(
            moment(nextProps.job.actualEndTime).subtract(15, 'hours')
          ),
        prevJob: nextProps.job,
      }
    }
    return prevState
  }

  handleDateChange = date => {
    this.setState({
      timeMoment: date,
      validTime: moment().isAfter(date),
      overMaxLength:
        this.props.job.actualEndTime &&
        date.isBefore(
          moment(this.props.job.actualEndTime).subtract(15, 'hours')
        ),
    })
  }

  handleSubmit = e => {
    const time = this.state.timeMoment.toDate()
    this.props.onSubmit(this.props.job, time)
    this.props.onClose()
    this.setState(initialState())
    e.preventDefault()
  }

  render = () => {
    const { classes, fullScreen, open, job, onClose } = this.props
    const { timeMoment, validTime, overMaxLength } = this.state

    return (
      <div className={classes.root}>
        <Dialog
          open={open}
          onClose={onClose}
          fullScreen={fullScreen}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title" onClose={onClose}>
            Start Shift
          </DialogTitle>
          <DialogContent>
            <Typography variant="subtitle1" className={classes.description}>
              Enter actual start time of shift
            </Typography>
            <ValidatorForm
              id="start-job-form"
              onSubmit={this.handleSubmit}
              instantValidate
            >
              <Grid container spacing={8}>
                <Grid item xs>
                  <InlineDatePicker
                    id="startDate"
                    name="startDate"
                    label="Start Date"
                    value={timeMoment}
                    onChange={this.handleDateChange}
                    className={classes.textField}
                    variant="outlined"
                    fullWidth
                    disableFuture
                  />
                </Grid>
                <Grid item xs>
                  <TimePicker
                    id="startTime"
                    name="startTime"
                    label="Start Time"
                    value={timeMoment}
                    onChange={this.handleDateChange}
                    className={classes.textField}
                    variant="outlined"
                    fullWidth
                    error={!validTime || overMaxLength}
                    helperText={
                      !validTime
                        ? 'Cannot start a shift in the future'
                        : overMaxLength &&
                          'Shift cannot be over 15 hours, contact support if this is not an accident'
                    }
                  />
                </Grid>
              </Grid>
              {timeMoment &&
                !timeMoment.isBetween(
                  moment(job.bookedStartTime).subtract(31, 'minutes'),
                  moment(job.bookedStartTime).add(31, 'minutes')
                ) && (
                  <Grid container alignItems="center">
                    <Grid item>
                      <WarningIcon
                        color="error"
                        className={classes.warningIcon}
                      />
                    </Grid>
                    <Grid item xs>
                      <Typography variant="body1" color="error">
                        Warning: This start time differs by more than 30 mins
                        from the booked start time. Please double-check the
                        entered time, especially AM/PM.
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              {job.actualEndTime && validTime && (
                <Typography variant="subtitle2" className={classes.shiftLength}>
                  {`Shift length would be: ${moment(job.actualEndTime)
                    .diff(timeMoment, 'hours', true)
                    .toFixed(2)} hours`}
                </Typography>
              )}
            </ValidatorForm>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="primary">
              Cancel
            </Button>
            <Button
              type="submit"
              form="start-job-form"
              disabled={!validTime || overMaxLength}
              color="primary"
            >
              Start
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default withMobileDialog()(withStyles(styles)(JobStartDialog))
