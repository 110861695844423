import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'

import socketio from './socketio'
import authentication from './authentication'
import notification from './notification'
import billing from './billing'
import business from './business'
import employee from './employee'
import employer from './employer'
import jobProfile from './job-profile'
import jobType from './job-type'
import job from './job'
import maps from './maps'
import offer from './offer'
import softSkill from './soft-skill'

export default combineReducers({
  routing: routerReducer,
  socketio,
  authentication,
  notification,
  billing,
  business,
  employee,
  employer,
  jobProfile,
  jobType,
  job,
  maps,
  offer,
  softSkill,
})
