import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import {
  fetchEmployees,
  fetchApplicants,
  updateEmployee,
} from '../reducers/employee'

import Contractors from './Contractors'
import Applicants from './Applicants'

class ContractorsPage extends Component {
  componentDidMount = () => {
    this.props.fetchApplicants()
  }

  handleSelectContractor = contractor => {
    this.props.history.push('/contractor-details/' + contractor.id)
  }

  handleToggleActivated = contractor => {
    this.props.updateEmployee(contractor.id, {
      user: { activated: !contractor.user.activated },
    })
  }

  render = () => {
    const { applicants, employees, employeeTotal, fetchEmployees } = this.props
    return (
      <Fragment>
        <Applicants
          applicants={applicants}
          onSelect={this.handleSelectContractor}
          onToggleActivated={this.handleToggleActivated}
        />
        <Contractors
          contractors={employees}
          contractorTotal={employeeTotal}
          fetchContractors={fetchEmployees}
          onSelect={this.handleSelectContractor}
          onToggleActivated={this.handleToggleActivated}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = ({ employee: { applicants, employees, total } }) => ({
  applicants,
  employees,
  employeeTotal: total,
})

const mapDispatchToProps = {
  fetchEmployees,
  fetchApplicants,
  updateEmployee,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ContractorsPage))
