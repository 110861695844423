import axios from 'axios'
import { push } from 'react-router-redux'
import Jimp from 'jimp'

import { resizeImageFile } from '../util/images'

//////////////////////////////
// Action Types
//////////////////////////////
export const EMPLOYEE_FETCH_ALL_REQUESTED = 'employee/FETCH_ALL_REQUESTED'
export const EMPLOYEE_FETCH_ALL_ERROR = 'employee/FETCH_ALL_ERROR'
export const EMPLOYEE_FETCH_ALL = 'employee/FETCH_ALL'
export const EMPLOYEE_FETCH_APPLICANTS_REQUESTED =
  'employee/FETCH_APPLICANTS_REQUESTED'
export const EMPLOYEE_FETCH_APPLICANTS_ERROR = 'employee/FETCH_APPLICANTS_ERROR'
export const EMPLOYEE_FETCH_APPLICANTS = 'employee/FETCH_APPLICANTS'
export const EMPLOYEE_FETCH_REQUESTED = 'employee/FETCH_REQUESTED'
export const EMPLOYEE_FETCH_ERROR = 'employee/FETCH_ERROR'
export const EMPLOYEE_FETCH = 'employee/FETCH'
export const EMPLOYEE_FETCH_PENDING_OFFERS_REQUESTED =
  'employee/FETCH_PENDING_OFFERS_REQUESTED'
export const EMPLOYEE_FETCH_PENDING_OFFERS_ERROR =
  'employee/FETCH_PENDING_OFFERS_ERROR'
export const EMPLOYEE_FETCH_PENDING_OFFERS = 'employee/FETCH_PENDING_OFFERS'
export const EMPLOYEE_FETCH_UPCOMING_JOBS_REQUESTED =
  'employee/FETCH_UPCOMING_JOBS_REQUESTED'
export const EMPLOYEE_FETCH_UPCOMING_JOBS_ERROR =
  'employee/FETCH_UPCOMING_JOBS_ERROR'
export const EMPLOYEE_FETCH_UPCOMING_JOBS = 'employee/FETCH_UPCOMING_JOBS'
export const EMPLOYEE_FETCH_COMPLETED_JOBS_REQUESTED =
  'employee/FETCH_COMPLETED_JOBS_REQUESTED'
export const EMPLOYEE_FETCH_COMPLETED_JOBS_ERROR =
  'employee/FETCH_COMPLETED_JOBS_ERROR'
export const EMPLOYEE_FETCH_COMPLETED_JOBS = 'employee/FETCH_COMPLETED_JOBS'
export const EMPLOYEE_UPDATE_REQUESTED = 'employee/UPDATE_REQUESTED'
export const EMPLOYEE_UPDATE_ERROR = 'employee/UPDATE_ERROR'
export const EMPLOYEE_UPDATE = 'employee/UPDATE'
export const EMPLOYEE_REGISTER_REQUESTED = 'employee/REGISTER_REQUESTED'
export const EMPLOYEE_REGISTER_ERROR = 'employee/REGISTER_ERROR'
export const EMPLOYEE_REGISTER = 'employee/REGISTER'
export const EMPLOYEE_SELECT = 'employee/SELECT'

//////////////////////////////
// Reducer
//////////////////////////////
const initialState = {
  isFetching: false,
  isFetchingDetails: false,
  isFetchingApplicants: false,
  employees: [],
  applicants: [],
  employee: null,
  total: 0,
  error: null,
  pendingOffers: [],
  upcomingJobs: [],
  completedJobs: [],
}
export default (state = initialState, action) => {
  switch (action.type) {
    case EMPLOYEE_FETCH_ALL_REQUESTED:
      return {
        ...state,
        isFetching: true,
        total: 0,
        employees: action.concat ? state.employees : [],
      }
    case EMPLOYEE_FETCH_APPLICANTS_REQUESTED:
      return {
        ...state,
        isFetchingApplicants: true,
        applicants: [],
      }
    case EMPLOYEE_FETCH_REQUESTED:
    case EMPLOYEE_REGISTER_REQUESTED:
      return {
        ...state,
        isFetching: true,
        employee: null,
        error: null,
      }
    case EMPLOYEE_UPDATE_REQUESTED:
      return {
        ...state,
        isFetching: true,
        error: null,
      }
    case EMPLOYEE_FETCH_PENDING_OFFERS_REQUESTED:
      return {
        ...state,
        isFetchingDetails: true,
        pendingOffers: [],
      }
    case EMPLOYEE_FETCH_UPCOMING_JOBS_REQUESTED:
      return {
        ...state,
        isFetchingDetails: true,
        upcomingJobs: [],
      }
    case EMPLOYEE_FETCH_COMPLETED_JOBS_REQUESTED:
      return {
        ...state,
        isFetchingDetails: true,
        completedJobs: [],
      }
    case EMPLOYEE_FETCH_ALL_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
        total: 0,
      }
    case EMPLOYEE_FETCH_APPLICANTS_ERROR:
      return {
        ...state,
        isFetchingApplicants: false,
        error: action.error,
      }
    case EMPLOYEE_FETCH_ERROR:
    case EMPLOYEE_REGISTER_ERROR:
    case EMPLOYEE_UPDATE_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      }
    case EMPLOYEE_FETCH_PENDING_OFFERS_ERROR:
    case EMPLOYEE_FETCH_UPCOMING_JOBS_ERROR:
    case EMPLOYEE_FETCH_COMPLETED_JOBS_ERROR:
      return {
        ...state,
        isFetchingDetails: false,
        error: action.error,
      }
    case EMPLOYEE_REGISTER:
      return {
        ...state,
        isFetching: false,
        error: null,
        employees: [...state.employees, action.employee],
      }
    case EMPLOYEE_FETCH_ALL:
      return {
        ...state,
        isFetching: false,
        error: null,
        employees: action.concat
          ? [...state.employees, ...action.employees]
          : action.employees,
        total: action.total,
      }
    case EMPLOYEE_FETCH_APPLICANTS:
      return {
        ...state,
        isFetchingApplicants: false,
        error: null,
        applicants: action.applicants,
      }
    case EMPLOYEE_FETCH:
    case EMPLOYEE_UPDATE:
      return {
        ...state,
        isFetching: false,
        error: null,
        employee: action.employee,
        employees: state.employees.map(employee =>
          employee.id === action.employee.id ? action.employee : employee
        ),
      }
    case EMPLOYEE_FETCH_PENDING_OFFERS:
      return {
        ...state,
        isFetchingDetails: false,
        error: null,
        pendingOffers: action.offers,
      }
    case EMPLOYEE_FETCH_UPCOMING_JOBS:
      return {
        ...state,
        isFetchingDetails: false,
        error: null,
        upcomingJobs: action.jobs,
      }
    case EMPLOYEE_FETCH_COMPLETED_JOBS:
      return {
        ...state,
        isFetchingDetails: false,
        error: null,
        completedJobs: action.jobs,
      }
    case EMPLOYEE_SELECT:
      return {
        ...state,
        employee: action.employee,
      }
    default:
      return state
  }
}

//////////////////////////////
// Action Creators
//////////////////////////////

export const fetchEmployees = ({
  limit,
  offset,
  sort,
  search,
  businessId,
  jobTypeId,
  includeStats = false,
  includeInactive = false,
  concat = offset > 0,
}) => async dispatch => {
  dispatch({
    type: EMPLOYEE_FETCH_ALL_REQUESTED,
    concat,
  })
  try {
    const result = await axios.get('/api/employees', {
      params: {
        businessId,
        jobTypeId,
        limit,
        offset,
        sort,
        search,
        includeStats,
        activated: !includeInactive,
      },
    })
    dispatch({
      type: EMPLOYEE_FETCH_ALL,
      employees: result.data,
      total: parseInt(result.headers['x-total-count']),
      concat: offset > 0,
    })
  } catch (error) {
    dispatch({
      type: EMPLOYEE_FETCH_ALL_ERROR,
      error: error.response.data.message,
    })
  }
}

export const fetchApplicants = () => async dispatch => {
  dispatch({
    type: EMPLOYEE_FETCH_APPLICANTS_REQUESTED,
  })
  try {
    const result = await axios.get('/api/employees', {
      params: {
        applicantsOnly: true,
      },
    })
    dispatch({
      type: EMPLOYEE_FETCH_APPLICANTS,
      applicants: result.data,
    })
  } catch (error) {
    dispatch({
      type: EMPLOYEE_FETCH_APPLICANTS_ERROR,
      error: error.response.data.message,
    })
  }
}

export const fetchEmployee = id => async dispatch => {
  dispatch({
    type: EMPLOYEE_FETCH_REQUESTED,
  })
  try {
    const result = await axios.get(`/api/employees/${id}`)
    dispatch({
      type: EMPLOYEE_FETCH,
      employee: result.data,
    })
    return result
  } catch (error) {
    dispatch({
      type: EMPLOYEE_FETCH_ERROR,
      error: error.response.data.message,
    })
  }
}

export const updateEmployee = (id, employee) => async dispatch => {
  dispatch({
    type: EMPLOYEE_UPDATE_REQUESTED,
  })
  try {
    const result = await axios.put(`/api/employees/${id}`, employee)
    dispatch({
      type: EMPLOYEE_UPDATE,
      employee: result.data,
    })
    return result
  } catch (error) {
    dispatch({
      type: EMPLOYEE_UPDATE_ERROR,
      error: error.response.data.message,
    })
  }
}

export const updateIdentificationPhoto = (
  employee,
  photo
) => async dispatch => {
  dispatch({
    type: EMPLOYEE_UPDATE_REQUESTED,
  })
  try {
    const resizedPhotoBlob = await resizeImageFile(photo, 1500, 1500)

    const filename = `${employee.user.name}.jpeg`
    let data = new FormData()
    data.append('photo', resizedPhotoBlob, filename)
    const result = await axios.post(
      `/api/employees/${employee.id}/identification-photo`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    employee = { ...employee, identificationPhoto: result.data }

    dispatch({
      type: EMPLOYEE_UPDATE,
      employee,
    })

    return employee.identificationPhoto
  } catch (error) {
    dispatch({
      type: EMPLOYEE_UPDATE_ERROR,
      error: error.response.data.message,
    })
  }
}

export const updateResume = (employee, resumeFile) => async dispatch => {
  dispatch({
    type: EMPLOYEE_UPDATE_REQUESTED,
  })
  try {
    let data = new FormData()
    data.append('resume', resumeFile)
    const result = await axios.put(
      `/api/employees/${employee.id}/resume`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    employee = { ...employee, resume: result.data }

    dispatch({
      type: EMPLOYEE_UPDATE,
      employee,
    })

    return employee.resume
  } catch (error) {
    dispatch({
      type: EMPLOYEE_UPDATE_ERROR,
      error: error.response.data.message,
    })
  }
}

export const register = (employee, photo) => async dispatch => {
  dispatch({
    type: EMPLOYEE_REGISTER_REQUESTED,
  })
  try {
    const employeeResult = await axios.post('/api/employees/register', employee)

    employee = employeeResult.data.response.employee

    const { x, y, width, height } = photo.pixelCrop
    const image = await Jimp.read(photo.src)
    const newImage = image
      .crop(x, y, width, height)
      .resize(900, 900)
      .quality(70)
    const b64 = await newImage.getBase64Async(Jimp.AUTO)
    const blob = await (await fetch(b64)).blob()

    // Upload photo
    const filename = `${employee.user.name}.${newImage.getExtension()}`
    let data = new FormData()
    data.append('photo', blob, filename)
    const photoResult = await axios.post(
      `/api/employees/${employee.id}/photo`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${employeeResult.data.response.token}`,
        },
      }
    )

    employee = { ...employee, photo: photoResult.data }
    dispatch({
      type: EMPLOYEE_REGISTER,
      employee,
    })

    dispatch(push('/get-app'))
  } catch (error) {
    dispatch({
      type: EMPLOYEE_REGISTER_ERROR,
      error: error && error.response.data ? error.response.data.message : error,
    })
  }
}

/**
 * This should only be used by an admin user
 */
export const selectEmployee = employee => async dispatch => {
  dispatch({
    type: EMPLOYEE_SELECT,
    employee,
  })
}

/**
 * This should only be used by an admin user
 */
export const fetchEmployeeHistory = (
  employeeId,
  businessId
) => async dispatch => {
  dispatch(fetchEmployeePendingOffers(employeeId))
  dispatch(fetchEmployeeUpcomingJobs(employeeId))
  dispatch(fetchEmployeeCompletedJobs(employeeId, businessId, 5))
}

/**
 * This should only be used by an admin user
 */
export const fetchEmployeePendingOffers = employeeId => async dispatch => {
  dispatch({
    type: EMPLOYEE_FETCH_PENDING_OFFERS,
  })
  try {
    const result = await axios.get('/api/offers/pending', {
      params: {
        employeeId,
      },
    })
    dispatch({
      type: EMPLOYEE_FETCH_PENDING_OFFERS,
      offers: result.data,
    })
  } catch (error) {
    dispatch({
      type: EMPLOYEE_FETCH_PENDING_OFFERS_ERROR,
      error: error.response.data.message,
    })
  }
}

/**
 * This should only be used by an admin user
 */
export const fetchEmployeeUpcomingJobs = employeeId => async dispatch => {
  dispatch({
    type: EMPLOYEE_FETCH_UPCOMING_JOBS_REQUESTED,
  })
  try {
    const result = await axios.get('/api/jobs/matched', {
      params: {
        employeeId,
      },
    })
    dispatch({
      type: EMPLOYEE_FETCH_UPCOMING_JOBS,
      jobs: result.data,
    })
  } catch (error) {
    dispatch({
      type: EMPLOYEE_FETCH_UPCOMING_JOBS_ERROR,
      error: error.response.data.message,
    })
  }
}

/**
 * This should only be used by an admin user
 */
export const fetchEmployeeCompletedJobs = (
  employeeId,
  businessId,
  limit = 5
) => async dispatch => {
  dispatch({
    type: EMPLOYEE_FETCH_COMPLETED_JOBS_REQUESTED,
  })
  try {
    const result = await axios.get('/api/jobs/completed', {
      params: {
        employeeId,
        businessId,
        limit,
      },
    })
    dispatch({
      type: EMPLOYEE_FETCH_COMPLETED_JOBS,
      jobs: result.data,
    })
  } catch (error) {
    dispatch({
      type: EMPLOYEE_FETCH_COMPLETED_JOBS_ERROR,
      error: error.response.data.message,
    })
  }
}
