import React from 'react'
import { withStyles, Grid, Typography } from '@material-ui/core'

const styles = {
  root: {},
  item: {
    minWidth: 40,
  },
  icon: {
    verticalAlign: 'top',
    fontSize: 18,
  },
}

const SoftSkillStats = ({ classes, contractor, softSkills }) => {
  const contractorSkills = softSkills.map(({ key, name, icon }) => {
    const contractorSkill = contractor.softSkills.find(
      softSkill => softSkill.key === key
    )
    return {
      key,
      name,
      icon,
      skill: contractorSkill ? contractorSkill.employee_softSkill.skill : '--',
    }
  })

  return (
    <Grid className={classes.root} container>
      {contractorSkills.map(({ key, name, icon, skill }) => (
        <div className={classes.item} key={key}>
          <Typography color="textSecondary" noWrap>
            <span className={classes.icon}>{icon}</span>
            {skill}
          </Typography>
        </div>
      ))}
    </Grid>
  )
}

export default withStyles(styles)(SoftSkillStats)
