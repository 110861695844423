import React, { Component, Fragment } from 'react'

import Register from './Register'

import { connect } from 'react-redux'
import { registerEmployer } from '../reducers/employer'
import { geolocate } from '../reducers/maps'

class RegisterPage extends Component {
  componentDidMount = () => {
    this.props.geolocate()
  }

  render = () => {
    const { location, registerEmployer, error } = this.props
    return (
      <Fragment>
        <Register location={location} submit={registerEmployer} error={error} />
      </Fragment>
    )
  }
}

const mapStateToProps = ({ employer: { error }, maps: { location } }) => ({
  error,
  location,
})

const mapDispatchToProps = {
  registerEmployer,
  geolocate,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterPage)
