import React from 'react'
import { connect } from 'react-redux'

import { connectSocket } from '../reducers/socketio'

class SocketConnector extends React.Component {
  componentDidMount = () => {
    this.props.connectSocket()
  }

  render = () => null
}

const mapDispatchToProps = {
  connectSocket,
}

export default connect(
  null,
  mapDispatchToProps
)(SocketConnector)
