import React, { Component, Fragment } from 'react'
import {
  withStyles,
  Badge,
  IconButton,
  Menu,
  MenuItem,
  Avatar,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core'
import NotificationsIcon from '@material-ui/icons/Notifications'
import DoneIcon from '@material-ui/icons/Done'

const styles = {
  menuItem: {
    paddingTop: 16,
    paddingBottom: 16,
  },
  menuItemText: {
    marginRight: 32,
  },
  hidden: {
    display: 'none',
  },
}

class NotificationMenu extends Component {
  state = {
    anchorEl: null,
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  handleClickItem = request => {
    // this.props.download(request.id)
    this.handleClose()
  }

  render = () => {
    const { classes, notifications } = this.props
    const { anchorEl } = this.state

    return (
      notifications.length > 0 && (
        <Fragment>
          <IconButton
            color="inherit"
            aria-label="Notifications"
            aria-owns={anchorEl ? 'notification-menu' : undefined}
            aria-haspopup="true"
            onClick={this.handleClick}
          >
            <Badge
              color="secondary"
              badgeContent={notifications.length}
              classes={{
                badge: notifications.length < 1 && classes.hidden,
              }}
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <Menu
            id="notification-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
          >
            {notifications.map(notification => (
              <MenuItem
                key={notification.data.id}
                className={classes.menuItem}
                onClick={() => this.handleClickItem(notification.data)}
              >
                <ListItemIcon>
                  <Avatar>
                    <DoneIcon />
                  </Avatar>
                </ListItemIcon>
                <ListItemText
                  className={classes.menuItemText}
                  primary={`${notification.title}`}
                />
              </MenuItem>
            ))}
          </Menu>
        </Fragment>
      )
    )
  }
}

export default withStyles(styles)(NotificationMenu)
