import React, { Fragment, Component } from 'react'
import { CssBaseline } from '@material-ui/core'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from 'material-ui-pickers'
// pick utils
import MomentUtils from '@date-io/moment'
// import DateFnsUtils from '@date-io/date-fns'
// import LuxonUtils from '@date-io/luxon'

import { connect } from 'react-redux'
import { getUserSession } from '../reducers/authentication'

import theme from '../config/theme'
import SocketConnector from './SocketConnector'
import AppLayout from './AppLayout'
import Routes from './Routes'

class App extends Component {
  componentDidMount = () => {
    this.props.getUserSession()
  }

  render = () => (
    <Fragment>
      <SocketConnector />
      <CssBaseline />
      <MuiThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <AppLayout>
            <Routes />
          </AppLayout>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </Fragment>
  )
}

const mapStateToProps = () => ({})

const mapDispatchToProps = {
  getUserSession,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { pure: false }
)(App)
