import React from 'react'
import {
  AppBar,
  Dialog,
  IconButton,
  Toolbar,
  Typography,
  withMobileDialog,
  withStyles,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import ContractorSelect from './ContractorSelect'

const styles = {
  titleBar: {
    padding: 0,
  },
  appBar: {},
  closeButton: {
    marginLeft: -12,
    marginRight: 20,
  },
}

const ContractorSelectDialog = ({
  classes,
  open,
  fullScreen,
  job,
  region,
  employees,
  employeeTotal,
  selectedEmployee,
  fetchEmployees,
  isFetching,
  onClose,
  onSelect,
  onSubmit,
}) => {
  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      aria-labelledby="contractor-dialog"
    >
      <AppBar className={classes.appBar} position="sticky">
        <Toolbar>
          <IconButton
            className={classes.closeButton}
            color="inherit"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" color="inherit">
            Select Contractor
          </Typography>
        </Toolbar>
      </AppBar>
      <ContractorSelect
        job={job}
        region={region}
        employees={employees}
        employeeTotal={employeeTotal}
        selectedEmployee={selectedEmployee}
        fetchEmployees={fetchEmployees}
        isFetching={isFetching}
        onSelect={onSelect}
        onSubmit={onSubmit}
      />
    </Dialog>
  )
}

export default withMobileDialog()(withStyles(styles)(ContractorSelectDialog))
