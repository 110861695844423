import React from 'react'
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Switch,
  Typography,
  withStyles,
} from '@material-ui/core'

import { steps } from '../util/onboarding'

const styles = {
  paper: {
    margin: 16,
    maxWidth: 800,
  },
  header: {
    padding: 16,
  },
  description: {
    padding: 16,
  },
}

const getStepText = contractor => {
  const currentStep = steps.findIndex(step => !contractor[step.key])
  return `${currentStep + 1}/${steps.length} \u00b7 ${steps[currentStep].name}`
}

const Applicants = ({ classes, applicants, onSelect, onToggleActivated }) => (
  <Paper className={classes.paper}>
    <Typography className={classes.header} variant="h5">
      Applicants
    </Typography>
    {applicants.length === 0 ? (
      <Typography className={classes.description}>Ain't none.</Typography>
    ) : (
      <List className={classes.list}>
        {applicants
          .sort((a, b) => getStepText(a).localeCompare(getStepText(b)))
          .map(contractor => (
            <div key={contractor.id}>
              <ListItem
                key={contractor.id}
                button
                onClick={() => onSelect(contractor)}
              >
                <ListItemAvatar>
                  <Avatar
                    alt={contractor.user.nickname}
                    src={contractor.photo}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={`${contractor.user.name} (${
                    contractor.user.nickname
                  })`}
                  secondary={getStepText(contractor)}
                />
                <ListItemSecondaryAction>
                  <Switch
                    checked={contractor.user.activated}
                    onChange={() => onToggleActivated(contractor)}
                    value={Boolean(contractor.user.activated)}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            </div>
          ))}
      </List>
    )}
  </Paper>
)

export default withStyles(styles)(Applicants)
