import React, { Component } from 'react'

import { withStyles } from '@material-ui/core'

import { withRouter } from 'react-router-dom'

import AppBar from './AppBar'
import AppDrawer from './AppDrawer'
import { getRouteName } from './Routes'

import { connect } from 'react-redux'
import { logout } from '../reducers/authentication'

const styles = theme => ({
  root: {
    display: 'flex',
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
  },
})

class AppLayout extends Component {
  state = {
    drawerOpenMobile: false,
  }

  handleDrawerToggle = () => {
    this.setState(state => ({ drawerOpenMobile: !state.drawerOpenMobile }))
  }

  handleBack = () => this.props.history.goBack()

  render = () => {
    const {
      children,
      classes,
      isAuthenticated,
      isAdmin,
      isContractor,
      notifications,
      currentPage,
      logout,
    } = this.props
    const { drawerOpenMobile } = this.state

    return (
      <div className={classes.root}>
        <AppBar
          title={currentPage}
          onMenuClick={this.handleDrawerToggle}
          onBack={this.handleBack}
          isAuthenticated={isAuthenticated}
          currentPage={currentPage}
          notifications={notifications}
          logout={logout}
        />
        <AppDrawer
          open={drawerOpenMobile}
          isAuthenticated={isAuthenticated}
          isAdmin={isAdmin}
          isContractor={isContractor}
          currentPage={currentPage}
          onClose={this.handleDrawerToggle}
        />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {children}
        </main>
      </div>
    )
  }
}

const mapStateToProps = ({
  authentication: { isAuthenticated, isAdmin, isContractor },
  routing: {
    location: { pathname },
  },
  notification: { notifications },
}) => ({
  currentPage: getRouteName(pathname),
  isAuthenticated,
  isAdmin,
  isContractor,
  notifications,
})

const mapDispatchToProps = {
  logout,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(AppLayout)))
