import React, { Component, Fragment } from 'react'
import {
  withStyles,
  withMobileDialog,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  MenuItem,
  Paper,
  Typography,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Grid,
  IconButton,
} from '@material-ui/core'
import AssignmentIcon from '@material-ui/icons/Assignment'
import DeleteIcon from '@material-ui/icons/Delete'
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from 'react-material-ui-form-validator'
import PlacesAutocomplete from 'react-places-autocomplete'
import CheckboxValidator from './CheckboxValidator'
import poweredByGoogle from '../images/powered_by_google_on_white.png'
import classNames from 'classnames'

const style = theme => ({
  textField: {
    marginTop: 8,
    marginBottom: 8,
  },
  subheader: {
    fontSize: '0.875rem',
    fontWeight: theme.typography.fontWeightMedium,
    opacity: 1,
    marginBottom: -16,
  },
  noSelected: {
    backgroundColor: 'transparent !important',
  },
  jobTypeItem: {
    height: 32,
  },
  detailsArea: {},
  detailsRow: {
    margin: 8,
  },
  detailsHeader: {
    fontWeight: 500,
    display: 'inline',
    marginTop: 8,
    marginRight: 8,
  },
  details: {
    whiteSpace: 'pre-wrap',
    margin: 8,
  },
  inset: {
    marginLeft: 16,
  },
  inline: {
    display: 'inline',
  },
})

const initialState = {
  id: null,
  name: '',
  customDescription: false,
  description: '',
  dressCode: '',
  jobTypeId: '',
  address: '',
  addressCustom: false,
  selectedAddress: null,
  placeId: null,
}

class JobProfileDialog extends Component {
  state = initialState

  // Need the following to set state from props for edit
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.jobProfile !== prevState.prevJobProfile) {
      if (!nextProps.jobProfile) {
        return { ...initialState, prevJobProfile: nextProps.jobProfile }
      }
      const {
        id,
        name,
        description,
        dressCode,
        jobTypeId,
        addressCustom,
        address,
        addressGPlaceId,
      } = nextProps.jobProfile
      return {
        id,
        name,
        customDescription: description && description.length > 0,
        description,
        dressCode,
        jobTypeId,
        addressCustom,
        address: addressCustom ? address : null,
        placeId: addressCustom ? addressGPlaceId : null,
        selectedAddress: addressCustom ? address : null,
        prevJobProfile: nextProps.jobProfile,
      }
    }
    return prevState
  }

  handleChange = e => {
    const { name, value, type, checked } = e.target

    this.setState({
      [name]: type === 'checkbox' ? checked : value,
    })
  }

  handleAddressChange = address => {
    this.setState({ address, selectedAddress: '', placeId: '' })
  }
  handleAddressSelect = (address, placeId) => {
    this.setState({ address, selectedAddress: address, placeId })
  }

  handleSubmit = e => {
    const {
      id,
      name,
      customDescription,
      description,
      dressCode,
      jobTypeId,
      addressCustom,
      selectedAddress,
      placeId,
    } = this.state
    const jobProfile = {
      id,
      name,
      description: customDescription ? description : '',
      dressCode,
      jobTypeId,
      addressCustom,
      address: addressCustom && selectedAddress ? selectedAddress : null,
      addressGPlaceId: addressCustom && placeId ? placeId : null,
    }
    this.props.onSubmit(jobProfile)
    this.props.onClose()
    this.setState(initialState)
    e.preventDefault()
  }

  componentDidMount = () => {
    ValidatorForm.addValidationRule(
      'isNameUnique',
      value =>
        this.props.edit ||
        !this.props.jobProfiles.some(jobProfile => jobProfile.name === value)
    )
    ValidatorForm.addValidationRule(
      'isSelectedFromList',
      value => this.state.selectedAddress === value
    )
  }

  render = () => {
    const {
      classes,
      fullScreen,
      jobProfile,
      jobTypes,
      location,
      open,
      edit,
      businessAddress,
      onClose,
      onDelete,
    } = this.props
    const {
      name,
      customDescription,
      description,
      dressCode,
      jobTypeId,
      address,
      addressCustom,
    } = this.state

    // Get default location if exists, otherwise default to somewhere that makes sense
    // TODO: Fallback is hard-coded to Toronto for now (Yonge & Eglinton)
    const lat = location && location.lat ? location.lat : 43.7064477
    const lng = location && location.lng ? location.lng : -79.3998241
    const defaultLocation = new window.google.maps.LatLng(lat, lng)

    const selectedJobType = jobTypeId
      ? jobTypes.find(jobType => jobType.id === jobTypeId)
      : null

    return (
      <Dialog
        open={open}
        onClose={onClose}
        fullScreen={fullScreen}
        fullWidth={!fullScreen}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" onClose={onClose}>
          {edit ? (
            <Grid container justify="space-between">
              <Grid item>Edit Position</Grid>
              <Grid item>
                <IconButton
                  aria-label="Delete"
                  onClick={() => onDelete(jobProfile)}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          ) : (
            'New Position'
          )}
        </DialogTitle>
        <DialogContent>
          <ValidatorForm
            id="job-profile-form"
            onSubmit={this.handleSubmit}
            instantValidate
          >
            <SelectValidator
              id="jobTypeId"
              name="jobTypeId"
              label="Job Type"
              type="text"
              value={jobTypeId}
              onChange={this.handleChange}
              validators={['required']}
              errorMessages={['Required']}
              className={classes.textField}
              variant="outlined"
              fullWidth
              SelectProps={{
                renderValue: value =>
                  jobTypes.find(jobType => jobType.id === value).name,
              }}
              disabled={edit}
            >
              {jobTypes
                .map(jobType => ({ isCategory: false, ...jobType }))
                .sort((a, b) => a.order - b.order) // Sort jobTypes
                .reduce((categories, jobType) => {
                  // Group into categories
                  const jobTypeCategory = jobType.jobCategory || {
                    name: 'General',
                  }
                  const index = categories.findIndex(
                    category => jobTypeCategory.name === category.name
                  )
                  index < 0
                    ? categories.push({
                        ...jobTypeCategory,
                        jobTypes: [jobType],
                      })
                    : categories[index].jobTypes.push(jobType)
                  return categories
                }, [])
                .sort((a, b) => a.order - b.order) // Sort categories
                .reduce(
                  // Flatten for Select
                  (items, category) => [
                    ...items,
                    { isCategory: true, ...category },
                    ...category.jobTypes,
                  ],
                  []
                )
                .map(item => (
                  <MenuItem
                    // className={classes.jobTypeItem}
                    className={classNames(classes.jobTypeItem, {
                      [classes.noSelected]: item.isCategory,
                    })}
                    disabled={item.isCategory}
                    value={item.id}
                    key={item.name}
                    // selected={!item.isCategory && item.id === jobTypeId}
                  >
                    {item.isCategory ? (
                      <div className={classes.subheader}>{item.name}</div>
                    ) : (
                      <Fragment>
                        <ListItemAvatar>
                          <Avatar>
                            <AssignmentIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={item.name}
                          secondary={
                            item.experience && `${item.experience} experience`
                          }
                        />
                        <Typography variant="subtitle1">{`$${
                          item.wage
                        }/hr`}</Typography>
                      </Fragment>
                    )}
                  </MenuItem>
                ))}
            </SelectValidator>
            {selectedJobType && (
              <Fragment>
                <div className={classes.detailsArea}>
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <div className={classes.detailsRow}>
                        <Typography
                          className={classes.detailsHeader}
                          variant="caption"
                        >
                          Wage:
                        </Typography>
                        <Typography
                          className={classes.inline}
                          variant="caption"
                        >
                          {`$${selectedJobType.wage} / hr`}
                        </Typography>
                      </div>
                    </Grid>
                    {selectedJobType.experience && (
                      <Grid item xs={12} sm={6}>
                        <div className={classes.detailsRow}>
                          <Typography
                            className={classes.detailsHeader}
                            variant="caption"
                          >
                            Expected experience:
                          </Typography>
                          <Typography
                            className={classes.inline}
                            variant="caption"
                          >
                            {selectedJobType.experience}
                          </Typography>
                        </div>
                      </Grid>
                    )}
                  </Grid>
                  <div className={classes.detailsRow}>
                    <Typography
                      className={classes.detailsHeader}
                      variant="caption"
                    >
                      Base responsibilities / description:
                    </Typography>
                    <Typography className={classes.details} variant="caption">
                      {selectedJobType.description}
                    </Typography>
                  </div>
                </div>
                <CheckboxValidator
                  id="customDescription"
                  name="customDescription"
                  label="I have other responsiblilities or details about this position than described above"
                  checked={customDescription}
                  value={String(customDescription)}
                  onChange={this.handleChange}
                />
                {customDescription && (
                  <div className={classes.inset}>
                    <TextValidator
                      id="description"
                      name="description"
                      label="Other requirements/details for position"
                      type="text"
                      multiline
                      rows={2}
                      value={description}
                      onChange={this.handleChange}
                      validators={['maxStringLength:255']}
                      errorMessages={['Maximum 255 characters']}
                      helperText={`${description.length} / 255`}
                      className={classes.textField}
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                )}
                <TextValidator
                  id="dressCode"
                  name="dressCode"
                  label="How should the worker be dressed?"
                  type="text"
                  multiline
                  rows={2}
                  value={dressCode}
                  onChange={this.handleChange}
                  validators={['required', 'maxStringLength:255']}
                  errorMessages={['Required', 'Maximum 255 characters']}
                  helperText={`${dressCode.length} / 255`}
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                />
                <CheckboxValidator
                  id="addressCustom"
                  name="addressCustom"
                  label={`Address is different than ${businessAddress}`}
                  checked={addressCustom}
                  value={String(addressCustom)}
                  onChange={this.handleChange}
                  disabled={edit}
                />
                {addressCustom && (
                  <PlacesAutocomplete
                    value={address}
                    onChange={this.handleAddressChange}
                    onSelect={this.handleAddressSelect}
                    searchOptions={{
                      location: defaultLocation,
                      radius: 50000,
                    }}
                    disabled={edit}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div className={classes.inset}>
                        <TextValidator
                          id="somename"
                          name="somename"
                          label="Address"
                          value={address}
                          className={classes.textField}
                          variant="outlined"
                          fullWidth
                          validators={['isSelectedFromList']}
                          errorMessages={[
                            'You must select your address from the list',
                          ]}
                          InputProps={{
                            ...getInputProps(),
                            inputProps: {
                              autoComplete: 'off',
                            },
                            disabled: edit,
                            endAdornment: (
                              <InputAdornment position="end">
                                <img
                                  className={classes.poweredByGoogle}
                                  src={poweredByGoogle}
                                  alt="Powered by Google"
                                />
                              </InputAdornment>
                            ),
                          }}
                          disabled={edit}
                        />
                        <Paper>
                          {loading && <CircularProgress />}
                          {suggestions.map(suggestion => (
                            <MenuItem
                              key={suggestion.index}
                              value={suggestion.description}
                              {...getSuggestionItemProps(suggestion)}
                            >
                              {suggestion.description}
                            </MenuItem>
                          ))}
                        </Paper>
                      </div>
                    )}
                  </PlacesAutocomplete>
                )}
                <TextValidator
                  id="name"
                  name="name"
                  label="Position name (for your use)"
                  helperText='eg. "Dishwasher", "Morning Prep Cook", "Dishwasher at Location #2", etc.'
                  type="text"
                  value={name}
                  onChange={this.handleChange}
                  validators={['required', 'isNameUnique']}
                  errorMessages={[
                    'Required',
                    'Name must be unique, you have a position with this name already',
                  ]}
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                />
              </Fragment>
            )}
          </ValidatorForm>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button type="submit" form="job-profile-form" color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withMobileDialog()(withStyles(style)(JobProfileDialog))
