import React from 'react'
import { withStyles, Typography, Grid } from '@material-ui/core'

import AppStoreButtons from './AppStoreButtons'

import logo from '../images/zing-logo.png'

const styles = {
  root: {
    margin: 16,
  },
  logo: {
    margin: 24,
    width: '100%',
  },
}

const DownloadApp = ({ classes }) => (
  <div className={classes.root}>
    <Typography variant="h6">Download the Zing app</Typography>
    <Typography style={{ marginTop: 8 }}>
      We are on the App Store and Google Play.
    </Typography>
    <Typography>
      Use our app to sign up for Zing, get job offers, accept/decline jobs,
      clock in, clock out, and see job details.
    </Typography>
    <Grid container spacing={12}>
      <Grid item xs={8} sm={6} md={5} lg={4}>
        <img className={classes.logo} src={logo} alt="Zing logo" />
      </Grid>
    </Grid>
    <AppStoreButtons />
  </div>
)

export default withStyles(styles)(DownloadApp)
