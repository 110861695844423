import React from 'react'
import { connect } from 'react-redux'

import Login from './Login'
import { login } from '../reducers/authentication'

const LoginPage = ({ login, location, isAuthenticated, error }) =>
  location && location.state && location.state.from ? (
    <Login
      login={login}
      isAuthenticated={isAuthenticated}
      error={error}
      from={location.state.from}
    />
  ) : (
    <Login login={login} isAuthenticated={isAuthenticated} error={error} />
  )

const mapStateToProps = ({ authentication: { isAuthenticated, error } }) => ({
  isAuthenticated,
  error,
})

const mapDispatchToProps = {
  login,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginPage)
