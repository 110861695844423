import React, { Fragment } from 'react'
import {
  withStyles,
  Paper,
  List,
  ListItem,
  ListItemText,
  Typography,
  ListItemSecondaryAction,
  ListItemAvatar,
  Avatar,
  Grid,
  CircularProgress,
} from '@material-ui/core'
import moment from 'moment'
import InfiniteScroll from 'react-infinite-scroller'

export const PAGE_SIZE = 10

const styles = theme => ({
  root: {
    flexGrow: 1,
    margin: 16,
  },
  paper: {
    maxWidth: 900,
  },
  title: {
    padding: 16,
  },
  description: {
    padding: 16,
  },
  listItemText: {
    marginRight: 24,
  },
  secondaryText: {
    padding: 12,
  },
  inline: {
    display: 'inline',
  },
})

const JobsCompleted = ({
  classes,
  jobs,
  showBusiness = false,
  onSelect,
  onNextPage,
  hasNextPage,
}) => (
  <div className={classes.root}>
    <Paper className={classes.paper}>
      <Typography className={classes.title} variant="h5">
        Completed
      </Typography>
      {jobs.length === 0 ? (
        <Typography className={classes.description} variant="body2">
          Shifts will be shown here after they are completed
        </Typography>
      ) : (
        <List>
          <InfiniteScroll
            pageStart={0}
            loadMore={onNextPage}
            hasMore={hasNextPage}
            loader={
              <Grid container justify="center" key="infinite-scroll-loader">
                <CircularProgress size={24} />
              </Grid>
            }
            initialLoad={false}
          >
            {jobs.map(job => (
              <ListItem key={job.id} button onClick={e => onSelect(e, job)}>
                <ListItemAvatar>
                  <Avatar
                    alt={job.employee.user.nickname}
                    src={job.employee.photo}
                  />
                </ListItemAvatar>
                <ListItemText
                  className={classes.listItemText}
                  primary={
                    <Fragment>
                      {showBusiness && `${job.business.name} \u00b7 `}
                      {`${job.employee.user.nickname} \u00b7 `}
                      <Typography
                        component="span"
                        className={classes.inline}
                        color="textSecondary"
                      >
                        {job.jobProfile.name}
                      </Typography>
                    </Fragment>
                  }
                  secondary={`${moment(job.actualStartTime).format(
                    'ddd, MMM D @ h:mm a'
                  )} -
                    ${moment(job.actualEndTime).format('h:mm a')}`}
                />
                <ListItemSecondaryAction>
                  <Grid container alignItems="center">
                    {job.jobPrice && (
                      <Typography
                        variant="subtitle1"
                        className={classes.secondaryText}
                      >
                        ${job.jobPrice.employerTotal}
                      </Typography>
                    )}
                  </Grid>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </InfiniteScroll>
        </List>
      )}
    </Paper>
  </div>
)

export default withStyles(styles)(JobsCompleted)
