export const steps = [
  {
    key: 'infoReviewed',
    name: 'Review/correct information provided',
  },
  {
    key: 'idPhotoReviewed',
    name: 'Review photo ID / awaiting new photo',
  },
  {
    key: 'resumeReviewed',
    name: 'Upload and review resume / CV',
  },
  {
    key: 'interviewCompleted',
    name: 'Interview completed, skills assessed',
  },
]
