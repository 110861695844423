import React, { Component, Fragment } from 'react'
import {
  withStyles,
  Button,
  Paper,
  Typography,
  Grid,
  CircularProgress,
} from '@material-ui/core'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import ErrorIcon from '@material-ui/icons/Error'
import { Link } from 'react-router-dom'

const styles = {
  root: {
    margin: 16,
  },
  paper: {
    maxWidth: 1024,
    padding: 32,
  },
  title: {
    marginBottom: 16,
  },
  description: {
    marginBottom: 32,
  },
  textField: {
    minHeight: '5rem',
  },
  errorIcon: {
    verticalAlign: 'middle',
    margin: 4,
  },
}

class ResetPassword extends Component {
  state = {
    password: '',
    passwordConfirm: '',
  }

  handleChange = e => {
    const value = e.target.value
    const name = e.target.name

    this.setState({
      [name]: value,
    })
  }

  handleSubmit = e => {
    const { password } = this.state
    this.props.onSubmit(this.props.token, password)
    e.preventDefault()
  }

  componentDidMount = () => {
    ValidatorForm.addValidationRule(
      'isPasswordMatch',
      value => value === this.state.password
    )
  }

  render = () => {
    const { classes, isValidToken, error, isFetching, complete } = this.props
    const { password, passwordConfirm } = this.state

    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Typography className={classes.title} variant="h5">
            Reset Password
          </Typography>
          {isValidToken && (
            <ValidatorForm
              id="reset-password-form"
              onSubmit={this.handleSubmit}
              instantValidate
            >
              <Grid container spacing={8}>
                <Grid item xs={12} sm={6} lg={4}>
                  <TextValidator
                    id="password"
                    name="password"
                    label="Password"
                    type="password"
                    value={password}
                    onChange={this.handleChange}
                    onKeyPress={this.handleEnter}
                    validators={[
                      'required',
                      'matchRegexp:^(?=.*?[a-zA-Z])(?=.*?[0-9]).*$',
                      'minStringLength:8',
                    ]}
                    errorMessages={[
                      'Required',
                      'Password must contain at least one letter and one number',
                      'Must be at least 8 characters',
                    ]}
                    className={classes.textField}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                  <TextValidator
                    id="password-confirm"
                    name="passwordConfirm"
                    label="Confirm password"
                    type="password"
                    value={passwordConfirm}
                    onChange={this.handleChange}
                    onKeyPress={this.handleEnter}
                    validators={['required', 'isPasswordMatch']}
                    errorMessages={['Required', 'Passwords do not match']}
                    className={classes.textField}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
              </Grid>

              {complete ? (
                <Fragment>
                  <Typography color="primary" variant="h6">
                    Password reset successful
                  </Typography>
                  <Typography color="primary" variant="body2">
                    You may now log in with your new password.
                  </Typography>
                  <Button
                    component={Link}
                    to="/login"
                    color="primary"
                    variant="contained"
                  >
                    Log in
                  </Button>
                </Fragment>
              ) : isFetching ? (
                <CircularProgress />
              ) : (
                <Button
                  type="submit"
                  className={classes.button}
                  variant="contained"
                  color="primary"
                >
                  Submit
                </Button>
              )}
            </ValidatorForm>
          )}

          {error && (
            <Typography
              className={classes.errorMessage}
              variant="body1"
              color="error"
            >
              <ErrorIcon className={classes.errorIcon} />
              {error}
            </Typography>
          )}
        </Paper>
      </div>
    )
  }
}

export default withStyles(styles)(ResetPassword)
