import React, { Fragment } from 'react'
import {
  withStyles,
  Button,
  InputAdornment,
  MenuItem,
  Grid,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  IconButton,
  Typography,
  Divider,
} from '@material-ui/core'
import {
  TextValidator,
  SelectValidator,
} from 'react-material-ui-form-validator'
import { InlineDatePicker } from 'material-ui-pickers'
import DeleteIcon from '@material-ui/icons/Delete'
import moment from 'moment'

const styles = {
  root: {
    marginBottom: 16,
  },
  textField: {
    marginTop: 8,
    marginBottom: 8,
  },
  radioGroup: {
    margin: 8,
    width: '100%',
  },
  deleteIcon: {
    paddingRight: 0,
  },
  divider: {
    margin: '16px 48px',
  },
}

const generateTimeOptions = () => {
  let timeOptions = []
  for (let hour = 0; hour < 24; hour++) {
    for (const minute of ['00', '15', '30', '45']) {
      timeOptions.push(`${hour.toString().padStart(2, '0')}:${minute}`)
    }
  }
  return timeOptions
}

const timeOptions = generateTimeOptions()

const timeValid = (date, timeStr) => {
  return momentSetTime(date, timeStr).isAfter(moment().add(2, 'hours'))
}

const momentSetTime = (timeMoment, timeStr) => {
  const [hour, min] = timeStr.split(':')
  return timeMoment
    .clone()
    .hour(parseInt(hour))
    .minute(parseInt(min))
}

const ShiftTimePicker = ({ classes, time, index, onChange }) => (
  <Grid container spacing={8}>
    <Grid item xs={12} sm={12} md>
      <InlineDatePicker
        id={`startDate-${index}`}
        name={`startDate-${index}`}
        label="Start Date"
        value={time.moment}
        onChange={date => onChange(index, date, time.length)}
        className={classes.textField}
        variant="outlined"
        fullWidth
        disablePast
        maxDate={moment().add(2, 'months')}
        maxDateMessage="Shifts can be request at most 2 months in advance"
      />
    </Grid>
    <Grid item xs>
      <SelectValidator
        id={`startTime-${index}`}
        name={`startTime-${index}`}
        label="Start Time"
        type="text"
        value={time.moment.format('HH:mm')}
        onChange={e =>
          onChange(
            index,
            momentSetTime(time.moment, e.target.value),
            time.length
          )
        }
        // validators={['required', 'validTime']}
        // errorMessages={['Required', 'Shifts must be requested at least 2 hours in advance']}
        className={classes.textField}
        variant="outlined"
        fullWidth
        error={!time.moment.isAfter(moment().add(2, 'hours'))}
        helperText={
          !time.moment.isAfter(moment().add(2, 'hours')) &&
          'Shifts must be requested at least 2 hours in advance'
        }
      >
        {timeOptions.map(timeOption => (
          <MenuItem
            key={timeOption}
            value={timeOption}
            disabled={!timeValid(time.moment, timeOption)}
          >
            {`${timeOption} (${moment(timeOption, 'HH:mm').format('h:mm a')})`}
          </MenuItem>
        ))}
      </SelectValidator>
    </Grid>
    <Grid item xs>
      <TextValidator
        id={`length-${index}`}
        name={`length-${index}`}
        label="Shift Length"
        value={time.length}
        type="number"
        onChange={e => onChange(index, time.moment, e.target.value)}
        validators={['required', 'minNumber:4']}
        errorMessages={['Required', 'Shift must be at least 4 hours']}
        className={classes.textField}
        variant="outlined"
        fullWidth
        InputProps={{
          endAdornment: <InputAdornment position="end">Hours</InputAdornment>,
          inputProps: {
            min: 4,
            step: 'any',
          },
        }}
      />
    </Grid>
  </Grid>
)

const ScheduleJobTimes = ({
  classes,
  times,
  multiple = 'once', // Other values can be 'repeat', 'varied'
  repeat,
  onAddDateTime,
  onRemoveDateTime,
  onDateTimeChange,
  onChange,
}) => {
  const [initialTime, ...additionalTimes] = times
  if (multiple === 'varied' && times.length < 2) {
    onAddDateTime()
  }
  return (
    <div className={classes.root}>
      <ShiftTimePicker
        classes={classes}
        time={initialTime}
        index={0}
        onChange={onDateTimeChange}
      />
      <FormControl component="fieldset" className={classes.radioGroup}>
        <FormLabel component="label">Repeat this shift?</FormLabel>
        <RadioGroup
          aria-label="Book multiple?"
          name="multiple"
          value={multiple}
          onChange={onChange}
        >
          <FormControlLabel
            value="once"
            control={<Radio />}
            label="No repeat, one time only"
          />
          <FormControlLabel
            value="repeat"
            control={<Radio />}
            // label="I need multiple workers at the same time"
            label={
              <Grid container alignItems="center">
                <Grid item>
                  <Typography>
                    I need multiple workers at the same time
                  </Typography>
                </Grid>
                {multiple === 'repeat' && (
                  <Grid item>
                    <TextValidator
                      id="repeat"
                      name="repeat"
                      label="How many?"
                      value={repeat}
                      type="number"
                      onChange={onChange}
                      validators={['required', 'minNumber:2', 'maxNumber:100']}
                      errorMessages={[
                        'Required',
                        'Repeating position requires at least 2',
                        'Maximum of 100 repeating shifts',
                      ]}
                      className={classes.textField}
                      style={{ marginLeft: 8, width: 150 }}
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        inputProps: {
                          min: 2,
                          step: 'any',
                        },
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            }
          />
          <FormControlLabel
            value="varied"
            control={<Radio />}
            label="I want to book this position for multiple days and/or times"
          />
        </RadioGroup>

        {multiple === 'varied' && (
          <div>
            {additionalTimes.map((time, index) => (
              <Fragment key={index}>
                <Grid container alignItems="center">
                  <Grid item xs>
                    <ShiftTimePicker
                      classes={classes}
                      time={time}
                      index={index + 1}
                      onChange={onDateTimeChange}
                    />
                  </Grid>
                  <Grid item>
                    <IconButton
                      className={classes.deleteIcon}
                      onClick={() => onRemoveDateTime(index + 1)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                {index < additionalTimes.length - 1 && (
                  <Divider className={classes.divider} />
                )}
              </Fragment>
            ))}
            <Button color="primary" onClick={onAddDateTime}>
              Add another
            </Button>
          </div>
        )}
      </FormControl>
    </div>
  )
}

export default withStyles(styles)(ScheduleJobTimes)
