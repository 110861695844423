import React from 'react'
import { ValidatorComponent } from 'react-form-validator-core'
import { withStyles, FormHelperText, IconButton, Grid } from '@material-ui/core'
import StarIcon from '@material-ui/icons/Star'
import StarBorderIcon from '@material-ui/icons/StarBorder'

const styles = {
  root: {
    paddingBottom: 8,
  },
  error: {
    margin: 0,
  },
  starButton: {
    paddingBottom: 4,
  },
}

class RatingValidator extends ValidatorComponent {
  render() {
    /* eslint-disable no-unused-vars */
    const {
      classes,
      label,
      errorMessages,
      validators,
      requiredError,
      rating,
      onStarClick,
      ...rest
    } = this.props
    const { isValid } = this.state

    const RatingStarIcon = ({ value }) =>
      rating >= value ? (
        <StarIcon fontSize="large" color="secondary" />
      ) : (
        <StarBorderIcon fontSize="large" color="secondary" />
      )

    const Rating = () => {
      let stars = []

      for (let i = 1; i <= 5; i++) {
        stars.push(
          <IconButton
            className={classes.starButton}
            key={i}
            onClick={() => onStarClick(i)}
          >
            <RatingStarIcon value={i} />
          </IconButton>
        )
      }

      return <div className={classes.starRating}>{stars}</div>
    }

    return (
      <div className={classes.root}>
        <Grid container justify="center">
          <Rating {...rest} />
        </Grid>
        <Grid container justify="center">
          {!isValid && (
            <FormHelperText error className={classes.error}>
              {this.getErrorMessage()}
            </FormHelperText>
          )}
        </Grid>
      </div>
    )
  }
}

export default withStyles(styles)(RatingValidator)
