import React, { Fragment } from 'react'
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Typography,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'

import { drawerWidth } from '../config/theme'
import AppBarMenu from './AppBarMenu'
import NotificationMenu from './NotificationMenu'

const styles = theme => ({
  flex: {
    flex: 1,
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  image: {
    height: 24, // 56-padding(32)
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      height: 16, // 48-padding(32)
    },
    [theme.breakpoints.up('sm')]: {
      height: 32, // 64-padding(32)
    },
    marginRight: 24,
  },
  name: {
    marginRight: 24,
  },
  backButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
})

const hasBackButton = page =>
  page === 'Shift Details' ||
  page === 'Schedule Shift' ||
  page === 'Contractor Details'

const MainAppBar = ({
  classes,
  title,
  isAuthenticated,
  currentPage,
  logout,
  notifications,
  onMenuClick,
  onBack,
}) => (
  <div className={classes.root}>
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        {hasBackButton(currentPage) ? (
          <IconButton
            className={classes.menuButton}
            color="inherit"
            aria-label="Back"
            onClick={onBack}
          >
            <ArrowBackIcon />
          </IconButton>
        ) : (
          <IconButton
            className={classes.menuButton}
            color="inherit"
            aria-label="Menu"
            onClick={onMenuClick}
          >
            <MenuIcon />
          </IconButton>
        )}
        <Typography variant="h6" color="inherit" className={classes.name}>
          {title}
        </Typography>
        <div className={classes.flex} />
        {!isAuthenticated &&
          currentPage !== 'Login' &&
          currentPage !== 'Contractor Sign Up' &&
          currentPage !== 'Sign Up Success' && (
            <Button component={Link} to="/login" color="inherit">
              Login
            </Button>
          )}
        {isAuthenticated && (
          <Fragment>
            <NotificationMenu notifications={notifications} />
            <AppBarMenu logout={logout} />
          </Fragment>
        )}
      </Toolbar>
    </AppBar>
  </div>
)

export default withStyles(styles)(MainAppBar)
