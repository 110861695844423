import React from 'react'
import {
  withStyles,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListSubheader,
  ListItemSecondaryAction,
  IconButton,
  Hidden,
  Typography,
  Radio,
} from '@material-ui/core'
import AssignmentIcon from '@material-ui/icons/Assignment'
import DeleteIcon from '@material-ui/icons/Delete'

const styles = theme => ({
  root: {},
  wage: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: 48,
    },
  },
  category: {
    marginBottom: -16,
  },
})

const JobProfilesList = ({
  classes,
  jobProfiles,
  selectedProfile,
  showDelete = false,
  radio = false,
  onItemClick,
  onDelete,
}) => (
  <div className={classes.root}>
    <List>
      {jobProfiles
        .sort((a, b) => a.jobType.order - b.jobType.order) // Sort jobTypes
        .reduce((categories, jobProfile) => {
          // Group into categories
          const jobProfileCategory = jobProfile.jobType.jobCategory || {
            name: 'General',
          }
          const index = categories.findIndex(
            category => jobProfileCategory.name === category.name
          )
          index < 0
            ? categories.push({
                ...jobProfileCategory,
                jobProfiles: [jobProfile],
              })
            : categories[index].jobProfiles.push(jobProfile)
          return categories
        }, [])
        .sort((a, b) => a.order - b.order) // Sort categories
        .map(category => (
          <div key={category.id}>
            <ListSubheader className={classes.category}>
              {category.name}
            </ListSubheader>
            {category.jobProfiles.map(jobProfile => (
              <ListItem
                key={jobProfile.id}
                button
                onClick={() => onItemClick(jobProfile)}
              >
                {radio ? (
                  <Radio
                    checked={
                      selectedProfile && selectedProfile.id === jobProfile.id
                    }
                  />
                ) : (
                  <ListItemAvatar>
                    <Avatar>
                      <AssignmentIcon />
                    </Avatar>
                  </ListItemAvatar>
                )}
                <ListItemText
                  primary={jobProfile.name}
                  secondary={`${jobProfile.jobType.name}${
                    jobProfile.jobType.experience
                      ? // ? ` \u2014 ${
                        //     jobProfile.jobType.experience
                        //   } experience`
                        ` [${jobProfile.jobType.experience} experience]`
                      : ''
                  }`}
                />
                <Typography variant="subtitle1" className={classes.wage}>{`$${
                  jobProfile.jobType.wage
                }/hr`}</Typography>
                {showDelete && (
                  <Hidden xsDown>
                    <ListItemSecondaryAction>
                      <IconButton
                        onClick={e => {
                          e.stopPropagation()
                          e.preventDefault()
                          onDelete(jobProfile)
                        }}
                        aria-label="Delete"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </Hidden>
                )}
              </ListItem>
            ))}
          </div>
        ))}
    </List>
  </div>
)

export default withStyles(styles)(JobProfilesList)
