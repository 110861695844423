import React, { Component } from 'react'
import { connect } from 'react-redux'

import ScheduleJob from './ScheduleJob'

import { createMultipleJobs } from '../reducers/job'
import { fetchJobProfiles, createJobProfile } from '../reducers/job-profile'
import { fetchJobTypes } from '../reducers/job-type'
import { geolocate } from '../reducers/maps'

class ScheduleJobPage extends Component {
  componentDidMount = () => {
    this.props.fetchJobProfiles()
    this.props.fetchJobTypes(this.props.user.regionId)
    this.props.geolocate()
  }

  render = () => {
    const {
      jobProfiles,
      jobTypes,
      isFetching,
      error,
      location,
      businessAddress,
      createJobProfile,
      createMultipleJobs,
    } = this.props
    return (
      <ScheduleJob
        jobProfiles={jobProfiles}
        jobTypes={jobTypes}
        working={isFetching}
        error={error}
        location={location}
        businessAddress={businessAddress}
        onSubmit={createMultipleJobs}
        onSubmitJobProfile={createJobProfile}
      />
    )
  }
}

const mapStateToProps = ({
  authentication: { user },
  job: { isFetching, error },
  jobProfile: { jobProfiles },
  jobType: { jobTypes },
  maps: { location },
}) => ({
  user,
  isFetching,
  error,
  jobProfiles,
  jobTypes,
  location,
  businessAddress:
    user.employer &&
    user.employer.business &&
    user.employer.business.address &&
    user.employer.business.address,
})

const mapDispatchToProps = {
  createMultipleJobs,
  createJobProfile,
  fetchJobProfiles,
  fetchJobTypes,
  geolocate,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleJobPage)
