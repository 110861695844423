import React from 'react'
import { withStyles } from '@material-ui/core'
import { TextValidator } from 'react-material-ui-form-validator'

const styles = {
  root: {
    marginBottom: 8,
  },
  textField: {
    marginTop: 8,
    marginBottom: 8,
  },
}

const ScheduleJobDetails = ({
  classes,
  managerName,
  specialInstructions,
  onChange,
}) => (
  <div className={classes.root}>
    <TextValidator
      id="managerName"
      name="managerName"
      label="Name of person(s) worker should check in with"
      type="text"
      value={managerName}
      onChange={onChange}
      validators={['required', 'maxStringLength:255']}
      errorMessages={['Required', 'Maximum 255 characters']}
      className={classes.textField}
      variant="outlined"
      fullWidth
    />
    <TextValidator
      id="specialInstructions"
      name="specialInstructions"
      label="Special instructions for shift (optional)"
      type="text"
      multiline
      rows={3}
      value={specialInstructions}
      onChange={onChange}
      validators={['maxStringLength:255']}
      errorMessages={['Maximum 255 characters']}
      helperText={`${specialInstructions.length} / 255`}
      className={classes.textField}
      variant="outlined"
      fullWidth
    />
  </div>
)

export default withStyles(styles)(ScheduleJobDetails)
