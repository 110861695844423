import axios from 'axios'

//////////////////////////////
// Action Types
//////////////////////////////
export const FETCH_JOB_TYPES_REQUESTED = 'jobType/FETCH_REQUESTED'
export const FETCH_JOB_TYPES_ERROR = 'jobType/FETCH_ERROR'
export const FETCH_JOB_TYPES = 'jobType/FETCH'

//////////////////////////////
// Reducer
//////////////////////////////
const initialState = {
  isFetching: false,
  jobTypes: [],
  error: null,
}
export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_JOB_TYPES_REQUESTED:
      return {
        ...state,
        isFetching: true,
      }
    case FETCH_JOB_TYPES_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      }
    case FETCH_JOB_TYPES:
      return {
        ...state,
        isFetching: false,
        error: null,
        jobTypes: action.jobTypes,
      }
    default:
      return state
  }
}

//////////////////////////////
// Action Creators
//////////////////////////////

export const fetchJobTypes = regionId => async dispatch => {
  dispatch({
    type: FETCH_JOB_TYPES_REQUESTED,
  })
  try {
    const result = await axios.get('/api/job-types', {
      params: {
        regionId,
      },
    })
    dispatch({
      type: FETCH_JOB_TYPES,
      jobTypes: result.data,
    })
  } catch (error) {
    dispatch({
      type: FETCH_JOB_TYPES_ERROR,
      error: error.response.data.message,
    })
  }
}
