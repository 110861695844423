import React, { Fragment, Component, createRef } from 'react'
import {
  withStyles,
  Avatar,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputAdornment,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  MenuItem,
  Paper,
  Switch,
  Typography,
} from '@material-ui/core'
import Slider from '@material-ui/lab/Slider'
import ErrorIcon from '@material-ui/icons/Error'
import SaveIcon from '@material-ui/icons/Save'
import { withRouter } from 'react-router-dom'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import InputMask from 'react-input-mask'
import PlacesAutocomplete from 'react-places-autocomplete'
import poweredByGoogle from '../images/powered_by_google_on_white.png'

import ContractorStats from './ContractorStats'
import CheckboxValidator from './CheckboxValidator'

import { steps } from '../util/onboarding'

const aboutPlaceholder =
  'eg.\n- Big fan of Toronto FC\n- Love to travel, have been all over the world\n- Huge Star Wars fan'

const styles = {
  root: {
    margin: 16,
  },
  icon: {
    verticalAlign: 'middle',
    marginRight: 8,
  },
  textField: {
    minHeight: '5rem',
  },
  onboarding: {
    padding: 16,
  },
  info: {
    marginTop: 16,
    marginBottom: 16,
    padding: 16,
  },
  identificationPhoto: {
    marginTop: 16,
    marginBottom: 16,
    padding: 16,
  },
  resume: {
    marginTop: 16,
    marginBottom: 16,
    padding: 16,
  },
  stats: {
    marginBottom: 32,
  },
  skills: {
    marginTop: 16,
    marginBottom: 16,
    padding: 16,
    maxWidth: 600,
  },
  title: {
    marginBottom: 16,
  },
  jobCategory: {
    marginBottom: -16,
  },
  skillList: {},
  skillInput: {
    maxWidth: 150,
  },
  fileInput: {
    display: 'none',
  },
  softSkillItem: {
    padding: '8px 0 8px 0',
  },
  softSkillLabel: {
    paddingRight: 16,
  },
  softSkillNumber: {
    padding: 4,
  },
  slider: {
    // width: 200,
    paddingBottom: 16,
  },
}

const initializeState = (contractor, jobTypes, softSkills) => {
  if (contractor && jobTypes && softSkills) {
    const {
      user: { name, nickname, email, phone, activated },
      about,
      address,
      addressGPlaceId,
      identificationPhoto,
      resume,
    } = contractor
    // Look through every jobType to set selected and skill for this contractor
    jobTypes = jobTypes.map(jobType => {
      const contractorJobType = contractor.jobTypes.find(
        jt => jt.id === jobType.id
      )
      return {
        ...jobType,
        selected: Boolean(contractorJobType),
        skill: contractorJobType
          ? contractorJobType.employee_jobType.skill
          : null,
      }
    })
    // If no soft skills set yet use empty array,
    // otherwise set up with the existing and 1 for those that do not exist
    softSkills =
      contractor.softSkills.length === 0
        ? []
        : softSkills.map(softSkill => {
            const contractorSoftSkill = contractor.softSkills.find(
              ss => ss.id === softSkill.id
            )
            return {
              ...softSkill,
              skill: contractorSoftSkill
                ? contractorSoftSkill.employee_softSkill.skill
                : 1,
            }
          })
    const onboardingSteps = steps.map(step => ({
      ...step,
      selected: contractor[step.key],
    }))
    return {
      name,
      nickname,
      email,
      phone,
      activated,
      about,
      address,
      selectedAddress: address,
      placeId: addressGPlaceId,
      identificationPhoto,
      identificationPhotoFile: null,
      resume,
      resumeFile: null,
      jobTypes,
      softSkills,
      isSaving: false,
      onboardingSteps,
    }
  }

  return {
    name: '',
    nickname: '',
    email: '',
    phone: '',
    activated: false,
    about: '',
    address: '',
    selectedAddress: '',
    placeId: '',
    identificationPhoto: '',
    identificationPhotoFile: null,
    resume: '',
    resumeFile: null,
    jobTypes: [],
    softSkills: [],
    isSaving: false,
    onboardingSteps: [],
  }
}

/**
 * "key" prop is required because we are initializing state from props.
 * https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#recommendation-fully-uncontrolled-component-with-a-key
 */
class ContractorDetails extends Component {
  state = initializeState(
    this.props.contractor,
    this.props.jobTypes,
    this.props.softSkills
  )

  identificationPhotoFileInputRef = createRef()
  resumeFileInputRef = createRef()

  handleChange = e => {
    const { name, value, type, checked } = e.target

    this.setState({
      [name]: type === 'checkbox' ? checked : value,
    })
  }

  handleChangeSkill = (jobTypeId, selected, skill) => {
    this.setState(prevState => ({
      jobTypes: prevState.jobTypes.map(jobType =>
        jobType.id === jobTypeId
          ? {
              ...jobType,
              selected,
              skill: selected ? skill : null,
            }
          : jobType
      ),
    }))
  }

  handleChangeSoftSkill = (softSkillId, skill) => {
    this.setState(prevState => ({
      softSkills: prevState.softSkills.map(softSkill =>
        softSkill.id === softSkillId
          ? {
              ...softSkill,
              skill,
            }
          : softSkill
      ),
    }))
  }

  handleChangeOnboardingStep = e => {
    const { name, checked } = e.target
    this.setState(prevState => ({
      onboardingSteps: prevState.onboardingSteps.map(step =>
        step.key === name
          ? {
              ...step,
              selected: checked,
            }
          : step
      ),
    }))
  }

  handleAddressChange = address => {
    this.setState({ address, selectedAddress: '', placeId: '' })
  }
  handleAddressSelect = (address, placeId) => {
    this.setState({ address, selectedAddress: address, placeId })
  }

  handleIdentificationPhotoChange = () => {
    this.openIdentificationPhotoFileDialog()
  }
  openIdentificationPhotoFileDialog = () => {
    this.identificationPhotoFileInputRef.current.click()
  }
  onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      this.setState({
        identificationPhotoFile: e.target.files[0],
      })
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        () => {
          this.setState({
            identificationPhoto: reader.result,
          })
        },
        false
      )
      reader.readAsDataURL(e.target.files[0])
    }
  }

  handleResumeFileChange = () => {
    this.openResumeFileDialog()
  }
  openResumeFileDialog = () => {
    this.resumeFileInputRef.current.click()
  }
  onSelectResumeFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      this.setState({
        resumeFile: e.target.files[0],
      })
    }
  }

  handleAssessSoftSkills = () => {
    this.setState({
      softSkills: this.props.softSkills.map(softSkill => ({
        ...softSkill,
        skill: 1,
      })),
    })
  }

  handleInstantActivation = activated => {
    let { id, ...contractor } = this.props.contractor
    // Set here for onSave
    contractor.user.activated = activated
    // Set here for future of this page, as they will remain and can make other changes
    this.setState({
      activated,
    })
    // I will also save changes to onboarding steps, as they likely changed, and they will disappear now too
    for (const step of this.state.onboardingSteps) {
      contractor = { ...contractor, [step.key]: step.selected }
    }
    this.props.onSave(id, contractor)
  }

  handleSave = async () => {
    this.setState({ isSaving: true })
    const {
      name,
      nickname,
      email,
      password,
      phone,
      activated,
      about,
      selectedAddress,
      placeId,
      identificationPhotoFile,
      resumeFile,
      onboardingSteps,
    } = this.state
    // Reshape jobTypes and filter to only the enabled
    const jobTypes = this.state.jobTypes
      .filter(jobType => jobType.selected)
      .map(({ id, skill }) => ({
        id,
        skill,
      }))
    // Reshape softSkills
    const softSkills = this.state.softSkills.map(({ id, skill }) => ({
      id,
      skill,
    }))
    let employee = {
      user: {
        name,
        nickname,
        email,
        password,
        phone,
        activated,
      },
      about,
      address: selectedAddress,
      addressGPlaceId: placeId,
      jobTypes,
      softSkills,
    }
    for (const step of onboardingSteps) {
      employee = { ...employee, [step.key]: step.selected }
    }

    if (identificationPhotoFile !== null) {
      const identificationPhoto = await this.props.onUploadIdentificationPhoto(
        this.props.contractor,
        identificationPhotoFile
      )
      if (this.props.error) {
        this.setState({ isSaving: false })
        return
      }
      employee = { ...employee, identificationPhoto }
    }
    if (resumeFile !== null) {
      const resume = await this.props.onUploadResume(
        this.props.contractor,
        resumeFile
      )
      if (this.props.error) {
        this.setState({ isSaving: false })
        return
      }
      employee = { ...employee, resume }
    }

    const { id } = this.props.contractor
    await this.props.onSave(id, employee)

    this.setState({ isSaving: false })

    if (!this.props.error) {
      this.props.history.push('/contractors')
    }
  }

  render = () => {
    const {
      classes,
      contractor,
      location,
      loadingContractor,
      loadingJobTypes,
      loadingSoftSkills,
      error,
    } = this.props
    const {
      name,
      nickname,
      email,
      phone,
      activated,
      about,
      address,
      selectedAddress,
      identificationPhoto,
      resume,
      resumeFile,
      jobTypes,
      softSkills,
      isSaving,
      onboardingSteps,
    } = this.state

    // Get default location if exists, otherwise default to somewhere that makes sense
    // TODO: Fallback is hard-coded to Toronto for now (Yonge & Eglinton)
    const lat = location && location.lat ? location.lat : 43.7064477
    const lng = location && location.lng ? location.lng : -79.3998241
    const defaultLocation = new window.google.maps.LatLng(lat, lng)

    return (
      <div className={classes.root}>
        <Dialog open={isSaving} aria-labelledby="save-progress-title">
          <DialogTitle id="save-progress-title">Saving contractor</DialogTitle>
          <DialogContent>
            <LinearProgress />
          </DialogContent>
        </Dialog>
        <ValidatorForm onSubmit={this.handleSave} instantValidate>
          {activated == null && (
            <div className={classes.onboarding}>
              <FormGroup>
                <FormLabel>Onboarding Steps</FormLabel>
                {onboardingSteps.map(step => (
                  <CheckboxValidator
                    key={step.key}
                    id={step.key}
                    name={step.key}
                    label={step.name}
                    checked={step.selected}
                    value={String(step.selected)}
                    onChange={this.handleChangeOnboardingStep}
                  />
                ))}
                <Typography>
                  {' '}
                  Finally, either{' '}
                  <Button
                    onClick={() => this.handleInstantActivation(true)}
                    color="primary"
                  >
                    Activate
                  </Button>{' '}
                  or{' '}
                  <Button
                    onClick={() => this.handleInstantActivation(false)}
                    color="primary"
                  >
                    De-activate
                  </Button>{' '}
                  the contractor{' '}
                </Typography>
              </FormGroup>
            </div>
          )}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={loadingContractor}
          >
            <SaveIcon className={classes.icon} />
            Save
          </Button>
          {error && (
            <Typography variant="body1" color="error">
              <ErrorIcon className={classes.icon} color="error" />
              {error}
            </Typography>
          )}
          <Grid container spacing={8}>
            <Grid item xs={12} sm={5}>
              {/* Information */}
              <Paper className={classes.info}>
                <Grid container justify="space-between">
                  <Typography variant="h6" className={classes.title}>
                    Information
                  </Typography>
                  <FormControlLabel
                    name="activated"
                    control={
                      <Switch
                        checked={Boolean(activated)}
                        onChange={this.handleChange}
                        value={String(activated)}
                      />
                    }
                    label="Activated"
                  />
                </Grid>
                {loadingContractor && !isSaving ? (
                  <CircularProgress />
                ) : (
                  <Fragment>
                    <div className={classes.stats}>
                      <Grid container spacing={16} alignItems="center">
                        <Grid item>
                          <Avatar alt="photo" src={contractor.photo} />
                        </Grid>
                        <Grid item>
                          <ContractorStats contractor={contractor} />
                        </Grid>
                      </Grid>
                    </div>
                    <Grid container spacing={8}>
                      <Grid item xs={12} lg={6}>
                        <TextValidator
                          id="name"
                          name="name"
                          label="Full name"
                          type="text"
                          value={name}
                          onChange={this.handleChange}
                          onKeyPress={this.handleEnter}
                          validators={['required']}
                          errorMessages={['Required']}
                          className={classes.textField}
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={8}>
                      <Grid item xs={12} lg={6}>
                        <TextValidator
                          id="nickname"
                          name="nickname"
                          label="What should we call you?"
                          placeholder="eg. First name, nickname, short name, etc"
                          type="text"
                          value={nickname}
                          onChange={this.handleChange}
                          onKeyPress={this.handleEnter}
                          validators={['required']}
                          errorMessages={['Required']}
                          className={classes.textField}
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={8}>
                      <Grid item xs={12} lg={6}>
                        <TextValidator
                          id="email"
                          name="email"
                          label="Email"
                          type="email"
                          value={email}
                          onChange={this.handleChange}
                          onKeyPress={this.handleEnter}
                          validators={['required', 'isEmail']}
                          errorMessages={['Required', 'Invalid email format']}
                          className={classes.textField}
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <InputMask
                          mask="(999) 999-9999"
                          maskChar=" "
                          value={phone}
                          onChange={this.handleChange}
                          onKeyPress={this.handleEnter}
                          className={classes.textField}
                        >
                          {() => (
                            <TextValidator
                              id="phone"
                              name="phone"
                              label="Phone"
                              className={classes.textField}
                              variant="outlined"
                              fullWidth
                              validators={[
                                'required',
                                'matchRegexp:^\\([0-9]{3}\\) [0-9]{3}-[0-9]{4}$',
                              ]}
                              errorMessages={[
                                'Required',
                                'Invalid phone number',
                              ]}
                            />
                          )}
                        </InputMask>
                      </Grid>
                    </Grid>
                    <Grid container spacing={8}>
                      <Grid item xs={12}>
                        <PlacesAutocomplete
                          value={address}
                          onChange={this.handleAddressChange}
                          onSelect={this.handleAddressSelect}
                          searchOptions={{
                            location: defaultLocation,
                            radius: 50000,
                          }}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => (
                            <Fragment>
                              <TextValidator
                                id="address"
                                name="address"
                                label="Address"
                                value={address}
                                className={classes.textField}
                                variant="outlined"
                                fullWidth
                                validators={['required']}
                                errorMessages={['Required']}
                                helperText={
                                  address &&
                                  !selectedAddress &&
                                  'You must select your address from the list'
                                }
                                error={Boolean(address && !selectedAddress)}
                                InputProps={{
                                  ...getInputProps(),
                                  inputProps: {
                                    autoComplete: 'disabled',
                                  },
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <img
                                        className={classes.poweredByGoogle}
                                        src={poweredByGoogle}
                                        alt="Powered by Google"
                                      />
                                    </InputAdornment>
                                  ),
                                  // Clear address when in focus
                                  onFocus: () => this.handleAddressChange(''),
                                  // Reset address on blur if nothing was selected
                                  onBlur: () => {
                                    if (address === '') {
                                      this.handleAddressSelect(
                                        contractor.address,
                                        contractor.addressGPlaceId
                                      )
                                    }
                                  },
                                }}
                              />
                              <Paper>
                                {loading && <CircularProgress />}
                                {suggestions.map(suggestion => (
                                  <MenuItem
                                    key={suggestion.index}
                                    value={suggestion.description}
                                    {...getSuggestionItemProps(suggestion)}
                                  >
                                    {suggestion.description}
                                  </MenuItem>
                                ))}
                              </Paper>
                            </Fragment>
                          )}
                        </PlacesAutocomplete>
                      </Grid>
                    </Grid>
                    <Grid container spacing={8}>
                      <Grid item xs={12}>
                        <TextValidator
                          id="about"
                          name="about"
                          label="Fun facts about you"
                          type="text"
                          multiline
                          rows={4}
                          value={about}
                          placeholder={aboutPlaceholder}
                          onChange={this.handleChange}
                          validators={['required', 'maxStringLength:1023']}
                          errorMessages={[
                            'Required',
                            'Maximum 1023 characters',
                          ]}
                          helperText={`${about.length} / 1023`}
                          className={classes.textField}
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Fragment>
                )}
              </Paper>

              {/* Photo ID */}
              <Paper className={classes.identificationPhoto}>
                <input
                  ref={this.identificationPhotoFileInputRef}
                  className={classes.fileInput}
                  type="file"
                  accept="image/*"
                  onChange={this.onSelectFile}
                />
                <Grid container justify="space-between">
                  <Typography variant="h6" className={classes.title}>
                    Photo ID
                  </Typography>
                  <Button
                    color="primary"
                    onClick={this.handleIdentificationPhotoChange}
                  >
                    Upload ID Photo
                  </Button>
                </Grid>
                {loadingContractor && !isSaving ? (
                  <CircularProgress />
                ) : identificationPhoto ? (
                  <img
                    src={identificationPhoto}
                    alt="Identification"
                    width="100%"
                  />
                ) : (
                  <Typography>No photo ID provided.</Typography>
                )}
              </Paper>

              {/* Resume */}
              <Paper className={classes.resume}>
                <input
                  ref={this.resumeFileInputRef}
                  className={classes.fileInput}
                  type="file"
                  accept=".doc, .docx, .txt, .pdf"
                  onChange={this.onSelectResumeFile}
                />
                <Grid container justify="space-between">
                  <Typography variant="h6" className={classes.title}>
                    Resume / CV
                  </Typography>
                  <Button color="primary" onClick={this.handleResumeFileChange}>
                    Upload Resume
                  </Button>
                </Grid>
                {loadingContractor && !isSaving ? (
                  <CircularProgress />
                ) : resumeFile ? (
                  <Typography>
                    File <code>{resumeFile.name}</code> is ready for upload.
                    Save to upload.
                  </Typography>
                ) : resume ? (
                  <iframe
                    title={resume}
                    src={`https://docs.google.com/gview?url=${resume}&embedded=true`}
                  />
                ) : (
                  <Typography>No resume / CV on file.</Typography>
                )}
              </Paper>
            </Grid>

            <Grid item xs={12} sm={7}>
              {/* Skills */}
              <Paper className={classes.skills}>
                <Typography variant="h6" className={classes.title}>
                  Skills
                </Typography>
                {(loadingContractor || loadingJobTypes) && !isSaving ? (
                  <CircularProgress />
                ) : (
                  <List disablePadding className={classes.skillList}>
                    {jobTypes
                      .sort((a, b) => a.order - b.order) // Sort jobTypes
                      .reduce((categories, jobType) => {
                        // Group into categories
                        const jobTypeCategory = jobType.jobCategory || {
                          name: 'General',
                          id: 0,
                        }
                        const index = categories.findIndex(
                          category => jobTypeCategory.name === category.name
                        )
                        index < 0
                          ? categories.push({
                              ...jobTypeCategory,
                              jobTypes: [jobType],
                            })
                          : categories[index].jobTypes.push(jobType)
                        return categories
                      }, [])
                      .sort((a, b) => a.order - b.order)
                      .map(category => (
                        <div key={category.id}>
                          <ListSubheader
                            disableGutters
                            className={classes.jobCategory}
                          >
                            {category.name}
                          </ListSubheader>
                          {category.jobTypes.map(jobType => (
                            <ListItem key={jobType.id} disableGutters>
                              <Checkbox
                                checked={jobType.selected}
                                value={String(jobType.selected)}
                                onChange={e =>
                                  this.handleChangeSkill(
                                    jobType.id,
                                    e.target.checked,
                                    1
                                  )
                                }
                              />
                              <ListItemText primary={jobType.name} />
                              {jobType.selected && (
                                <TextValidator
                                  id={`skill-${jobType.id}`}
                                  name={`skill-${jobType.id}`}
                                  label="Skill Level (1-5)"
                                  value={jobType.skill}
                                  type="number"
                                  onChange={e =>
                                    this.handleChangeSkill(
                                      jobType.id,
                                      jobType.selected,
                                      e.target.value
                                    )
                                  }
                                  validators={[
                                    'required',
                                    'minNumber:1',
                                    'maxNumber:5',
                                  ]}
                                  errorMessages={[
                                    'Required',
                                    'Minimum is 1',
                                    'Maximum is 5',
                                  ]}
                                  className={classes.skillInput}
                                  variant="outlined"
                                  // fullWidth
                                  InputProps={{
                                    inputProps: {
                                      min: 1,
                                      max: 5,
                                      step: 'any',
                                    },
                                  }}
                                />
                              )}
                            </ListItem>
                          ))}
                        </div>
                      ))}
                  </List>
                )}
              </Paper>

              {/* Soft Skills */}
              <Paper className={classes.skills}>
                <Typography variant="h6" className={classes.title}>
                  Soft Skills
                </Typography>
                {(loadingContractor || loadingSoftSkills) && !isSaving ? (
                  <CircularProgress />
                ) : softSkills.length === 0 ? (
                  <Typography>
                    Soft skills not assessed yet.
                    <Button
                      color="primary"
                      onClick={this.handleAssessSoftSkills}
                    >
                      Assess soft skills
                    </Button>
                  </Typography>
                ) : (
                  <List disablePadding className={classes.skillList}>
                    {softSkills.map(softSkill => (
                      <div className={classes.softSkillItem} key={softSkill.id}>
                        <Grid container alignItems="center">
                          <Grid item sm={4} xs={6}>
                            <Typography
                              className={classes.softSkillLabel}
                              variant="subtitle1"
                            >
                              {softSkill.name}
                            </Typography>
                          </Grid>
                          <Grid item sm={8} xs={6}>
                            <div>
                              <Typography
                                align="center"
                                className={classes.softSkillNumber}
                              >
                                {softSkill.skill}
                              </Typography>
                              <Slider
                                className={classes.slider}
                                color="secondary"
                                min={1}
                                max={5}
                                step={1}
                                value={softSkill.skill}
                                onChange={(event, value) => {
                                  this.handleChangeSoftSkill(
                                    softSkill.id,
                                    value
                                  )
                                }}
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    ))}
                  </List>
                )}
              </Paper>
            </Grid>
          </Grid>
        </ValidatorForm>
      </div>
    )
  }
}

export default withStyles(styles)(withRouter(ContractorDetails))
