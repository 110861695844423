import React, { Component } from 'react'
import {
  withStyles,
  withMobileDialog,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { InlineDatePicker, TimePicker } from 'material-ui-pickers'
import moment from 'moment'

const styles = {
  root: {
    margin: 32,
  },
  description: {
    marginBottom: 16,
  },
  shiftLength: {
    margin: 8,
  },
  textField: {
    marginTop: 8,
    marginBottom: 8,
  },
  progress: {
    margin: 48,
  },
  warningIcon: {
    margin: 8,
  },
}

const initialState = () => ({
  timeMoment: null,
  validTime: true,
  overMaxLength: false,
})

class JobStopDialog extends Component {
  state = initialState()

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.job && nextProps.job !== prevState.prevJob) {
      const { actualEndTime, bookedEndTime } = nextProps.job
      const timeMoment = actualEndTime
        ? moment(actualEndTime)
        : moment(bookedEndTime)
      return {
        timeMoment,
        overMaxLength: timeMoment.isAfter(
          moment(nextProps.job.actualStartTime).add(15, 'hours')
        ),
        prevJob: nextProps.job,
      }
    }
    return prevState
  }

  handleDateChange = date => {
    this.setState({
      timeMoment: date,
      validTime:
        moment().isAfter(date) && date.isAfter(this.props.job.actualStartTime),
      overMaxLength: date.isAfter(
        moment(this.props.job.actualStartTime).add(15, 'hours')
      ),
    })
  }

  handleSubmit = e => {
    const time = this.state.timeMoment.toDate()
    this.props.onSubmit(this.props.job, time)
    this.props.onClose()
    this.setState(initialState())
    e.preventDefault()
  }

  render = () => {
    const { classes, fullScreen, open, job, working, onClose } = this.props
    const { timeMoment, validTime, overMaxLength } = this.state

    return (
      <div className={classes.root}>
        <Dialog
          open={open}
          onClose={onClose}
          fullScreen={fullScreen}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title" onClose={onClose}>
            Stop Shift
          </DialogTitle>
          <DialogContent>
            <Typography variant="subtitle1" className={classes.description}>
              Enter actual end time of shift
            </Typography>
            <ValidatorForm
              id="stop-job-form"
              onSubmit={this.handleSubmit}
              instantValidate
            >
              <Grid container spacing={8}>
                <Grid item xs>
                  <InlineDatePicker
                    id="endDate"
                    name="endDate"
                    label="End Date"
                    value={timeMoment}
                    onChange={this.handleDateChange}
                    className={classes.textField}
                    variant="outlined"
                    fullWidth
                    disableFuture
                  />
                </Grid>
                <Grid item xs>
                  <TimePicker
                    id="endTime"
                    name="endTime"
                    label="End Time"
                    value={timeMoment}
                    onChange={this.handleDateChange}
                    className={classes.textField}
                    variant="outlined"
                    fullWidth
                    error={!validTime || overMaxLength}
                    helperText={
                      !validTime
                        ? 'Must be after the start time, and cannot be in the future'
                        : overMaxLength &&
                          'Shift cannot be over 15 hours, contact support if this is not an accident'
                    }
                  />
                </Grid>
              </Grid>
              {timeMoment &&
                !timeMoment.isBetween(
                  moment(job.bookedEndTime).subtract(31, 'minutes'),
                  moment(job.bookedEndTime).add(31, 'minutes')
                ) && (
                  <Grid container alignItems="center">
                    <Grid item>
                      <WarningIcon
                        color="error"
                        className={classes.warningIcon}
                      />
                    </Grid>
                    <Grid item xs>
                      <Typography variant="body1" color="error">
                        Warning: This end time differs by more than 30 mins from
                        the booked end time. Please double-check the entered
                        time, especially AM/PM.
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              {validTime && timeMoment && (
                <Typography variant="subtitle2" className={classes.shiftLength}>
                  {`Shift length would be: ${timeMoment
                    .diff(job.actualStartTime, 'hours', true)
                    .toFixed(2)} hours`}
                </Typography>
              )}

              {working && (
                <Grid container justify="center">
                  <CircularProgress
                    className={classes.progress}
                    color="secondary"
                  />
                </Grid>
              )}
            </ValidatorForm>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="primary">
              Cancel
            </Button>
            <Button
              type="submit"
              form="stop-job-form"
              disabled={!validTime || overMaxLength}
              color="primary"
            >
              Submit Time
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default withMobileDialog()(withStyles(styles)(JobStopDialog))
