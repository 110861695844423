import axios from 'axios'

//////////////////////////////
// Action Types
//////////////////////////////
export const MAPS_GEOLOCATION_REQUESTED = 'maps/FETCH_ALL_REQUESTED'
export const MAPS_GEOLOCATION_ERROR = 'maps/FETCH_ALL_ERROR'
export const MAPS_GEOLOCATION = 'maps/FETCH_ALL'

//////////////////////////////
// Reducer
//////////////////////////////
const initialState = {
  isFetching: false,
  location: {},
  error: null,
}
export default (state = initialState, action) => {
  switch (action.type) {
    case MAPS_GEOLOCATION_REQUESTED:
      return {
        ...state,
        isFetching: true,
      }
    case MAPS_GEOLOCATION_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      }
    case MAPS_GEOLOCATION:
      return {
        ...state,
        isFetching: false,
        error: null,
        location: action.location,
      }
    default:
      return state
  }
}

//////////////////////////////
// Action Creators
//////////////////////////////

export const geolocate = () => async dispatch => {
  dispatch({
    type: MAPS_GEOLOCATION_REQUESTED,
  })
  try {
    const result = await axios.post(
      'https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyCbdPTyDOdU3UF20MDu24SibAotAPorrhE'
    )
    dispatch({
      type: MAPS_GEOLOCATION,
      location: result.data.location,
    })
  } catch (error) {
    dispatch({
      type: MAPS_GEOLOCATION_ERROR,
      error: error.response.data.message,
    })
  }
}
