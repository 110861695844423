//////////////////////////////
// Action Types
//////////////////////////////
export const NOTIFICATION_ADD = 'notification/ADD'

//////////////////////////////
// Reducer
//////////////////////////////
const initialState = {
  notifications: [],
}
export default (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATION_ADD:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            title: action.title,
            data: action.data,
          },
        ],
      }
    default:
      return state
  }
}

//////////////////////////////
// Action Creators
//////////////////////////////

export const addRequestProcessedNotification = request => dispatch => {
  const notification = {
    title: 'Request Processed',
    data: request,
  }
  dispatch({
    type: NOTIFICATION_ADD,
    notification,
  })
}
