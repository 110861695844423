import React from 'react'
import {
  withStyles,
  Button,
  Paper,
  List,
  ListItem,
  ListItemText,
  Typography,
  ListItemSecondaryAction,
  ListItemAvatar,
  Avatar,
  IconButton,
  Grid,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import CancelIcon from '@material-ui/icons/Cancel'

const styles = {
  root: {
    flexGrow: 1,
  },
  paper: {
    maxWidth: 900,
  },
  title: {
    padding: 16,
  },
  description: {
    padding: 16,
  },
  listItemText: {
    marginRight: 24,
  },
  secondaryText: {
    padding: 12,
  },
  inline: {
    display: 'inline',
  },
}

const getOfferStatus = offer => {
  if (offer.cancelled) return 'Cancelled'
  if (offer.accepted) return 'Accepted'
  if (offer.accepted !== null) return 'Declined'
  return 'Pending'
}

const OfferList = ({ classes, offers, onCreate, onSelect, onCancel }) => (
  <div className={classes.root}>
    <Paper className={classes.paper}>
      <Grid container justify="space-between">
        <Typography className={classes.title} variant="h6">
          Offers
        </Typography>
        <Button onClick={onCreate}>
          <AddIcon />
          Add
        </Button>
      </Grid>
      {offers.length === 0 ? (
        <Typography className={classes.description} variant="body2">
          No offers made
        </Typography>
      ) : (
        <List>
          {offers
            .sort(
              (a, b) =>
                new Date(a.bookedStartTime) - new Date(b.bookedStartTime)
            )
            .map(offer => (
              <ListItem key={offer.id} button onClick={e => onSelect(e, offer)}>
                <ListItemAvatar>
                  <Avatar
                    alt={offer.employee.user.nickname}
                    src={offer.employee.photo}
                  />
                </ListItemAvatar>
                <ListItemText
                  className={classes.listItemText}
                  primary={offer.employee.user.nickname}
                  secondary={getOfferStatus(offer)}
                />
                <ListItemSecondaryAction>
                  {!offer.cancelled && (
                    <IconButton
                      onClick={e => onCancel(offer.id)}
                      aria-label="Cancel"
                    >
                      <CancelIcon />
                    </IconButton>
                  )}
                </ListItemSecondaryAction>
              </ListItem>
            ))}
        </List>
      )}
    </Paper>
  </div>
)

export default withStyles(styles)(OfferList)
