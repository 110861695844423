import React, { Fragment } from 'react'
import {
  withStyles,
  Paper,
  List,
  ListItem,
  ListItemText,
  Typography,
  ListItemSecondaryAction,
  ListItemAvatar,
  Avatar,
  IconButton,
  Grid,
  Hidden,
} from '@material-ui/core'
import TimerIcon from '@material-ui/icons/Timer'
import TimerOffIcon from '@material-ui/icons/TimerOff'
import DoneIcon from '@material-ui/icons/Done'
import moment from 'moment'

const styles = theme => ({
  root: {
    flexGrow: 1,
    margin: 16,
  },
  paper: {
    maxWidth: 900,
  },
  title: {
    padding: 16,
  },
  description: {
    padding: 16,
  },
  listItemText: {
    marginRight: 24,
  },
  secondaryText: {
    padding: 12,
  },
  inline: {
    display: 'inline',
  },
})

const JobsMatched = ({
  classes,
  jobs,
  showBusiness,
  showStartStop,
  onSelect,
  onStart,
  onStop,
  onComplete,
}) => (
  <div className={classes.root}>
    <Paper className={classes.paper}>
      <Typography className={classes.title} variant="h5">
        Matched
      </Typography>
      {jobs.length === 0 ? (
        <Typography className={classes.description} variant="body2">
          Upcoming shifts you have requested will show here when they are
          matched with a qualified professional.
        </Typography>
      ) : (
        <List>
          {jobs.map(job => (
            <ListItem key={job.id} button onClick={e => onSelect(e, job)}>
              <ListItemAvatar>
                <Avatar
                  alt={job.employee.user.nickname}
                  src={job.employee.photo}
                />
              </ListItemAvatar>
              <ListItemText
                className={classes.listItemText}
                primary={
                  <Fragment>
                    {showBusiness && `${job.business.name} \u00b7 `}
                    {`${job.employee.user.nickname} \u00b7 `}
                    <Typography
                      component="span"
                      className={classes.inline}
                      color="textSecondary"
                    >
                      {job.jobProfile.name}
                    </Typography>
                  </Fragment>
                }
                secondary={`${moment(job.bookedStartTime).format(
                  'ddd, MMM D @ h:mm a'
                )} -
                    ${moment(job.bookedEndTime).format('h:mm a')}`}
              />
              <ListItemSecondaryAction>
                <Grid container alignItems="center">
                  {job.jobPriceEstimate && (
                    <Typography
                      variant="subtitle1"
                      className={classes.secondaryText}
                    >
                      $
                      {job.jobPrice
                        ? job.jobPrice.employerTotal
                        : job.jobPriceEstimate.employerTotal}
                    </Typography>
                  )}
                  <Hidden only="xs">
                    {showStartStop && (
                      <Fragment>
                        {!job.actualStartTime && (
                          <IconButton
                            onClick={e => onStart(e, job)}
                            aria-label="Start"
                          >
                            <TimerIcon color="primary" />
                          </IconButton>
                        )}
                        {job.actualStartTime && !job.actualEndTime && (
                          <IconButton
                            onClick={e => onStop(e, job)}
                            aria-label="Stop"
                          >
                            <TimerOffIcon color="primary" />
                          </IconButton>
                        )}
                      </Fragment>
                    )}
                    {job.actualEndTime && (
                      <IconButton
                        onClick={e => onComplete(e, job)}
                        aria-label="Complete"
                      >
                        <DoneIcon color="primary" />
                      </IconButton>
                    )}
                  </Hidden>
                </Grid>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      )}
    </Paper>
  </div>
)

export default withStyles(styles)(JobsMatched)
