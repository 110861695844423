import React, { Component, Fragment } from 'react'
import { Paper, Grid, withStyles, Button, Typography } from '@material-ui/core'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import ErrorIcon from '@material-ui/icons/Error'

import { Redirect, Link } from 'react-router-dom'

const styles = {
  root: {
    margin: 16,
  },
  avatar: {
    fontSize: 128,
    marginBottom: 32,
  },
  form: {
    margin: 'auto',
    maxWidth: 600,
    padding: 16,
    // paddingBottom: 48,
    textAlign: 'center',
  },
  textField: {
    maxWidth: 400,
    minHeight: '5rem',
    margin: 4,
    // marginLeft: theme.spacing.unit,
    // marginRight: theme.spacing.unit,
  },
  forgotPassword: {
    // textTransform: 'none'
    marginTop: 8,
  },
  button: {
    marginTop: 16,
  },
  errorMessage: {
    marginTop: 16,
  },
  errorIcon: {
    verticalAlign: 'top',
    marginRight: 2,
  },
  signUp: {
    marginTop: 16,
  },
}

class Login extends Component {
  state = {
    email: '',
    password: '',
  }

  handleChange = e => {
    const value = e.target.value
    const name = e.target.name

    this.setState({
      [name]: value,
    })
  }

  handleSubmit = () => {
    const { email, password } = this.state
    this.props.login(email, password)
  }

  handleEnter = e => {
    if (e.key === 'Enter') {
      this.handleSubmit(e)
      e.preventDefault()
    }
  }

  render() {
    const { classes, isAuthenticated, error, from } = this.props
    const { email, password } = this.state

    if (isAuthenticated) {
      const route = from ? from : '/'
      return <Redirect to={route} />
    }

    return (
      <div className={classes.root}>
        <Paper className={classes.form}>
          <ValidatorForm
            ref="form"
            onSubmit={this.handleSubmit}
            instantValidate
          >
            <Grid container justify="center" alignItems="center">
              <Grid item>
                <AccountCircleIcon className={classes.avatar} />
              </Grid>
              <Grid item xs={12}>
                <TextValidator
                  label="Email"
                  className={classes.textField}
                  type="email"
                  name="email"
                  value={email}
                  validators={['required', 'isEmail']}
                  errorMessages={['Required', 'Invalid email address']}
                  // validatorListener={this.validatorListener}
                  onChange={this.handleChange}
                  onKeyPress={this.handleEnter}
                  fullWidth={true}
                  variant="outlined"
                />
                <TextValidator
                  label="Password"
                  className={classes.textField}
                  type="password"
                  name="password"
                  value={password}
                  validators={['required']}
                  errorMessages={['Required']}
                  // validatorListener={this.validatorListener}
                  onChange={this.handleChange}
                  onKeyPress={this.handleEnter}
                  fullWidth={true}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  className={classes.forgotPassword}
                  color="primary"
                  component={Link}
                  to="/forgot-password"
                >
                  Forgot password?
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  className={classes.button}
                  variant="contained"
                  color="primary"
                >
                  Login
                </Button>
                {error ? (
                  <Typography
                    className={classes.errorMessage}
                    variant="body1"
                    color="error"
                  >
                    <ErrorIcon className={classes.errorIcon} />
                    {error}
                  </Typography>
                ) : (
                  <Fragment />
                )}
              </Grid>
              <Grid item xs={12} className={classes.signUp}>
                <Typography variant="body1">
                  Need an account?
                  <Button color="primary" component={Link} to="/register">
                    Sign up
                  </Button>
                </Typography>
              </Grid>
            </Grid>
          </ValidatorForm>
        </Paper>
      </div>
    )
  }
}

export default withStyles(styles)(Login)
