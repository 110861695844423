import React from 'react'
import {
  withStyles,
  withMobileDialog,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core'

import CompleteForm from './CompleteForm'

const styles = {
  root: {
    margin: 32,
  },
}

const JobCompleteDialog = ({
  classes,
  fullScreen,
  isAdmin,
  open,
  job,
  working,
  error,
  onClose,
  onSubmit,
}) => (
  <div className={classes.root}>
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={fullScreen}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" onClose={onClose}>
        Complete Shift
      </DialogTitle>
      <DialogContent>
        <CompleteForm
          job={job}
          error={error}
          isAdmin={isAdmin}
          onSubmit={onSubmit}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          type="submit"
          disabled={working}
          form="complete-job-form"
          color="primary"
        >
          Complete
        </Button>
        )}
      </DialogActions>
    </Dialog>
  </div>
)

export default withMobileDialog()(withStyles(styles)(JobCompleteDialog))
