import React, { Fragment } from 'react'
import {
  withStyles,
  Paper,
  List,
  ListItem,
  ListItemText,
  Typography,
  ListItemSecondaryAction,
  ListItemIcon,
  Button,
  Grid,
  Avatar,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import WarningIcon from '@material-ui/icons/Warning'
import { Link } from 'react-router-dom'
import moment from 'moment'

const styles = theme => ({
  root: {
    flexGrow: 1,
    margin: 16,
  },
  paper: {
    maxWidth: 900,
  },
  title: {
    padding: 16,
  },
  warningIcon: {
    padding: 16,
  },
  listItemText: {
    marginRight: 24,
  },
  description: {
    padding: 16,
  },
  secondaryText: {
    padding: 12,
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
  },
})

const JobsPending = ({
  classes,
  jobs,
  showBusiness,
  showBillingWarning,
  showOfferCounts,
  onSelect,
}) => (
  <div className={classes.root}>
    <Paper className={classes.paper}>
      <Typography className={classes.title} variant="h5">
        Pending
      </Typography>
      {jobs.length === 0 ? (
        <Typography className={classes.description} variant="body2">
          No requested shifts are awaiting a match at the moment.
        </Typography>
      ) : (
        <Fragment>
          {showBillingWarning && (
            <Grid container alignItems="center" wrap="nowrap">
              <Grid item className={classes.warningIcon}>
                <WarningIcon color="error" />
              </Grid>
              <Typography color="error" variant="body1">
                You must set up billing before jobs will be matched
              </Typography>
              <Button
                variant="text"
                color="primary"
                style={{ paddingLeft: 16 }}
                component={Link}
                to="/billing"
              >
                Set up billing
              </Button>
            </Grid>
          )}
          <List>
            {jobs.map(job => (
              <ListItem key={job.id} button onClick={e => onSelect(e, job)}>
                <ListItemIcon>
                  {showOfferCounts ? (
                    <Avatar className={classes.avatar}>
                      {job.pendingOffersCount}
                    </Avatar>
                  ) : (
                    <SearchIcon />
                  )}
                </ListItemIcon>
                <ListItemText
                  className={classes.listItemText}
                  primary={
                    <Fragment>
                      {showBusiness && `${job.business.name} \u00b7 `}
                      {`${job.jobProfile.name}`}
                    </Fragment>
                  }
                  secondary={`${moment(job.bookedStartTime).format(
                    'ddd, MMM D @ h:mm a'
                  )} -
                      ${moment(job.bookedEndTime).format('h:mm a')}`}
                />
                <ListItemSecondaryAction>
                  {job.jobPriceEstimate && (
                    <Typography
                      variant="subtitle1"
                      className={classes.secondaryText}
                    >
                      ${job.jobPriceEstimate.employerTotal}
                    </Typography>
                  )}
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Fragment>
      )}
    </Paper>
  </div>
)

export default withStyles(styles)(JobsPending)
