import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core'

const JobProfileDeleteConfirm = ({ open, onClose, onConfirm }) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="profile-delete-confirm-title"
    aria-describedby="profile-delete-confirm-description"
  >
    <DialogTitle id="profile-delete-confirm-title">
      Delete position?
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="profile-delete-confirm-description">
        This position will be removed. You will no longer have it as an option
        when booking shifts. This will not affect past shifts.
      </DialogContentText>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="primary">
          Delete
        </Button>
      </DialogActions>
    </DialogContent>
  </Dialog>
)

export default JobProfileDeleteConfirm
