import React, { Fragment } from 'react'
import {
  withStyles,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
} from '@material-ui/core'

const styles = theme => ({
  root: {},
  jobType: {
    marginTop: 16,
  },
  manager: {
    marginTop: 16,
  },
  specialInstructions: {
    marginTop: 8,
  },
  details: {
    whiteSpace: 'pre-wrap',
    borderWidth: 'thin',
    borderStyle: 'solid',
    borderColor: theme.palette.grey[300],
    borderRadius: 4,
    padding: 4,
  },
  divider: {
    marginTop: 4,
    marginBottom: 4,
  },
  total: {
    paddingLeft: 16,
  },
  inline: {
    display: 'inline',
  },
})

const calculateItem = (jobProfile, length) => {
  // Calculate total cost without tax, but including Zing fees
  let { wage, zingRate, employerFee } = jobProfile.jobType
  wage = parseFloat(wage)
  zingRate = parseFloat(zingRate)
  employerFee = parseFloat(employerFee)

  const hourlyTotal = wage * length
  const zingAmount = hourlyTotal * zingRate + employerFee

  return hourlyTotal + zingAmount
}

const calculateTotalTaxes = (jobProfile, times) => {
  const totalLength = times.reduce(
    (total, time) => (total = total + Number(time.length)),
    0
  )

  const tax = jobProfile.jobType.region.taxRate

  let { wage, zingRate, employerFee } = jobProfile.jobType
  wage = parseFloat(wage)
  zingRate = parseFloat(zingRate)
  employerFee = parseFloat(employerFee)

  const hourlyTotal = wage * totalLength
  const zingAmount = hourlyTotal * zingRate + employerFee
  const total = hourlyTotal + zingAmount
  const totalTaxes = total * tax

  return totalTaxes
}

const calculateTotal = (jobProfile, times) => {
  const totalLength = times.reduce(
    (total, time) => (total = total + Number(time.length)),
    0
  )

  const tax = jobProfile.jobType.region.taxRate

  let { wage, zingRate, employerFee } = jobProfile.jobType
  wage = parseFloat(wage)
  zingRate = parseFloat(zingRate)
  employerFee = parseFloat(employerFee)

  const hourlyTotal = wage * totalLength
  const zingAmount = hourlyTotal * zingRate + employerFee
  const total = hourlyTotal + zingAmount
  const totalTaxes = total * tax

  return hourlyTotal + zingAmount + totalTaxes
}

const ScheduleJobSummary = ({
  classes,
  jobProfile,
  times,
  managerName,
  specialInstructions,
  hideTimes = false,
}) => (
  <div className={classes.root}>
    {jobProfile.jobType ? (
      <Fragment>
        <Typography className={classes.jobType} variant="subtitle2">
          {jobProfile.jobType.name}
        </Typography>
        <Typography variant="caption">{`$${
          jobProfile.jobType.wage
        } / hour + Zing fees`}</Typography>
        {managerName.length > 0 && (
          <div className={classes.manager}>
            <Typography className={classes.inline} variant="subtitle2">
              Check in with:
            </Typography>
            <Typography
              className={classes.inline}
            >{` ${managerName}`}</Typography>
          </div>
        )}
        {specialInstructions.length > 0 && (
          <div className={classes.specialInstructions}>
            <Typography variant="subtitle2">Special Instructions:</Typography>
            <Typography className={classes.details}>
              {specialInstructions}
            </Typography>
          </div>
        )}
      </Fragment>
    ) : (
      <Typography className={classes.jobType} variant="caption">
        Select a position to get started.
      </Typography>
    )}
    {!hideTimes && (
      <List>
        {times.map((time, index) => (
          <ListItem key={index} disableGutters dense>
            <ListItemText
              primary={time.moment.format('ddd, MMM D @ h:mm a')}
              secondary={time.length && `${time.length} hours`}
            />
            {time.length && (
              <Typography>{`$${calculateItem(jobProfile, time.length).toFixed(
                2
              )}`}</Typography>
            )}
          </ListItem>
        ))}
        {jobProfile.jobType.region.taxRate > 0 && (
          <Fragment>
            <Divider className={classes.divider} />
            <ListItem disableGutters dense className={classes.total}>
              <ListItemText primary="Taxes" />
              <Typography>{`$${calculateTotalTaxes(jobProfile, times).toFixed(
                2
              )}`}</Typography>
            </ListItem>
          </Fragment>
        )}
        <Divider className={classes.divider} />
        <ListItem disableGutters dense className={classes.total}>
          <ListItemText primary="Total" />
          <Typography>{`$${calculateTotal(jobProfile, times).toFixed(
            2
          )}`}</Typography>
        </ListItem>
      </List>
    )}
  </div>
)

export default withStyles(styles)(ScheduleJobSummary)
