import React, { Component, Fragment } from 'react'

import { connect } from 'react-redux'
import {
  fetchJobProfiles,
  createJobProfile,
  updateJobProfile,
  deleteJobProfile,
} from '../reducers/job-profile'
import { fetchJobTypes } from '../reducers/job-type'
import { geolocate } from '../reducers/maps'

import JobProfiles from './JobProfiles'
import JobProfileDialog from './JobProfileDialog'
import JobProfileDeleteConfirm from './JobProfileDeleteConfirm'

class JobProfilesPage extends Component {
  state = {
    dialogOpen: false,
    selectedJobProfile: null,
    edit: false,
    deleteDialogOpen: false,
    jobProfileToDelete: null,
  }

  componentDidMount = () => {
    this.props.fetchJobProfiles()
    this.props.fetchJobTypes(this.props.user.regionId)
    this.props.geolocate()
  }

  handleNewClick = () => {
    this.setState({ dialogOpen: true, selectedJobProfile: null, edit: false })
  }

  handleEditClick = jobProfile => {
    this.setState({
      dialogOpen: true,
      selectedJobProfile: jobProfile,
      edit: true,
    })
  }

  handleDeleteClick = jobProfile => {
    this.setState({
      deleteDialogOpen: true,
      jobProfileToDelete: jobProfile,
    })
  }

  handleDelete = () => {
    const { deleteJobProfile } = this.props
    const { jobProfileToDelete } = this.state
    deleteJobProfile(jobProfileToDelete)
    this.setState({ deleteDialogOpen: false, dialogOpen: false })
  }

  handleDialogClose = () => {
    this.setState({ dialogOpen: false })
  }

  render = () => {
    const {
      jobProfiles,
      jobTypes,
      createJobProfile,
      updateJobProfile,
      location,
      businessAddress,
    } = this.props
    const {
      dialogOpen,
      selectedJobProfile,
      edit,
      deleteDialogOpen,
    } = this.state

    return (
      <Fragment>
        <JobProfiles
          jobProfiles={jobProfiles}
          onNewClick={this.handleNewClick}
          onEditClick={this.handleEditClick}
          onDeleteClick={this.handleDeleteClick}
        />
        <JobProfileDialog
          open={dialogOpen}
          edit={edit}
          jobProfile={selectedJobProfile}
          jobProfiles={jobProfiles}
          jobTypes={jobTypes}
          location={location}
          businessAddress={businessAddress}
          onClose={this.handleDialogClose}
          onDelete={this.handleDeleteClick}
          onSubmit={edit ? updateJobProfile : createJobProfile}
        />
        <JobProfileDeleteConfirm
          open={deleteDialogOpen}
          onClose={() => this.setState({ deleteDialogOpen: false })}
          onConfirm={this.handleDelete}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = ({
  authentication: { user },
  jobProfile: { jobProfiles },
  jobType: { jobTypes },
  maps: { location },
}) => ({
  user,
  jobProfiles,
  jobTypes,
  location,
  businessAddress:
    user.employer && user.employer.business && user.employer.business.address,
})

const mapDispatchToProps = {
  fetchJobProfiles,
  createJobProfile,
  updateJobProfile,
  deleteJobProfile,
  fetchJobTypes,
  geolocate,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JobProfilesPage)
