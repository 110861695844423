import React, { Component, createRef } from 'react'
import {
  withStyles,
  Hidden,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  Grid,
  Paper,
  Typography,
  MobileStepper,
} from '@material-ui/core'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { withRouter } from 'react-router-dom'
import moment from 'moment'

import JobProfileDialog from './JobProfileDialog'
import ScheduleJobPosition from './ScheduleJobPosition'
import ScheduleJobTimes from './ScheduleJobTimes'
import ScheduleJobDetails from './ScheduleJobDetails'
import ScheduleJobSummary from './ScheduleJobSummary'
import ScheduleJobConfirmDialog from './ScheduleJobConfirmDialog'

const styles = {
  root: {
    margin: 16,
    maxWidth: 1200,
  },
  steps: {},
  summary: {
    padding: 16,
  },
  mobileContent: {
    marginBottom: 54,
  },
}

class ScheduleJob extends Component {
  state = {
    activeStep: 0,
    steps: [
      {
        id: 'job-position',
        ref: createRef(),
        label: 'Select Position',
        renderContent: props => {
          const { jobProfiles } = this.props
          const { selectedProfile } = this.state
          return (
            <div>
              <ScheduleJobPosition
                radio
                jobProfiles={jobProfiles}
                selectedProfile={selectedProfile}
                onSelectProfile={this.handleSelectJobProfile}
                onAddProfile={this.handleAddJobProfile}
                {...props}
              />
            </div>
          )
        },
        valid: false,
      },
      {
        id: 'job-times',
        ref: createRef(),
        label: 'Date(s) / Time(s)',
        renderContent: props => {
          const { times, multiple, repeat } = this.state
          return (
            <ScheduleJobTimes
              times={times}
              multiple={multiple}
              repeat={repeat}
              onAddDateTime={this.handleAddDateTime}
              onRemoveDateTime={this.handleRemoveDateTime}
              onDateTimeChange={this.handleDateTimeChange}
              onChange={this.handleChange}
              {...props}
            />
          )
        },
        valid: true, // Use form validity instead, so it points out the errors to user when they try to click 'Next'
      },
      {
        id: 'job-other',
        ref: createRef(),
        label: 'Other Details',
        renderContent: props => {
          const { managerName, specialInstructions } = this.state
          return (
            <ScheduleJobDetails
              managerName={managerName}
              specialInstructions={specialInstructions}
              onChange={this.handleChange}
            />
          )
        },
        valid: true, // Use form validity instead, so it points out the errors to user when they try to click 'Next'
      },
    ],
    jobProfileDialogOpen: false,
    confirmJobDialogOpen: false,
    // New job state
    selectedProfile: { id: null },
    times: [
      {
        moment: moment()
          .add(2, 'h')
          .add(15 - (moment().minute() % 15), 'm'),
        length: '',
      },
    ],
    multiple: 'once', // Valid values are 'once', 'repeat', 'varied'
    repeat: '',
    managerName: '',
    specialInstructions: '',
  }

  getActualTimes = () => {
    const { times, multiple, repeat } = this.state
    return multiple === 'varied'
      ? times
      : multiple === 'repeat'
      ? Array(parseInt(repeat) || 1).fill(times[0])
      : times.slice(0, 1)
  }

  handleSubmit = async () => {
    const { selectedProfile, managerName, specialInstructions } = this.state

    const jobs = this.getActualTimes().map(time => ({
      jobProfileId: selectedProfile.id,
      bookedStartTime: time.moment.toDate(),
      bookedEndTime: time.moment
        .clone()
        .add(time.length, 'h')
        .toDate(),
      managerName,
      specialInstructions,
    }))

    await this.props.onSubmit(jobs)

    if (!this.props.error) {
      this.handleCloseConfirmJobDialog()
      this.props.history.push('/')
    }
  }

  handleBack = () =>
    this.setState(prevState => ({
      activeStep:
        prevState.activeStep === 0
          ? prevState.activeStep
          : prevState.activeStep - 1,
    }))

  handleNext = () => {
    if (this.state.activeStep === this.state.steps.length - 1) {
      this.handleOpenConfirmJobDialog()
    } else {
      this.setState(prevState => ({
        activeStep: prevState.activeStep + 1,
      }))
    }
  }

  handleSetStepValidity = valid =>
    this.setState(prevState => ({
      steps: prevState.steps.map((step, index) =>
        index === prevState.activeStep ? { ...step, valid } : step
      ),
    }))

  checkFormValid = async () => {
    const valid = await this.state.steps[
      this.state.activeStep
    ].ref.current.isFormValid()

    this.setState(prevState => ({
      steps: prevState.steps.map((step, index) =>
        index === prevState.activeStep ? { ...step, valid } : step
      ),
    }))
  }

  handleSelectJobProfile = jobProfile => {
    this.handleSetStepValidity(jobProfile !== null)
    this.setState({ selectedProfile: jobProfile })
  }

  handleAddDateTime = () => {
    this.setState(
      prevState => ({
        times: [
          ...prevState.times,
          {
            moment: prevState.times[prevState.times.length - 1].moment.clone(),
            length: prevState.times[prevState.times.length - 1].length,
          },
        ],
      })
      // this.checkFormValid
    )
  }

  handleRemoveDateTime = index => {
    this.setState(prevState => ({
      times: prevState.times.filter((t, i) => i !== index),
    }))
  }

  handleDateTimeChange = (index, time, length) => {
    this.setState(
      prevState => ({
        times: prevState.times.map((oldTime, i) =>
          i === index ? { ...oldTime, moment: time, length } : oldTime
        ),
      })
      // this.checkFormValid
    )
  }

  handleChange = e => {
    const { name, value } = e.target

    this.setState(
      {
        [name]: value,
      }
      // this.checkFormValid
    )
  }

  handleAddJobProfile = () => this.setState({ jobProfileDialogOpen: true })

  handleSubmitJobProfile = async jobProfile => {
    const newProfile = await this.props.onSubmitJobProfile(jobProfile)
    console.log({ jobProfile, newProfile })
    this.handleSelectJobProfile(newProfile)
  }

  handleCloseJobProfileDialog = () =>
    this.setState({ jobProfileDialogOpen: false })

  handleOpenConfirmJobDialog = () =>
    this.setState({ confirmJobDialogOpen: true })

  handleCloseConfirmJobDialog = () =>
    this.setState({ confirmJobDialogOpen: false })

  render = () => {
    const {
      classes,
      jobProfiles,
      jobTypes,
      working,
      error,
      location,
      businessAddress,
    } = this.props
    const {
      steps,
      activeStep,
      selectedProfile,
      managerName,
      specialInstructions,
      jobProfileDialogOpen,
      confirmJobDialogOpen,
    } = this.state
    const currentStep = steps[activeStep]
    return (
      <div className={classes.root}>
        {/* View for non-mobile */}
        <Hidden xsDown>
          <Grid container spacing={16}>
            <Grid item sm className={classes.steps}>
              {/* Steps */}
              <Paper className={classes.steps}>
                <Stepper activeStep={activeStep} orientation="vertical">
                  {steps.map(({ id, ref, label, renderContent, valid }) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                      <StepContent>
                        <ValidatorForm
                          id={id}
                          ref={ref}
                          onSubmit={this.handleNext}
                          instantValidate
                        >
                          {renderContent({
                            setValid: this.handleSetStepValidity,
                          })}
                          <div className={classes.actions}>
                            <Button
                              disabled={activeStep === 0}
                              onClick={this.handleBack}
                            >
                              Back
                            </Button>
                            <Button
                              type="submit"
                              form={id}
                              variant="contained"
                              color="primary"
                              disabled={!valid}
                              // disabled={
                              //   !ref.current || !ref.current.isFormValid(false)
                              // }
                              // onClick={this.handleNext}
                            >
                              Next
                            </Button>
                          </div>
                        </ValidatorForm>
                      </StepContent>
                    </Step>
                  ))}
                </Stepper>
              </Paper>
            </Grid>
            <Grid item sm={4}>
              {/* Summary */}
              <Paper className={classes.summary}>
                <Typography variant="subtitle1">Summary</Typography>
                <ScheduleJobSummary
                  jobProfile={selectedProfile}
                  times={this.getActualTimes()}
                  hideTimes={activeStep === 0}
                  managerName={managerName}
                  specialInstructions={specialInstructions}
                />
              </Paper>
            </Grid>
          </Grid>
        </Hidden>

        {/* View for mobile */}
        <Hidden smUp>
          <ValidatorForm
            id={currentStep.id}
            ref={currentStep.ref}
            onSubmit={this.handleNext}
            instantValidate
          >
            <div className={classes.mobileContent}>
              {currentStep.renderContent({
                setValid: this.handleSetStepValidity,
              })}
            </div>
            <MobileStepper
              steps={steps.length}
              activeStep={activeStep}
              nextButton={
                <Button
                  size="small"
                  type="submit"
                  form={currentStep.id}
                  color="primary"
                  disabled={!currentStep.valid}
                >
                  Next
                  <KeyboardArrowRightIcon />
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  disabled={activeStep === 0}
                  color="primary"
                  onClick={this.handleBack}
                >
                  <KeyboardArrowLeftIcon />
                  Back
                </Button>
              }
            />
          </ValidatorForm>
        </Hidden>

        <JobProfileDialog
          open={jobProfileDialogOpen}
          jobProfiles={jobProfiles}
          jobTypes={jobTypes}
          location={location}
          businessAddress={businessAddress}
          onClose={this.handleCloseJobProfileDialog}
          onSubmit={this.handleSubmitJobProfile}
        />

        <ScheduleJobConfirmDialog
          open={confirmJobDialogOpen}
          jobProfile={selectedProfile}
          times={this.getActualTimes()}
          managerName={managerName}
          specialInstructions={specialInstructions}
          working={working}
          error={error}
          onClose={this.handleCloseConfirmJobDialog}
          onSubmit={this.handleSubmit}
        />
      </div>
    )
  }
}

export default withStyles(styles)(withRouter(ScheduleJob))
