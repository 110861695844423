import axios from 'axios'

//////////////////////////////
// Action Types
//////////////////////////////
export const BUSINESS_FETCH_ALL_REQUESTED = 'business/FETCH_ALL_REQUESTED'
export const BUSINESS_FETCH_ALL_ERROR = 'business/FETCH_ALL_ERROR'
export const BUSINESS_FETCH_ALL = 'business/FETCH_ALL'
export const BUSINESS_SELECT = 'business/SELECT'

//////////////////////////////
// Reducer
//////////////////////////////
const initialState = {
  isFetching: false,
  businesses: [],
  selectedBusiness: null,
  error: null,
}
export default (state = initialState, action) => {
  switch (action.type) {
    case BUSINESS_FETCH_ALL_REQUESTED:
      return {
        ...state,
        isFetching: true,
      }
    case BUSINESS_FETCH_ALL_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      }
    case BUSINESS_FETCH_ALL:
      return {
        ...state,
        isFetching: false,
        error: null,
        businesses: action.businesses,
      }
    case BUSINESS_SELECT:
      return {
        ...state,
        selectedBusiness: action.business,
      }
    default:
      return state
  }
}

//////////////////////////////
// Action Creators
//////////////////////////////

export const fetchBusinesses = () => async dispatch => {
  dispatch({
    type: BUSINESS_FETCH_ALL_REQUESTED,
  })
  try {
    const result = await axios.get('/api/businesses')
    dispatch({
      type: BUSINESS_FETCH_ALL,
      businesses: result.data,
    })
  } catch (error) {
    dispatch({
      type: BUSINESS_FETCH_ALL_ERROR,
      error: error.response.data.message,
    })
  }
}

export const selectBusiness = business => dispatch => {
  dispatch({
    type: BUSINESS_SELECT,
    business,
  })
}
