import React, { Fragment, Component } from 'react'

import { connect } from 'react-redux'
import {
  fetchEmployees,
  fetchEmployeeHistory,
  selectEmployee,
} from '../reducers/employee'
import { fetchOffers, createOffer, cancelOffer } from '../reducers/offer'

import ContractorSelectDialog from './ContractorSelectDialog'
import OfferList from './OfferList'

class OfferContainer extends Component {
  state = {
    contractorDialogOpen: false,
  }

  handleAddClicked = () => {
    this.setState({ contractorDialogOpen: true })
  }

  handleDialogClose = () => {
    this.setState({ contractorDialogOpen: false })
  }

  handleFetchEmployees = (limit, offset, sort, search) => {
    const {
      businessId,
      jobProfile: { jobType },
    } = this.props.job
    this.props.fetchEmployees({
      limit,
      offset,
      sort,
      search,
      businessId,
      jobTypeId: jobType.id,
      includeStats: true,
    })
  }

  handleSelectEmployee = employee => {
    // Select / unselect employee (for expand/collapse)
    if (employee) {
      if (
        this.props.selectedEmployee &&
        this.props.selectedEmployee.id === employee.id
      ) {
        this.props.selectEmployee(null)
      } else {
        this.props.selectEmployee(employee)
        this.props.fetchEmployeeHistory(employee.id, this.props.job.business.id)
      }
    }
  }

  handleCreateOffer = employeeId => {
    this.props.createOffer({ jobId: this.props.job.id, employeeId })
    this.handleSelectEmployee(null)
    this.handleDialogClose()
  }

  handleCancelOffer = offerId => {
    this.props.cancelOffer(offerId)
  }

  render = () => {
    const {
      job,
      region,
      offers,
      employees,
      employeeTotal,
      selectedEmployee,
      isFetchingEmployees,
    } = this.props
    const { contractorDialogOpen } = this.state

    return (
      <Fragment>
        <ContractorSelectDialog
          open={contractorDialogOpen}
          job={job}
          region={region}
          employees={employees}
          employeeTotal={employeeTotal}
          selectedEmployee={selectedEmployee}
          fetchEmployees={this.handleFetchEmployees}
          isFetching={isFetchingEmployees}
          onClose={this.handleDialogClose}
          onSelect={this.handleSelectEmployee}
          onSubmit={this.handleCreateOffer}
        />
        <OfferList
          offers={offers}
          onCreate={this.handleAddClicked}
          onCancel={this.handleCancelOffer}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = ({
  authentication: {
    user: { region },
  },
  offer: { offers },
  employee: {
    employees,
    total,
    employee,
    isFetching,
    pendingOffers,
    upcomingJobs,
    completedJobs,
  },
}) => ({
  region,
  offers,
  employees,
  isFetchingEmployees: isFetching,
  selectedEmployee: employee
    ? {
        ...employee,
        pendingOffers,
        upcomingJobs,
        completedJobs,
      }
    : null,
  employeeTotal: total,
})

const mapDispatchToProps = {
  fetchOffers,
  createOffer,
  cancelOffer,
  fetchEmployees,
  fetchEmployeeHistory,
  selectEmployee,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OfferContainer)
